<!-- eslint-disable max-len -->
<template>
    <div class="margin-top-6">
        <h2 id="row-start-end" class="anchor-link" tabindex="-1">Grid Row Start/End</h2>
        <p>Use the <code class="code-highlight">.grid-rows-start-{x}</code> and <code class="code-highlight">.grid-rows-end-x</code> utilities to make an element start or end at a specified grid line. These can also be combined with the <code class="code-highlight">.grid-rows-span-{x}</code> utilities to span a specific number of rows.
        <p>Note that CSS grid lines start at 1, not 0, so a full-height element in a 3-row grid would start at line 1 and end at line 4.</p>
        <div class="margin-y-4">
            <div class="margin-y-4">
                <div class="grid grid-rows-3 grid-auto-flow-column grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4">
                    <div class="background-blue-medium"></div>
                    <div class="grid-rows-start-2 grid-rows-span-2 background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">1</span>
                    </div>
                    <div class="grid-rows-start-1 grid-rows-end-4 background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">2</span>
                    </div>
                    <div class="grid-rows-start-1 grid-rows-end-3 background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">3</span>
                    </div>
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">4</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-rows-3 grid-auto-flow-column grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4'>
    <div class='background-blue-medium'></div>
    <div class='grid-rows-start-2 grid-rows-span-2 background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>1</span>
    </div>
    <div class='grid-rows-start-1 grid-rows-end-4 background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>2</span>
    </div>
    <div class='grid-rows-start-1 grid-rows-end-3 background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>3</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>4</span>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
