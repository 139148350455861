<!-- eslint-disable max-len -->
<template>
    <div class="margin-top-6">
        <h2 id="auto-columns" class="anchor-link" tabindex="-1">Grid Auto Columns</h2>
        <p>Use the <code class="code-highlight">.grid-auto-columns-{keyword}</code> utilities to control the size implicitly-created grid columns. This works best when combining with the <a href="#auto-flow"><code class="code-highlight">.grid-auto-flow-x</code></a> class.</p>
        <table>
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Properties</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <code>.grid-auto-columns-auto</code>
                    </td>
                    <td>
                        <code>grid-auto-columns: auto;</code>
                    </td>
                </tr>
                <tr>
                    <td>
                        <code>.grid-auto-columns-min</code>
                    </td>
                    <td>
                        <code>grid-auto-columns: min-content;</code>
                    </td>
                </tr>
                <tr>
                    <td>
                        <code>.grid-auto-columns-max</code>
                    </td>
                    <td>
                        <code>grid-auto-columns: max-content;</code>
                    </td>
                </tr>
                <tr>
                    <td>
                        <code>.grid-auto-columns-fr</code>
                    </td>
                    <td>
                        <code>grid-auto-columns: minmax(0, 1fr);</code>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="margin-y-4">
            <div class="grid grid-auto-columns-fr grid-auto-flow-column grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4">
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">1</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">2</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">3</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">4</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">5</span>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-auto-columns-fr grid-auto-flow-column grid-columns-gap-6 grid-rows-gap-3 background-blue-primary-10 padding-4'>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>1</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>2</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>3</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>4</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>5</span>
    </div>
</div>" language="markup"></prism-editor></pre>
</div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
