<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <basic-template>
      <div slot="content">
        <div class="accessibility__page-content">
          <div class="accessibility__page-section">
            <h1 class="margin-y-6">Accessibility</h1>
            <p>Approximately 1 out of 4 adults in the U.S. have at least one disability <a href="https://www.cdc.gov/media/releases/2018/p0816-disability.html">[Source: CDC].</a></p>
            <p>Disabilities may affect how people move, see, hear, communicate, learn, understand, and process information.
                As a result, it’s important to consider how to design and develop a website or application to support a wide range of needs and experiences.</p>
              <p>With this in mind, Syracuse University works to foster diversity and build a campus culture of inclusion. The University is committed to ensuring people with disabilities enjoy full participation in the University’s programs, services and benefits.
                  The University seeks the continuous improvement of accessibility on its campuses and in its activities, and prohibits unlawful discrimination on the basis of disability.
              </p>
              <p>This website is designed to provide the tools and guidance&mdash;in the form of markup and components&mdash;to create accessible websites and applications.</p>
              <p>Many accessibility features are included in the markup and components, but it’s important to make sure that the tools provided are integrated in a way that doesn't create unforeseen accessibility barriers.
                  Depending how components are used, there may be more design and implementation considerations. Be sure to test your site or applications using WAVE or other accessibility testing tools.</p>
              <h2>Meeting the Web Content Accessibility Guidelines (WCAG)</h2>
              <p>This digital design system targets WCAG 2.1 Level AA and Level AAA success criteria, and seeks to provide a highly usable experience for everyone.</p>
              <p>For more information, see the following resources:</p>
              <ul>
                  <li><a href="https://www.w3.org/TR/WCAG21/">WCAG 2.1</a></li>
                  <li><a href="https://www.w3.org/TR/wai-aria-1.1/">ARIA 1.1</a></li>
                  <li><a href="https://answers.syr.edu/display/AEA/Accessible+Content">Answers</a></li>
              </ul>
          </div>
        </div>
      </div>
    </basic-template>
  </div>
</template>

<script>
import BasicTemplate from '../components/Base/basic-template.vue';

export default {
  name: 'borders',
  components: {
    BasicTemplate,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
  .accessibility {
    &__page-content {
      width: 100%;
      max-width: 65em;
      margin: 0 auto;
    }
    &__page-section {
      padding: 0 $spacer-3;
    }
  }
</style>
