<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
          <components-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Loading Graphics</h1>
        </div>
        <div class="design-system__page-content">
            <div class="design-system__page-section">
                <h2></h2>
                <div class="flex-container">
                    <div class="flex-grid">
                        <div class="flex-grid-item-lg">
                            <div class="card shadow-depth-1 margin-bottom-3">
                                <div class="loading">
                                    <div class="loading-message">
                                        Loading. Please Wait.
                                    </div>
                                    <div class="loading-small">
                                        <div class="ball ball-one"></div>
                                        <div class="ball ball-two"></div>
                                        <div class="ball ball-three"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grid-item-lg">
                            <div class="card shadow-depth-1 margin-bottom-3">
                                <div class="card-header padding-bottom-0">
                                    <h2 class="heading-size-h3 is-bold">Lorem Ipsum</h2>
                                </div>
                                <div class="card-body padding-top-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <div class="card-footer">
                                    <a class="button button-primary" href="#">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grid-item-lg">
                            <div class="card shadow-depth-1 margin-bottom-3">
                                <div class="card-header padding-bottom-0">
                                    <h2 class="heading-size-h3 is-bold">Lorem Ipsum</h2>
                                </div>
                                <div class="card-body padding-top-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <div class="card-footer">
                                    <a class="button button-primary" href="#">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='card shadow-depth-1 margin-bottom-3'>
    <div class='loading'>
        <div class='loading-message'>
            Loading. Please Wait.
        </div>
        <div class='loading-small'>
            <div class='ball ball-one'></div>
            <div class='ball ball-two'></div>
            <div class='ball ball-three'></div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import ComponentsSidebar from '../../components/Sidebars/components-sidebar.vue';


export default {
  name: 'home',
  components: {
    PageTemplate,
    PrismEditor,
    ComponentsSidebar,
  },
};
</script>
