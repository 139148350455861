<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <layout-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Breakpoints</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <p>Breakpoints are based on common device viewport widths. Abbreviations are used for each breakpoint to keep the class names concise.
              This abbreviated syntax is used consistently across responsive styles.
              Responsive styles allow you to change the properties at each breakpoint.
              For example, when using column widths for grid layouts, you can specify that the width is 12 columns wide by default&mdash;8 columns wide from the medium breakpoint
              and 4 columns wide from the extra-large breakpoint: <code class="code-highlight">&#60;div class="col-12 col-md-8 col-xl-4"&#62;...&#60;/div&#62;</code>.</p>
              <p>In most cases, breakpoints are used with the min-width media query&mdash;meaning that when using a responsive utility class, the class becomes "enabled" from the breakpoint on upwards.
                Or from the browser's perspective, when the browser's width <strong>is</strong> the breakpoint or <strong>wider</strong>.</p>
              <div class="row">
                <div class="col-sm-12 col-md-8">
                  <table class="border table-simple">
                    <thead>
                      <tr>
                        <th>Breakpoint</th>
                        <th>Syntax</th>
                        <th>Breaks At</th>
                        <th>Is Enabled</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><span class="mobile-th">Breakpoint</span> None</td>
                        <td><span class="mobile-th">Syntax</span></td>
                        <td><span class="mobile-th">Breaks At</span></td>
                        <td><span class="mobile-th">Is Enabled</span><span class="mobile-td">From 0px upwards -></span></td>
                      </tr>
                      <tr>
                        <td><span class="mobile-th">Breakpoint</span>Small</td>
                        <td><span class="mobile-th">Syntax</span>sm</td>
                        <td><span class="mobile-th">Breaks At</span>544px</td>
                        <td><span class="mobile-th">Is Enabled</span><span class="mobile-td">From 544px upwards -></span></td>
                      </tr>
                      <tr>
                        <td><span class="mobile-th">Breakpoint</span>Medium</td>
                        <td><span class="mobile-th">Syntax</span>md</td>
                        <td><span class="mobile-th">Breaks At</span>768px</td>
                        <td><span class="mobile-th">Is Enabled</span><span class="mobile-td">From 768px upwards -></span></td>
                      </tr>
                      <tr>
                        <td><span class="mobile-th">Breakpoint</span>Large</td>
                        <td><span class="mobile-th">Syntax</span>lg</td>
                        <td><span class="mobile-th">Breaks At</span>1012px</td>
                        <td><span class="mobile-th">Is Enabled</span><span class="mobile-td">From 1012 upwards -></span></td>
                      </tr>
                      <tr>
                        <td><span class="mobile-th">Breakpoint</span>Extra-Large</td>
                        <td><span class="mobile-th">Syntax</span>xl</td>
                        <td><span class="mobile-th">Breaks At</span>1280px</td>
                        <td><span class="mobile-th">Is Enabled</span><span class="mobile-td">From 1280px upwards -></span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
          <div class="design-system__page-section">
            <h2>Breakpoint Variables</h2>
            <breakpoint-variables />
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PageTemplate from '../../../components/Base/page-template.vue';
import LayoutSidebar from '../../../components/Sidebars/layout-sidebar.vue';
import BreakpointVariables from './BreakpointVariables.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    LayoutSidebar,
    BreakpointVariables,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">

</style>
