<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <design-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Shadows</h1>
        </div>
        <div class="design-system__page-content">
            <div class="design-system__page-section">
                <h2 id="box-shadow" class="anchor-link" tabindex="-1">Box Shadow</h2>
                <p>Box shadows are used to make content appear elevated. They are typically applied to containers of content that users need to pay attention
                    to or content that appears on top of (overlapping) other elements on the page (like a pop-over or modal).</p>
                <p>Box shadow size reflects elevation. Surfaces at higher elevations have larger shadows, while those at lower elevations have smaller shadows.</p>
                <p><strong>To maintain Syracuse University brand and design standards, box shadows should never be used on logos, buttons, or images.</strong></p>
                <p>A good example of applying box shadows is to combine a card component with a class of <code class="code-highlight">.shadow-depth-1, .shadow-depth-2,</code> or <code class="code-highlight">.shadow-depth-3</code>.</p>
                <p>In the example below, the shadow focuses your attention and differenciates the card component from the white background. </p>
                <div class="example__container">
                    <div class="example__demo">
                        <div class="example__demo-section">
                            <div class='card shadow-depth-1 margin-right margin-bottom'>
                                <div class='card-header'>
                                    <h3 class='margin-0'>Header Content</h3>
                                </div>
                                <div class='card-body'>
                                    <p class='font-size-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div class='card-footer'>
                                    <button class='button button-primary'>Read Article</button>
                                </div>
                            </div>
                            <div class='card shadow-depth-2 margin-right margin-bottom'>
                                <div class='card-header'>
                                    <h3 class='margin-0'>Header Content</h3>
                                </div>
                                <div class='card-body'>
                                    <p class='font-size-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div class='card-footer'>
                                    <button class='button button-primary'>Read Article</button>
                                </div>
                            </div>
                            <div class='card shadow-depth-3 margin-right margin-bottom'>
                                <div class='card-header'>
                                    <h3 class='margin-0'>Header Content</h3>
                                </div>
                                <div class='card-body'>
                                    <p class='font-size-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div class='card-footer'>
                                    <button class='button button-primary'>Read Article</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="example__code">
                        <pre contenteditable="true"><prism-editor code="<div class='card shadow-depth-1 margin-right margin-bottom'>
    <div class='card-header'>
        <h3 class='margin-0'>Header Content</h3>
    </div>
    <div class='card-body'>
        <p class='font-size-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    </div>
    <div class='card-footer'>
        <button class='button button-primary'>Read Article</button>
    </div>
</div>
<div class='card shadow-depth-2 margin-right margin-bottom'>
    <div class='card-header'>
        <h3 class='margin-0'>Header Content</h3>
    </div>
    <div class='card-body'>
        <p class='font-size-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    </div>
    <div class='card-footer'>
        <button class='button button-primary'>Read Article</button>
    </div>
</div>
<div class='card shadow-depth-3 margin-right margin-bottom'>
    <div class='card-header'>
        <h3 class='margin-0'>Header Content</h3>
    </div>
    <div class='card-body'>
        <p class='font-size-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    </div>
    <div class='card-footer'>
        <button class='button button-primary'>Read Article</button>
    </div>
</div>" language="markup"></prism-editor></pre>
                    </div>
                </div>
            </div>
            <div class="design-system__page-section">
                <h2 id="text-shadow" class="anchor-link" tabindex="-1">Text Shadow</h2>
                <p>Text shadows are often used to add a drop shadow or outline to text.
                    Adding the <code class="code-highlight">.text-shadow</code> class to typographical elements will add a very light outline to the text to provide additional contrast.</p>
                <p><strong>The text shadow class should only be used when white text appears on top of images, gradients or overlays such as in the example below.</strong></p>
                <div class="border background-black">
                    <div class="position-relative">
                        <div class="image-three-by-two">
                            <img src="../../assets/images/online-female-student-laptop-couch.webp" alt="Example Image"/>
                        </div>
                        <div class="position-absolute padding-5 full-width overlay-solid-dark" style="bottom: 0px;">
                            <blockquote class="text-white text-shadow">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</blockquote>
                        </div>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='border background-black'>
    <div class='position-relative'>
        <div class='image-three-by-two'>
            <img src='../../assets/images/online-female-student-laptop-couch.webp' alt='Example Image'/>
        </div>
        <div class='position-absolute padding-5 full-width overlay-solid-dark' style='bottom: 0px;'>
            <blockquote class='text-white text-shadow'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</blockquote>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import DesignSidebar from '../../components/Sidebars/design-sidebar.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    DesignSidebar,
    PrismEditor,
  },
};
</script>

<style lang="scss">

</style>
