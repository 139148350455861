<!-- eslint-disable max-len -->
<template>
    <prototype-template>
        <div slot="content">
            <div class="container-lg padding-left-3 padding-right-3">
                <form class="margin-3 padding-1 background-white padding-5" autocomplete="off">
                    <h3 class="heading-display-h3">Contact Form</h3>
                    <p>Fill out this form so that we may reach you concerning your interests in one of the following departments.</p>
                    <div class="form-group">
                      <label for="first-name" class="form-label">First Name</label>
                      <input id="first-name" type="text" placeholder="First Name" class="form-field" autocomplete="off" required>
                    </div>
                    <div class="form-group">
                      <label for="last-name" class="form-label">Last Name</label>
                      <input id="last-name" type="text" placeholder="Last Name" class="form-field" required>
                    </div>
                    <div class="form-group">
                      <label for="email-address" class="form-label">Email Address</label>
                      <input id="email-address" type="email" placeholder="email@emailaddress.com" class="form-field" required>
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="department">Choose a department</label>
                      <div class="select">
                        <select class="select-dropdown"
                          id="department" aria-labelledby="department">
                          <option>Admissions</option>
                          <option>Financial Aid</option>
                          <option>Parking Services</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="comments" class="form-label">Comments</label>
                      <textarea id="comments" class="form-textarea" ></textarea>
                    </div>
                    <div class="form-row">
                      <label for = "Newsletter">I wish to receive your newsletter</label>
                      <input type = "checkbox" id = "Newsletter" class="margin-left-3">
                    </div>
                    <div class="form-row">
                      <label for = "No-Newsletter"> I do not wish to receive your newsletter</label>
                      <input type = "checkbox" id = "No-Newsletter" class="margin-left-3">
                    </div>
                    <button class="button button-secondary-outline margin-top">Submit</button>
                </form>
            </div>
        </div>
    </prototype-template>
</template>

<script>
import PrototypeTemplate from '../../../components/Base/prototype-template.vue';

export default {
  name: 'flexbox-prototype',
  components: {
    PrototypeTemplate,
  },
};
</script>
