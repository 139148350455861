<!-- eslint-disable max-len -->
<template>
    <prototype-template>
        <div slot="content">
            <div class="container-lg padding-left-3 padding-right-3">
                <h1>Facilisis magna etiam tempor orci eu. Metus vulputate eu scelerisque felis imperdiet proin fermentum.</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                    Velit laoreet id donec ultrices tincidunt. Lectus magna fringilla urna porttitor.
                    Ipsum faucibus vitae aliquet nec ullamcorper. Integer enim neque volutpat ac tincidunt vitae semper.
                    Facilisis magna etiam tempor orci eu. Metus vulputate eu scelerisque felis imperdiet proin
                    fermentum. Facilisis gravida neque convallis a cras semper. Libero enim sed faucibus turpis in.
                    Proin fermentum leo vel orci.</p>
                <div class="flex-container">
                    <div class="flex-grid">
                        <div class="flex-grid-item-lg">
                            <div class="card shadow-depth-1 margin-bottom-3">
                                <div class="card-header padding-bottom-0">
                                    <h2 class="heading-size-h3 is-bold">Lorem Ipsum</h2>
                                </div>
                                <div class="card-body padding-top-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <div class="card-footer">
                                    <a class="button button-primary" href="#">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grid-item-lg">
                            <div class="card shadow-depth-1 margin-bottom-3">
                                <div class="card-header padding-bottom-0">
                                    <h2 class="heading-size-h3 is-bold">Lorem Ipsum</h2>
                                </div>
                                <div class="card-body padding-top-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <div class="card-footer">
                                    <a class="button button-primary" href="#">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grid-item-lg">
                            <div class="card shadow-depth-1 margin-bottom-3">
                                <div class="card-header padding-bottom-0">
                                    <h2 class="heading-size-h3 is-bold">Lorem Ipsum</h2>
                                </div>
                                <div class="card-body padding-top-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <div class="card-footer">
                                    <a class="button button-primary" href="#">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </prototype-template>
</template>

<script>
import PrototypeTemplate from '../../../components/Base/prototype-template.vue';

export default {
  name: 'flexbox-prototype',
  components: {
    PrototypeTemplate,
  },
};
</script>
