<!-- eslint-disable max-len -->
<template>
    <div>
        <h2 id="horizontal-card-stack" class="anchor-link" tabindex="-1">Horizontal Card Stack</h2>
        <p>The horizontal card component is a card that is horizontal in orientation and can stack on top of one another.
            The horizontal card is also responsive and collapses to a normal card at smaller viewports. You may also add the flex utility <code class="code-highlight">.flex-row-reverse-md</code> to alternate image position.
            Other flex utilities and other utility classes can be applied to modify styling.</p>
        <div class="border padding-2 background-gray-light-20">
            <div class="card card-horizontal">
                <div class="card-horizontal-section">
                    <div class="image-four-by-three">
                        <img src="../../../assets/images/image-size-example.jpg" alt="example image" />
                    </div>
                </div>
                <div class="card-horizontal-section flex flex-column flex-justify-center">
                    <div class="padding-5">
                        <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </blockquote>
                    </div>
                </div>
            </div>
            <div class="card card-horizontal flex-row-reverse-md">
                <div class="card-horizontal-section">
                    <div class="image-four-by-three">
                        <img src="../../../assets/images/image-size-example.jpg" alt="example image" />
                    </div>
                </div>
                <div class="card-horizontal-section flex flex-column flex-justify-center">
                    <div class="padding-5">
                        <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <pre contenteditable="true"><prism-editor code="<div class='card card-horizontal'>
    <div class='card-horizontal-section'>
        <div class='image-four-by-three'>
            <img src='../../../assets/images/image-size-example.jpg' alt='example image' />
        </div>
    </div>
    <div class='card-horizontal-section flex flex-column flex-justify-center'>
        <div class='padding-5'>
            <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </blockquote>
        </div>
    </div>
</div>
<div class='card card-horizontal flex-row-reverse-md'>
    <div class='card-horizontal-section'>
        <div class='image-four-by-three'>
            <img src='../../../assets/images/image-size-example.jpg' alt='example image' />
        </div>
    </div>
    <div class='card-horizontal-section flex flex-column flex-justify-center'>
        <div class='padding-5'>
            <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </blockquote>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'horizontal-card',
  components: {
    PrismEditor,
  },
};
</script>
