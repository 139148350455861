<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <layout-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Containers</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <p>Intended for easily centering your content horizontally, container widths match the breakpoints and are available at a sm, md, lg and xl size.
              Containers apply a max-width rather than a fixed-width for responsive layouts.</p>
              <div class="margin-bottom">
                <div class="container-sm border padding-1 background-blue-light-10">
                    .container-sm, max-width 544px
                </div>
                <div class="container-md border padding-1 margin-top background-blue-light-10">
                  .container-md, max-width 768px
                </div>
                <div class="container-lg border padding-1 margin-top background-blue-light-10">
                  .container-lg, max-width 1012px
                </div>
                <div class="container-xl border padding-1 margin-top background-blue-light-10">
                  .container-xl, max-width 1280px
                </div>
                <div class="container-xxl border padding-1 margin-top background-blue-light-10">
                  .container-xxl, max-width 1366px
                </div>
              </div>
              <div class="example__code border">
                <pre contenteditable="true"><prism-editor code="<div class='margin-bottom'>
  <div class='container-sm border padding-1 background-blue-light-10'>
      .container-sm, max-width 544px
  </div>
  <div class='container-md border padding-1 margin-top background-blue-light-10'>
    .container-md, max-width 768px
  </div>
  <div class='container-lg border padding-1 margin-top background-blue-light-10'>
    .container-lg, max-width 1012px
  </div>
  <div class='container-xl border padding-1 margin-top background-blue-light-10'>
    .container-xl, max-width 1280px
  </div>
  <div class='container-xxl border padding-1 margin-top background-blue-light-10'>
    .container-xxl, max-width 1366px
  </div>
</div>" language="markup"></prism-editor></pre>
              </div>
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import LayoutSidebar from '../../components/Sidebars/layout-sidebar.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    LayoutSidebar,
    PrismEditor,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">

</style>
