<!-- eslint-disable max-len -->
<template>
    <div>
        <h3>Stacked Checkbox</h3>
        <div class="example__container">
            <div class="example__demo">
                <div class="example__demo-section">
                    <form>
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="checkbox-1" value="one" checked="checked">
                                <label for="checkbox-1">One</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="checkbox-2" value="two">
                                <label for="checkbox-2">Two</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<form>
    <div class='form-group'>
        <div class='checkbox'>
            <input type='checkbox' id='checkbox-1' value='one' checked='checked'>
            <label for='checkbox-1'>One</label>
        </div>
    </div>
    <div class='form-group'>
        <div class='checkbox'>
            <input type='checkbox' id='checkbox-2' value='two'>
            <label for='checkbox-2'>Two</label>
        </div>
    </div>
</form>" language="markup"></prism-editor></pre>
            </div>
        </div>
        <h3>Inline Checkbox</h3>
        <div class="example__container">
            <div class="example__demo">
                <div class="example__demo-section">
                    <form>
                        <div class="form-row">
                            <div class="checkbox">
                                <input type="checkbox" id="checkbox-3" value="three" checked="checked">
                                <label for="checkbox-3">Three</label>
                            </div>
                            <div class="checkbox">
                                <input type="checkbox" id="checkbox-4" value="four">
                                <label for="checkbox-4">Four</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<form>
    <div class='form-row'>
        <div class='checkbox'>
            <input type='checkbox' id='checkbox-3' value='three' checked='checked'>
            <label for='checkbox-3'>Three</label>
        </div>
        <div class='checkbox'>
            <input type='checkbox' id='checkbox-4' value='four'>
            <label for='checkbox-4'>Four</label>
        </div>
    </div>
</form>" language="markup"></prism-editor></pre>
            </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'type-scale',
  components: {
    PrismEditor,
  },
};
</script>
