<!-- eslint-disable max-len -->
<template>
    <div class="design-system__page">
        <page-template>
            <div slot="sidebar">
                <layout-sidebar />
            </div>
            <div slot="content">
                <div class="design-system__page-header">
                    <h1>Flexbox</h1>
                </div>
                <div class="design-system__page-content">
                    <div class="design-system__page-section">
                        <h2>Flexbox Grid</h2>
                        <p>An alternative to the float-based column system, this responsive grid system is based on the flexbox model.</p>
                        <h3 id="the-container" class="anchor-link" tabindex="-1">The Container</h3>
                        <p>The <code class="code-highlight">.flex-container</code> is the basic layout unit for the flexbox grid.
                        It will fill the full width of the viewport with a default of 1rem of padding on the right and left sides of the container.</p>
                        <p>The container is meant to be a generic wrapper to add enough padding to the content of your application so that it doesn’t bump up against the sides of the viewport.
                            The container can be used as is without any other grid items inside, but it is required to wrap all other flex grid elements.</p>
                        <div class="border padding-3">
                            <div class="flex-container">
                                <div class="flex-grid">
                                    <div class="flex-grid-item background-blue-primary text-align-center padding-2">
                                        <span class="text-white"><code>.flex-container</code></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container'>
    <div class='flex-grid'>
        <div class='flex-grid-item background-blue-primary text-align-center padding-2'>
            <span class='text-white'><code>.flex-container</code></span>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="container-sizes" class="anchor-link" tabindex="-1">Container Sizes</h3>
                        <p>If you need to constrain the <code class="code-highlight">.flex-container</code> to a fixed size there are four modifier classes that will set a max-width on the container.</p>
                        <p>They are:</p>
                        <ul>
                            <li><code>.flex-container-sm</code> = 640px</li>
                            <li><code>.flex-container-md</code> = 1024px</li>
                            <li><code>.flex-container-lg</code> = 1140px</li>
                            <li><code>.flex-container-xl</code> = 1380px</li>
                        </ul>
                        <div class="border padding-3">
                            <div class="flex-container-sm margin-bottom">
                                <div class="flex-grid">
                                    <div class="flex-grid-item background-blue-primary text-align-center padding-2">
                                        <span class="text-white"><code>.flex-container-sm</code></span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-container-md margin-bottom">
                                <div class="flex-grid">
                                    <div class="flex-grid-item background-blue-primary text-align-center padding-2">
                                        <span class="text-white"><code>.flex-container-md</code></span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-container-lg margin-bottom">
                                <div class="flex-grid">
                                    <div class="flex-grid-item background-blue-primary text-align-center padding-2">
                                        <span class="text-white"><code>.flex-container-lg</code></span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-container-xl">
                                <div class="flex-grid">
                                    <div class="flex-grid-item background-blue-primary text-align-center padding-2">
                                        <span class="text-white"><code>.flex-container-xl</code></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container-sm margin-bottom'>
    <div class='flex-grid'>
        <div class='flex-grid-item background-blue-primary text-align-center padding-2'>
            <span class='text-white'><code>.flex-container-sm</code></span>
        </div>
    </div>
</div>
<div class='flex-container-md margin-bottom'>
    <div class='flex-grid'>
        <div class='flex-grid-item background-blue-primary text-align-center padding-2'>
            <span class='text-white'><code>.flex-container-md</code></span>
        </div>
    </div>
</div>
<div class='flex-container-lg margin-bottom'>
    <div class='flex-grid'>
        <div class='flex-grid-item background-blue-primary text-align-center padding-2'>
            <span class='text-white'><code>.flex-container-lg</code></span>
        </div>
    </div>
</div>
<div class='flex-container-xl'>
    <div class='flex-grid'>
        <div class='flex-grid-item background-blue-primary text-align-center padding-2'>
            <span class='text-white'><code>.flex-container-xl</code></span>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="container-center" class="anchor-link" tabindex="-1">Centering A Container</h3>
                        <p>To center a container that was given a max-width using the modifier classes above, you can add the class <code class="code-highlight">.flex-container-center</code> .</p>
                        <div class="border padding-3">
                            <div class="flex-container flex-container-sm flex-container-center">
                                <div class="flex-grid">
                                    <div class="flex-grid-item background-blue-primary padding-2">
                                        <span class="text-white">.flex-container-center</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container flex-container-sm flex-container-center'>
    <div class='flex-grid'>
        <div class='flex-grid-item background-blue-primary padding-2'>
            <span class='text-white'>.flex-container-center</span>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="automatic-columns" class="anchor-link" tabindex="-1">Automatic Columns</h3>
                        <p>The flexbox grid evenly distributes children marked with the CSS class <code class="code-highlight">.flex-grid-item</code> inside of a parent <code class="code-highlight">.flex-grid</code> class.
                        For example, a <code class="code-highlight">.flex-grid</code> parent with three <code class="code-highlight">.flex-grid-item</code> children, creates three equal width columns.</p>
                        <div class="flex-container padding-top-3 padding-bottom-3 border">
                            <div class="flex-grid">
                                <div class="flex-grid-item">
                                    <div class="background-blue-primary text-white padding-2">Grid Item</div>
                                </div>
                                <div class="flex-grid-item">
                                    <div class="background-blue-primary text-white padding-2">Grid Item</div>
                                </div>
                                <div class="flex-grid-item">
                                    <div class="background-blue-primary text-white padding-2">Grid Item</div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container padding-top-3 padding-bottom-3 border'>
    <div class='flex-grid'>
        <div class='flex-grid-item'>
            <div class='background-blue-primary text-white padding-2'>Grid Item</div>
        </div>
        <div class='flex-grid-item'>
            <div class='background-blue-primary text-white padding-2'>Grid Item</div>
        </div>
        <div class='flex-grid-item'>
            <div class='background-blue-primary text-white padding-2'>Grid Item</div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="responsive-columns" class="anchor-link" tabindex="-1">Responsive Automatic Columns</h3>
                        <p>Automatic columns will be distributed equally across all screen sizes by default.
                        You can use additional responsive classes to specify the screen size at which you would like your grid to become an auto grid.</p>
                        <p>In the example below, we’ve used the -lg suffix on the <code class="code-highlight">.flex-grid-item</code> CSS class.
                            This will make a grid that starts out collapsed into a single column on all screens up to large screens (1080px wide), at which point <code class="code-highlight">.flex-grid-item-lg</code> will become evenly distributed columns.</p>
                        <p>This convention will work for any of the design system breakpoints: -sm, -md, -lg, -xl.</p>
                        <div class="border padding-top-3">
                            <div class="flex-container">
                                <div class="flex-grid">
                                    <div class="flex-grid-item-lg margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">Auto Grid Item</div>
                                    </div>
                                    <div class="flex-grid-item-lg margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">Auto Grid Item</div>
                                    </div>
                                    <div class="flex-grid-item-lg margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">Auto Grid Item</div>
                                    </div>
                                    <div class="flex-grid-item-lg margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">Auto Grid Item</div>
                                    </div>
                                    <div class="flex-grid-item-lg margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">Auto Grid Item</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container'>
    <div class='flex-grid'>
        <div class='flex-grid-item-lg margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>Auto Grid Item</div>
        </div>
        <div class='flex-grid-item-lg margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>Auto Grid Item</div>
        </div>
        <div class='flex-grid-item-lg margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>Auto Grid Item</div>
        </div>
        <div class='flex-grid-item-lg margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>Auto Grid Item</div>
        </div>
        <div class='flex-grid-item-lg margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>Auto Grid Item</div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="flex-column-widths" class="anchor-link" tabindex="-1">Flex Column Widths</h3>
                        <p>You can set a specific number of columns you want grid items to span by including the number of columns in the responsive grid class.
                            For example, adding the class <code class="code-highlight">.flex-grid-item-6-md</code> would make a grid item span six columns at medium screens (740px) and larger.</p>
                        <p>When you explicitly set column widths, remember that the total number of columns should always equal 12.</p>
                        <div class="border padding-top-3">
                            <div class="flex-container flex-container-lg flex-container-center">
                                <div class="flex-grid">
                                    <div class="flex-grid-item-4-md margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">3 Columns Medium And Up</div>
                                    </div>
                                    <div class="flex-grid-item-4-md margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">3 Columns Medium And Up</div>
                                    </div>
                                    <div class="flex-grid-item-4-md margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">3 Columns Medium And Up</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container flex-container-lg flex-container-center'>
    <div class='flex-grid'>
        <div class='flex-grid-item-4-md margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>4 Columns Medium And Up</div>
        </div>
        <div class='flex-grid-item-4-md margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>4 Columns Medium And Upp</div>
        </div>
        <div class='flex-grid-item-4-md margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>4 Columns Medium And Up</div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="mixed-column-widths" class="anchor-link" tabindex="-1">Mixing Column Widths With Auto Columns</h3>
                        <p>It’s also possible to mix and match specified columns with <code class="code-highlight">.flex-grid-item</code> auto columns.
                        The grid items with specified columns will span the specified amount of columns and any auto grid items will fill the remaining space.</p>
                        <p>It’s important to note that the auto grid items will stack at whichever grid breakpoint you specify.
                            In the example below we are using <code class="code-highlight">.flex-grid-item-6-md</code> to set a width of six columns on medium screens and larger, so the auto grid items will inherit the same behavior.</p>
                        <div class="border padding-top-3">
                            <div class="flex-container">
                                <div class="flex-grid">
                                    <div class="flex-grid-item margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2"><code>.flex-grid-item</code></div>
                                    </div>
                                    <div class="flex-grid-item-6-md margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2"><code>.flex-grid-item-6-md</code></div>
                                    </div>
                                    <div class="flex-grid-item margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2"><code>.flex-grid-item</code></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container'>
    <div class='flex-grid'>
        <div class='flex-grid-item margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'><code>.flex-grid-item</code></div>
        </div>
        <div class='flex-grid-item-6-md margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'><code>.flex-grid-item-6-md</code></div>
        </div>
        <div class='flex-grid-item margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'><code>.flex-grid-item</code></div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="push-pull" class="anchor-link" tabindex="-1">Push and Pull</h3>
                        <p>When you need to change the visual order of the grid items, you can use <code class="code-highlight">.flex-grid-item-push-*</code> and <code class="code-highlight">.flex-grid-item-pull-*</code> utilities.</p>
                        <div class="border padding-top-3">
                            <div class="flex-container">
                                <div class="flex-grid">
                                    <div class="flex-grid-item-4-md flex-grid-item-push-8-md margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">
                                            I'm first in the source order
                                        </div>
                                    </div>
                                    <div class="flex-grid-item-8-md flex-grid-item-pull-4-md margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">
                                            I'm second in the source order
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container'>
    <div class='flex-grid'>
        <div class='flex-grid-item-4-md flex-grid-item-push-8-md margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>
                I'm first in the source order
            </div>
        </div>
        <div class='flex-grid-item-8-md flex-grid-item-pull-4-md margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>
                I'm second in the source order
            </div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="right-align" class="anchor-link" tabindex="-1">Right-align Last Item</h3>
                        <p>You can right-align the last item in a .rvt-grid by adding the <code class="code-highlight">.flex-grid-item-last</code> utility to any grid item.
                            This can be helpful when using set column widths that add up to less than 12 columns.
                            Using the <code class="code-highlight">.flex-grid-item-last</code> will push the last <code class="code-highlight">.flex-grid-item-*</code> to the right, leaving the remaining columns as extra space.</p>
                        <div class="border padding-top-3">
                            <div class="flex-container">
                                <div class="flex-grid">
                                    <div class="flex-grid-item-4-sm margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">
                                            Grid Item Four Small
                                        </div>
                                    </div>
                                    <div class="flex-grid-item-7-sm flex-grid-item-last margin-bottom-3">
                                        <div class="background-blue-primary text-white padding-2">
                                            Grid Item 7 Seven Small. 1 Left Over
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container'>
    <div class='flex-grid'>
        <div class='flex-grid-item-4-sm margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>
                Grid Item Four Small
            </div>
        </div>
        <div class='flex-grid-item-7-sm flex-grid-item-last margin-bottom-3'>
            <div class='background-blue-primary text-white padding-2'>
                Grid Item 7 Seven Small. 1 Left Over
            </div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                    <div class="design-system__page-section">
                        <h3 id="nesting-grid" class="anchor-link" tabindex="-1">Nesting Flex Grid Items</h3>
                        <p>You can nest new <code class="code-highlight">.flex-grid</code> blocks inside of <code class="code-highlight">.flex-grid-item-*</code> elements if you need more control over your layout.</p>
                        <div class="border padding-top-3">
                            <div class="flex-container">
                                <div class="flex-grid">
                                    <div class="flex-grid-item-7-md flex-grid-item-9-lg">
                                        <div class="background-blue-primary-30 padding-2 margin-bottom-3">
                                            Level one
                                            <div class="flex-grid">
                                                <div class="flex-grid-item-7-md flex-grid-item-9-lg">
                                                    <div class="background-blue-primary text-white padding-2 margin-2">
                                                        Level Two
                                                    </div>
                                                </div>
                                                <div class="flex-grid-item-5-md flex-grid-item-3-lg">
                                                    <div class="background-blue-primary text-white padding-2 margin-2">Level Two</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grid-item-5-md flex-grid-item-3-lg">
                                        <div class="background-blue-primary-30 padding-2 margin-bottom-3">
                                            Level one
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="example__code border margin-bottom-4">
                            <pre contenteditable="true"><prism-editor code="<div class='flex-container'>
    <div class='flex-grid'>
        <div class='flex-grid-item-7-md flex-grid-item-9-lg'>
            <div class='background-blue-primary-30 padding-2 margin-bottom-3'>
                Level one
                <div class='flex-grid'>
                    <div class='flex-grid-item-7-md flex-grid-item-9-lg'>
                        <div class='background-blue-primary text-white padding-2 margin-2'>
                            Level Two
                        </div>
                    </div>
                    <div class='flex-grid-item-5-md flex-grid-item-3-lg'>
                        <div class='background-blue-primary text-white padding-2 margin-2'>Level Two</div>
                    </div>
                </div>
            </div>
        </div>
        <div class='flex-grid-item-5-md flex-grid-item-3-lg'>
            <div class='background-blue-primary-30 padding-2 margin-bottom-3'>
                Level one
            </div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                        </div>
                    </div>
                </div>
            </div>
        </page-template>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../../components/Base/page-template.vue';
import LayoutSidebar from '../../../components/Sidebars/layout-sidebar.vue';


export default {
  name: 'flexbox',
  components: {
    PageTemplate,
    LayoutSidebar,
    PrismEditor,
  },
};
</script>
