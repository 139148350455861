<!-- eslint-disable max-len -->
<template>
    <div class="design-system__typography-section">
        <div class="design-system__font-families">
            <span class="font-size-1">Text Size 1</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 56px - 3.5rem;
$text-size-1" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-1'>Text Size 1</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-2">Text Size 2</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 48px - 3rem;
$text-size-2" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-2'>Text Size 2</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-3">Text Size 3</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="/ variable - 44px - 2.75rem;
$text-size-3" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-3'>Text Size 3</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-4">Text Size 4</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 40px - 2.5rem;
$text-size-4" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-4'>Text Size 4</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-5">Text Size 5</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 36px - 2.25rem;
$text-size-5" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-5'>Text Size 5</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-6">Text Size 6</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 32px - 2rem;
$text-size-6" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-6'>Text Size 6</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-7">Text Size 7</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 28px - 1.75rem;
$text-size-7" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-7'>Text Size 7</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-8">Text Size 8</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 24px - 1.5rem;
$text-size-8" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-8'>Text Size 8</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-9">Text Size 9</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 20px - 1.25rem;
$text-size-9" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-9'>Text Size 9</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-10">Text Size 10</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 16px - 1rem;
$text-size-10" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-10'>Text Size 10</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
        <div class="design-system__font-families">
            <span class="font-size-11">Text Size 11</span>
            <div class="design-system__font-code">
            <pre contenteditable="true"><prism-editor code="// variable - 14px - .875rem;
$text-size-11" language="scss"></prism-editor>
            <prism-editor code="<span class='font-size-11'>Text Size 11</span>" language="markup"></prism-editor></pre>
        </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'type-scale',
  components: {
    PrismEditor,
  },
};
</script>
