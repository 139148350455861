<!-- eslint-disable max-len -->
<template>
    <div class="margin-top-6">
        <h2 id="grid-gap" class="anchor-link" tabindex="-1">Grid Gap</h2>
        <p>The classes <code class="code-highlight">.grid-columns-gap-{x}</code> and <code class="code-highlight">.grid-rows-gap-{x}</code> controls the gutter between rows and columns.
        Gap sizes are based on the same <router-link :to="{name:'spacing'}">spacing standards</router-link> as used by margins and padding.</p>
        <div class="margin-y-4">
            <div class="grid grid-columns-3 grid-columns-gap-2 background-blue-primary-10 padding-3">
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">1</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">2</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">3</span>
                </div>
            </div>
            <div class="grid grid-columns-3 grid-columns-gap-3 background-blue-primary-10 padding-3">
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">1</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">2</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">3</span>
                </div>
            </div>
            <div class="grid grid-columns-3 grid-columns-gap-4 background-blue-primary-10 padding-3">
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">1</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">2</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">3</span>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-columns-3 grid-columns-gap-2 background-blue-primary-10 padding-3'>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>1</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>2</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>3</span>
        </div>
    </div>
    <div class='grid grid-columns-3 grid-columns-gap-3 background-blue-primary-10 padding-3'>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>1</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>2</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>3</span>
        </div>
    </div>
    <div class='grid grid-columns-3 grid-columns-gap-4 background-blue-primary-10 padding-3'>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>1</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>2</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>3</span>
        </div>
    </div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
