<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <layout-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Layout</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 id="display" class="anchor-link" tabindex="-1">Display</h2>
            <display />
          </div>
          <div class="design-system__page-section">
            <h2 id="position" class="anchor-link" tabindex="-1">Position</h2>
            <position />
          </div>
          <div class="design-system__page-section">
            <h2 id="alignment" class="anchor-link" tabindex="-1">Alignment</h2>
            <alignment />
          </div>
          <div class="design-system__page-section">
            <h2 id="width" class="anchor-link" tabindex="-1">Width</h2>
            <width />
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PageTemplate from '../../../components/Base/page-template.vue';
import LayoutSidebar from '../../../components/Sidebars/layout-sidebar.vue';
import Display from './Display.vue';
import Alignment from './Alignment.vue';
import Position from './Position.vue';
import Width from './Width.vue';


export default {
  name: 'home',
  components: {
    PageTemplate,
    LayoutSidebar,
    Display,
    Alignment,
    Position,
    Width,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">

</style>
