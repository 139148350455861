import 'isomorphic-fetch';
import 'es6-promise/auto';
import Vue from 'vue';
import 'prismjs';
import 'prismjs/themes/prism-coy.css';
import 'prismjs/components/prism-scss.min';
import 'prismjs/components/prism-markup-templating.min';
import 'prismjs/components/prism-markup.min';
import 'clipboard';
// import VuePrism from 'vue-prism';
import VueMq from 'vue-mq';
import router from './router';
import store from './store';
import App from './App.vue';

const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);
// Vue.use(VuePrism);

Vue.use(VueMq, {
  breakpoints: {
    xxs: 415,
    xs: 768,
    sm: 1024,
    md: 1280,
    lg: 1366,
    xl: 1920,
    xxl: Infinity,
  },
});

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
