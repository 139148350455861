<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <page-template>
      <div slot="sidebar">
        <components-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Cards</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <p>Flexible and reusable components for grouping content.</p>
            <h2 id="basic-card" class="anchor-link" tabindex="-1">Basic Card</h2>
            <p>Has a white background and will stretch to 100% width of its parent container.
              Cards are customizable using various utility classes and the component has a header, body and footer sections.</p>
            <div class="border">
                <div class="background-gray-light-10 padding-y-3">
                    <div class="flex-container">
                      <div class="flex-grid">
                        <div class="flex-grid-item-lg margin-bottom-3">
                          <div class="card">
                            <div class="card-header">
                              <h3 class="margin-0">Header Content</h3>
                            </div>
                            <div class="card-body">
                              <p class="font-size-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                            <div class="card-footer">
                              <button class="button button-primary">Read Article</button>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grid-item-lg">
                          <div class="card">
                            <div class="card-header">
                              <h3 class="margin-0">Header Content</h3>
                            </div>
                            <div class="card-body">
                              <p class="font-size-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                            <div class="card-footer">
                              <button class="button button-primary">Read Article</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div>
                  <pre contenteditable="true"><prism-editor code="<div class='card'>
  <div class='card-header'>
    <h3 class='margin-0'>Header Content</h3>
  </div>
  <div class='card-body'>
    <p class='font-size-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
  </div>
  <div class='card-footer'>
    <button class='button button-primary'>Read Article</button>
  </div>
</div>" language="markup"></prism-editor></pre>
              </div>
            </div>
          </div>
          <div class="design-system__page-section">
           <h2 id="card-link" class="anchor-link" tabindex="-1">Card As A Link</h2>
           <p></p>
          </div>
          <div class="design-system__page-section">
           <cards-image-top />
          </div>
          <div class="design-system__page-section">
           <horizontal-card-stack />
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

import PageTemplate from '../../../components/Base/page-template.vue';
import ComponentsSidebar from '../../../components/Sidebars/components-sidebar.vue';
import CardsImageTop from './CardsImageTop.vue';
import HorizontalCardStack from './HorizontalCardStack.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    ComponentsSidebar,
    PrismEditor,
    CardsImageTop,
    HorizontalCardStack,
  },
};
</script>

<style lang="scss">
  .design {
    height: 100%;
    &__content {
      padding: $spacer-4;
    }
  }
</style>
