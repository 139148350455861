<!-- eslint-disable max-len -->
<template>
    <div>
        <h3>Stacked Inputs</h3>
            <p>Input fields are intended to stretch to the width of their container. Limiting the size of the container will limit the size of the inputs within&mdash;additionally, inputs can be stacked by wrapping a label and input within a <code class="code-highlight">.form-group</code> element.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <form>
                      <div class="form-group">
                        <label for="fName" class="form-label">First Name</label>
                        <input id="fName" type="text" placeholder="First Name" class="form-field" aria-labelledby="fName">
                      </div>
                      <div class="form-group">
                        <label for="lName" class="form-label">Last Name</label>
                        <input id="lName" type="text" placeholder="Last Name" class="form-field">
                      </div>
                    </form>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<form>
  <div class='form-group'>
    <label for='first-name' class='form-label'>First Name</label>
    <input id='first-name' type='text' placeholder='First Name' class='form-field'>
  </div>
  <div class='form-group'>
    <label for='last-name' class='form-label'>Last Name</label>
    <input id='last-name' type='text' placeholder='Last Name' class='form-field'>
  </div>
</form>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <h3>Inline Inputs</h3>
            <p>To inline your input fields, wrap <code class="code-highlight">.form-group</code> elements within a <code class="code-highlight">.form-row</code> element.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <form>
                      <div class="form-row">
                        <div class="form-group">
                          <label for="firstName" class="form-label">First Name</label>
                          <input id="firstName" type="text" placeholder="First Name" class="form-field">
                        </div>
                        <div class="form-group">
                          <label for="lastName" class="form-label">Last Name</label>
                          <input id="lastName" type="text" placeholder="Last Name" class="form-field">
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<form>
  <div class='form-row'>
    <div class='form-group'>
      <label for='first-name' class='form-label'>First Name</label>
      <input id='first-name' type='text' placeholder='First Name' class='form-field'>
    </div>
    <div class='form-group'>
      <label for='last-name' class='form-label'>Last Name</label>
      <input id='last-name' type='text' placeholder='Last Name' class='form-field'>
    </div>
  </div>
</form>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <h3>Grouped Stacked and Inline Inputs</h3>
            <p>To combine stacked and inline input fields, wrap a stacked <code class="code-highlight">.form-group</code> within a <code class="code-highlight">.form-column</code> element.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section border-0">
                    <form>
                      <div class="form-row">
                        <div class="form-group">
                          <label for="first-name" class="form-label">First Name</label>
                          <input id="first-name" type="text" placeholder="First Name" class="form-field">
                        </div>
                        <div class="form-group">
                          <label for="last-name" class="form-label">Last Name</label>
                          <input id="last-name" type="text" placeholder="Last Name" class="form-field">
                        </div>
                      </div>
                      <div class="form-column">
                        <div class="form-group">
                          <label for="email-address" class="form-label">Email Address</label>
                          <input id="email-address" type="email" placeholder="email@emailaddress.com" class="form-field">
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<form>
  <div class='form-row'>
    <div class='form-group'>
      <label for='first-name' class='form-label'>First Name</label>
      <input id='first-name' type='text' placeholder='First Name' class='form-field'>
    </div>
    <div class='form-group'>
      <label for='last-name' class='form-label'>Last Name</label>
      <input id='last-name' type='text' placeholder='Last Name' class='form-field'>
    </div>
  </div>
  <div class='form-column'>
    <label for='email-address' class='form-label'>Email Address</label>
    <input id='email-address' type='email' placeholder='email@emailaddress.com' class='form-field'>
  </div>
</form>" language="markup"></prism-editor></pre>
                </div>
            </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'type-scale',
  components: {
    PrismEditor,
  },
};
</script>
