<template>
    <li class="mobile-sidebar__nav-item">
        <div class="mobile-sidebar__nav-item-link">
            <router-link :to="{name: linkLocation}" @click.native="toggleMobileSidebar">
                <span >{{ linkName }}</span>
            </router-link>
            <button class="mobile-sidebar__menu-expand"
                v-if="sublinks.length !== 0"
                @click="isExpanded = !isExpanded;"
                :class="menuExpand"
                :aria-expanded="isExpanded.toString()"
                :aria-controls="id"
                >
                <span class="hidden-button-text">Toggle Sub Menu</span>
                <span class="icon-chevron-down"></span>
            </button>
        </div>
        <ul class="mobile-sidebar__nav-submenu" v-show="isExpanded" :id="id"
            v-if="sublinks.length !== 0">
            <li class="mobile-sidebar__nav-submenu-item"
                v-for="link in sublinks"
                :key="link.linkName">
                <router-link :to="{name: link.linkLocation}" @click.native="toggleMobileSidebar">
                    {{ link.linkName }}
                </router-link>
            </li>
        </ul>
    </li>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    linkName: {
      type: String,
      required: true,
    },
    linkLocation: {
      type: String,
      required: true,
    },
    sublinks: {
      type: Array,
      required: false,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapState('sidebar', ['menuItems']),
    menuExpand() {
      return {
        'is-expanded': this.isExpanded === true,
      };
    },
  },
  methods: {
    ...mapMutations('sidebar', ['toggleMobileSidebar']),
  },
};
</script>
