<template>
    <div class="sidebar">
        <h3 class="sidebar__section-title">Prototypes</h3>
         <ul class="sidebar__menu">
            <li v-for="category in categories" :key="category.categoryName">
                {{ category.categoryName }}
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ScrollerMixin from './scrollmixin';

export default {
  mixins: [ScrollerMixin],
  name: 'prototypes-sidebar',
  computed: {
    ...mapState('prototypes', ['categories']),
  },
};
</script>
