<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <layout-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Columns</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <p>Columns are based on a 12-column system.
              Based on a 12-column system, the number of columns a container spans can be adjusted across breakpoints for responsive layouts.
              Columns also work with a variety of utilities, such as margins and padding, to achieve a variety of layouts.</p>
            <h2 id="float-columns" class="anchor-link" tabindex="-1">Float Based Columns</h2>
            <p>Nest float-based columns within a <code class="code-highlight">.row</code> element and add a class of <code class="code-highlight">.float-left</code> to the column.
            To reverse the order of the columns, add <code class="code-highlight">.float-right</code> to the column.</p>
            <float-columns />
          </div>
          <div class="design-system__page-section">
            <h2 id="nesting-columns" class="anchor-link" tabindex="-1">Nesting Columns</h2>
            <p>You can infinitely nest grid layouts within other columns because the column widths are percentage based.</p>
            <div class="row margin-bottom">
              <div class="col-12 border padding background-blue-light-60">
                <div class="row">
                  <div class="col-6 float-left border padding background-blue-light-30">
                    <div class="row">
                      <div class="col-6 float-left border padding background-blue-light-10"></div>
                      <div class="col-6 float-left border padding background-blue-light-10"></div>
                    </div>
                  </div>
                  <div class="col-6 float-left border padding background-blue-light-30">
                    <div class="row">
                      <div class="col-6 float-left border padding background-blue-light-10"></div>
                      <div class="col-6 float-left border padding background-blue-light-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-12 border padding background-blue-light-60'>
    <div class='row'>
      <div class='col-6 float-left border padding background-blue-light-30'>
        <div class='row'>
          <div class='col-6 float-left border padding background-blue-light-10'></div>
          <div class='col-6 float-left border padding background-blue-light-10'></div>
        </div>
      </div>
      <div class='col-6 float-left border padding background-blue-light-30'>
        <div class='row'>
          <div class='col-6 float-left border padding background-blue-light-10'></div>
          <div class='col-6 float-left border padding background-blue-light-10'></div>
        </div>
      </div>
    </div>
  </div>
</div>" language="markup"></prism-editor></pre>
          </div>
          <div class="design-system__page-section">
            <h2 id="offset-columns" class="anchor-link" tabindex="-1">Offset Columns</h2>
            <p>Column offset classes allow you to push a div over X number of columns and work responsively using the breakpoints outlined below.</p>
            <div class="example__container">
                <div class="row margin">
                  <div class="offset-1 col-3 border padding margin-bottom">.offset-1</div>
                  <div class="offset-2 col-3 border padding">.offset-2</div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<div class='row margin'>
  <div class='offset-1 col-3 border padding margin-bottom'>.offset-1</div>
  <div class='offset-2 col-3 border padding'>.offset-2</div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="responsive-columns" class="anchor-link" tabindex="-1">Responsive Columns</h2>
            <p>All the column width classes can be set per breakpoint to create responsive grid layouts. Each responsive style is applied to the specified breakpoint and up.</p>
            <responsive-columns/>
          </div>
          <div class="design-system__page-section">
            <h2 id="gutters" class="anchor-link" tabindex="-1">Gutters</h2>
            <p>Use gutter styles&mdash;gutter (default), gutter condensed, gutter spacious&mdash;or padding utilities to create gutters.
              Gutter styles support responsive breakpoint modifiers and add padding to the left and right side of each column.
              This applies a negative margin to the container to ensure content inside each column lines up with content outside of the grid.</p>
            <gutters />
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../../components/Base/page-template.vue';
import LayoutSidebar from '../../../components/Sidebars/layout-sidebar.vue';
import FloatColumns from './FloatColumns.vue';
import ResponsiveColumns from './ResponsiveColumns.vue';
import Gutters from './Gutters.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    LayoutSidebar,
    FloatColumns,
    ResponsiveColumns,
    Gutters,
    PrismEditor,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">

</style>
