<!-- eslint-disable max-len -->
<template>
    <div class="container-lg">
        <div class="row margin-bottom">
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-2 float-left background-blue-light-20 border padding-1">.col-2</div>
          <div class="col-2 float-left background-blue-light-20 border padding-1">.col-2</div>
          <div class="col-2 float-left background-blue-light-20 border padding-1">.col-2</div>
          <div class="col-2 float-left background-blue-light-20 border padding-1">.col-2</div>
          <div class="col-2 float-left background-blue-light-20 border padding-1">.col-2</div>
          <div class="col-2 float-left background-blue-light-20 border padding-1">.col-2</div>
        </div>
        <div class="example__code border margin-bottom-4">
                <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-2 float-left background-blue-light-20 border padding-1'>.col-2</div>
  <div class='col-2 float-left background-blue-light-20 border padding-1'>.col-2</div>
  <div class='col-2 float-left background-blue-light-20 border padding-1'>.col-2</div>
  <div class='col-2 float-left background-blue-light-20 border padding-1'>.col-2</div>
  <div class='col-2 float-left background-blue-light-20 border padding-1'>.col-2</div>
  <div class='col-2 float-left background-blue-light-20 border padding-1'>.col-2</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-3 float-left background-blue-light-20 border padding-1">.col-3</div>
          <div class="col-3 float-left background-blue-light-20 border padding-1">.col-3</div>
          <div class="col-3 float-left background-blue-light-20 border padding-1">.col-3</div>
          <div class="col-3 float-left background-blue-light-20 border padding-1">.col-3</div>
        </div>
        <div class="example__code border margin-bottom-4">
             <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-3 float-left background-blue-light-20 border padding-1'>.col-3</div>
  <div class='col-3 float-left background-blue-light-20 border padding-1'>.col-3</div>
  <div class='col-3 float-left background-blue-light-20 border padding-1'>.col-3</div>
  <div class='col-3 float-left background-blue-light-20 border padding-1'>.col-3</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-4 float-left background-blue-light-20 border padding-1">.col-4</div>
          <div class="col-4 float-left background-blue-light-20 border padding-1">.col-4</div>
          <div class="col-4 float-left background-blue-light-20 border padding-1">.col-4</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-4 float-left background-blue-light-20 border padding-1'>.col-4</div>
  <div class='col-4 float-left background-blue-light-20 border padding-1'>.col-4</div>
  <div class='col-4 float-left background-blue-light-20 border padding-1'>.col-4</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-5 float-left background-blue-light-20 border padding-1">.col-5</div>
          <div class="col-5 float-left background-blue-light-20 border padding-1">.col-5</div>
          <div class="col-2 float-left background-blue-light-20 border padding-1">.col-2</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-5 float-left background-blue-light-20 border padding-1'>.col-5</div>
  <div class='col-5 float-left background-blue-light-20 border padding-1'>.col-5</div>
  <div class='col-2 float-left background-blue-light-20 border padding-1'>.col-2</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-6 float-left background-blue-light-20 border padding-1">.col-6</div>
          <div class="col-6 float-left background-blue-light-20 border padding-1">.col-6</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row margin-bottom'>
  <div class='col-6 float-left background-blue-light-20 border padding-1'>.col-6</div>
  <div class='col-6 float-left background-blue-light-20 border padding-1'>.col-6</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-7 float-left background-blue-light-20 border padding-1">.col-7</div>
          <div class="col-5 float-left background-blue-light-20 border padding-1">.col-5</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-7 float-left background-blue-light-20 border padding-1'>.col-7</div>
  <div class='col-5 float-left background-blue-light-20 border padding-1'>.col-5</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-8 float-left background-blue-light-20 border padding-1">.col-8</div>
          <div class="col-4 float-left background-blue-light-20 border padding-1">.col-4</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-8 float-left background-blue-light-20 border padding-1'>.col-8</div>
  <div class='col-4 float-left background-blue-light-20 border padding-1'>.col-4</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-9 float-left background-blue-light-20 border padding-1">.col-9</div>
          <div class="col-3 float-left background-blue-light-20 border padding-1">.col-3</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-9 float-left background-blue-light-20 border padding-1'>.col-9</div>
  <div class='col-3 float-left background-blue-light-20 border padding-1'>.col-3</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-10 float-left background-blue-light-20 border padding-1">.col-10</div>
          <div class="col-2 float-left background-blue-light-20 border padding-1">.col-2</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-10 float-left background-blue-light-20 border padding-1'>.col-10</div>
  <div class='col-2 float-left background-blue-light-20 border padding-1'>.col-2</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-11 float-left background-blue-light-20 border padding-1">.col-11</div>
          <div class="col-1 float-left background-blue-light-20 border padding-1">.col-1</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
  <div class='col-11 float-left background-blue-light-20 border padding-1'>.col-11</div>
  <div class='col-1 float-left background-blue-light-20 border padding-1'>.col-1</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="row margin-bottom">
          <div class="col-12 float-left background-blue-light-20 border padding-1">.col-12</div>
        </div>
        <div class="example__code border margin-bottom-4">
          <pre contenteditable="true"><prism-editor code="<div class='row'>
          <div class='col-12 float-left background-blue-light-20 border padding-1'>.col-12</div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  components: {
    PrismEditor,
  },
};
</script>
