<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <page-template>
      <div slot="sidebar">
        <components-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Header</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2>Generic Header</h2>
            <p>This generic header component is a very simple header design for small and simple websites. The header allows for a logo image and title on the left and content such as links or buttons on the right.</p>
            <p>In mobile views, the header will collapse to show only the logo and a mobile menu toggle if needed. The title or other header information will appear in the sub-header.</p>
            <div class="padding-4 background-gray-light-30">
                <header-generic />
            </div>
            <div class="container-lg">
                <div class="example__code margin-top-5 border-1">
                    <pre contenteditable="true"><prism-editor code="<div class='header-generic'>
    <div class='header-generic-container'>
        <div class='header-generic-brand'>
            <div class='header-generic-brand-logo'>
                <a href='#maincontent' id='skip-nav'>Skip to Content</a>
                <a class='header-generic-brand-link' href='/'>
                    <img src='../../../assets/Logos/su-header-logo.svg'
                    alt='Syracuse University Design System'
                    class='header-generic-brand-image'/>
                </a>
            </div>
            <div class='header-generic-brand-title'>
                <span>Information Technology Services</span>
            </div>
        </div>
        <div class='header-generic-content'>
            <button class='button button-primary' role='button'>Sign In</button>
        </div>
        <button class='menu-button'>
            <i class='icon-hamburger' />
            <span class='menu-button-label'>Open Menu</span>
        </button>
    </div>
    <div class='header-generic-subhead'>
        <span>Information Technology Services</span>
    </div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import ComponentsSidebar from '../../components/Sidebars/components-sidebar.vue';
import HeaderGeneric from '../../components/Components/headers/header-generic.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    ComponentsSidebar,
    PrismEditor,
    HeaderGeneric,
  },
};
</script>

<style lang="scss">
  .design {
    height: 100%;
    &__content {
      padding: $spacer-4;
    }
  }
</style>
