<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <design-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Overlay</h1>
        </div>
        <div class="design-system__page-content">
            <div class="design-system__page-section">
                <p>Overlays in the design system are used for several purposes such as providing additional contrast between text and images, backgrounds for modals and for general aesthetics.</p>
            </div>
            <div class="design-system__page-section">
                <h2>Solid Overlays</h2>
                <p>Solid Overlays provide a solid, uniform background color with an opacity to your elements. The design system provides four colors that can be used.</p>
                <p>The following examples display the different solid overlays that can be applied to your designs.</p>
                <p><code class="code-highlight">.overlay-solid-dark</code></p>
                <div class="border position-relative">
                    <div class="image-three-by-two">
                        <img src="../../assets/images/online-vibrant-community.webp" alt="example image" />
                    </div>
                    <div class="overlay-solid-dark position-absolute full-width full-height flex flex-column flex-justify-end" style="top: 0">
                        <div class="padding-4">
                            <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                        </div>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='border position-relative'>
    <div class='image-three-by-two'>
        <img src='../../assets/images/online-vibrant-community.webp' alt='example image' />
    </div>
    <div class='overlay-solid-dark position-absolute full-width full-height flex flex-column flex-justify-end' style='top: 0'>
        <div class='padding-4'>
            <p class='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <div class="design-system__page-section">
                <p><code class="code-highlight">.overlay-solid-light</code></p>
                <div class="border position-relative">
                    <div class="image-three-by-two">
                        <img src="../../assets/images/online-vibrant-community.webp" alt="example image" />
                    </div>
                    <div class="overlay-solid-light position-absolute flex flex-column flex-justify-end margin-3" style="top:20%; max-width: 500px;">
                        <div class="padding-4">
                            <p class="padding-left-4 margin-0 border-left-4 border-orange">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='border position-relative'>
    <div class='image-three-by-two'>
        <img src='../../assets/images/online-vibrant-community.webp' alt='example image' />
    </div>
    <div class='overlay-solid-light position-absolute flex flex-column flex-justify-end margin-3' style='top:20%; max-width: 500px;'>
        <div class='padding-4'>
            <p class='padding-left-4 margin-0 border-left-4 border-orange'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <div class="design-system__page-section">
                <p><code class="code-highlight">.overlay-solid-blue</code></p>
                <div class="border position-relative">
                    <div class="image-three-by-two">
                        <img src="../../assets/images/online-vibrant-community.webp" alt="example image" />
                    </div>
                    <div class="overlay-solid-blue position-absolute full-width full-height flex flex-column flex-justify-end" style="top: 0">
                        <div class="padding-4">
                            <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                        </div>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='border position-relative'>
    <div class='image-three-by-two'>
        <img src='../../assets/images/online-vibrant-community.webp' alt='example image' />
    </div>
    <div class='overlay-solid-blue position-absolute full-width full-height flex flex-column flex-justify-end' style='top: 0'>
        <div class='padding-4'>
            <p class='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <div class="design-system__page-section">
                <p><code class="code-highlight">.overlay-solid-orange</code></p>
                <p><strong>Because the orange can when used in large areas be overpowering, it is recommended that this overlay class be used to highlight only smaller page elements.</strong></p>
                <div class="flex-container">
                    <div class="flex-grid">
                        <div class="flex-grid-item-lg margin-bottom">
                            <div class="image-two-by-three">
                                <img src="../../assets/images/CampusBuildings.jpg" alt="example image" />
                                <div class="overlay-solid-blue position-absolute full-width full-height flex flex-column flex-justify-center flex-items-center padding-3" style="top: 0">
                                    <div class="margin-bottom-3" style="min-width: 150px">
                                        <img src="../../assets/images/globe.svg" alt="example image" />
                                    </div>
                                    <h2 class="heading-size-h3 is-bold text-white">Lorem Ipsum</h2>
                                    <p class="text-white text-align-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grid-item-lg margin-bottom">
                            <div class="image-two-by-three">
                                <img src="../../assets/images/CampusBuildings.jpg" alt="example image" />
                                <div class="overlay-solid-orange position-absolute full-width full-height flex flex-column flex-justify-center flex-items-center padding-3" style="top: 0">
                                    <div class="margin-bottom-3" style="min-width: 150px">
                                        <img src="../../assets/images/globe.svg" alt="example image" />
                                    </div>
                                    <h2 class="heading-size-h3 is-bold text-white">Lorem Ipsum</h2>
                                    <p class="text-white text-align-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grid-item-lg margin-bottom">
                            <div class="image-two-by-three">
                                <img src="../../assets/images/CampusBuildings.jpg" alt="example image" />
                                <div class="overlay-solid-blue position-absolute full-width full-height flex flex-column flex-justify-center flex-items-center padding-3" style="top: 0">
                                    <div class="margin-bottom-3" style="min-width: 150px">
                                        <img src="../../assets/images/globe.svg" alt="example image" />
                                    </div>
                                    <h2 class="heading-size-h3 is-bold text-white">Lorem Ipsum</h2>
                                    <p class="text-white text-align-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='flex-container'>
    <div class='flex-grid'>
        <div class='flex-grid-item-lg margin-bottom'>
            <div class='image-two-by-three'>
                <img src='../../assets/images/CampusBuildings.jpg' alt='example image' />
                <div class='overlay-solid-blue position-absolute full-width full-height flex flex-column flex-justify-center flex-items-center padding-3' style='top: 0'>
                    <div class='margin-bottom-3' style='min-width: 150px'>
                        <img src='../../assets/images/globe.svg' alt='example image' />
                    </div>
                    <h2 class='heading-size-h3 is-bold text-white'>Lorem Ipsum</h2>
                    <p class='text-white text-align-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
        <div class='flex-grid-item-lg margin-bottom'>
            <div class='image-two-by-three'>
                <img src='../../assets/images/CampusBuildings.jpg' alt='example image' />
                <div class='overlay-solid-orange position-absolute full-width full-height flex flex-column flex-justify-center flex-items-center padding-3' style='top: 0'>
                    <div class='margin-bottom-3' style='min-width: 150px'>
                        <img src='../../assets/images/globe.svg' alt='example image' />
                    </div>
                    <h2 class='heading-size-h3 is-bold text-white'>Lorem Ipsum</h2>
                    <p class='text-white text-align-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
        <div class='flex-grid-item-lg margin-bottom'>
            <div class='image-two-by-three'>
                <img src='../../assets/images/CampusBuildings.jpg' alt='example image' />
                <div class='overlay-solid-blue position-absolute full-width full-height flex flex-column flex-justify-center flex-items-center padding-3' style='top: 0'>
                    <div class='margin-bottom-3' style='min-width: 150px'>
                        <img src='../../assets/images/globe.svg' alt='example image' />
                    </div>
                    <h2 class='heading-size-h3 is-bold text-white'>Lorem Ipsum</h2>
                    <p class='text-white text-align-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import DesignSidebar from '../../components/Sidebars/design-sidebar.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    DesignSidebar,
    PrismEditor,
  },
};
</script>
