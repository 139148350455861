<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template-alt>
      <div slot="sidebar">
          <icon-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Icon Library</h1>
        </div>
        <div class="padding-left-6">
            <p>Results: {{ iconResults }} icons</p>
        </div>
        <div class="icon-library__grid">
            <div v-for="icon in iconList" :key="icon.iconName">
                <div class="card flex flex-column flex-items-center shadow-depth-1 padding-3 full-height">
                    <div class="padding-3">
                        <span class="font-size-6" :class="icon.iconClass"></span>
                    </div>
                    <div>
                        <h2 class="heading-size-h4 is-bold">{{ icon.iconName }}</h2>
                    </div>
                    <code class="code-highlight">.{{ icon.iconClass }}</code>
                </div>
            </div>
        </div>
      </div>
    </page-template-alt>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PageTemplateAlt from '../../components/Base/page-template-alt.vue';
import IconSidebar from '../../components/Sidebars/icon-sidebar.vue';


export default {
  name: 'home',
  components: {
    PageTemplateAlt,
    IconSidebar,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('icons', ['iconList']),
    iconResults() {
      return this.iconList.length;
    },
  },
};
</script>

<style lang="scss">
  .icon-library {
    &__grid {
      padding: 0 $spacer-6 $spacer-6;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ;
      column-gap: $spacer-4;
      row-gap: $spacer-4;
    }
  }
</style>
