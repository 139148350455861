<!-- eslint-disable max-len -->
<template>
    <div>
        <h3>Stacked Radio Buttons</h3>
        <div class="example__container">
            <div class="example__demo">
                <div class="example__demo-section">
                    <form>
                        <fieldset>
                            <div class="radio">
                                <input type="radio" id="graduate" name="program" value="graduate" checked="checked">
                                <label for="graduate">Graduate</label>
                            </div>
                            <div class="radio">
                                <input type="radio" id="undergraduate" name="program" value="undergraduate">
                                <label for="undergraduate">Undergraduate</label>
                            </div>
                            <div class="radio">
                                <input type="radio" id="other" name="program" value="other">
                                <label for="other">Other</label>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<form>
    <fieldset>
    <div class='radio'>
        <input type='radio' id='graduate' name='program' value='graduate' checked='checked'>
        <label for='graduate'>Graduate</label>
    </div>
    <div class='radio'>
        <input type='radio' id='undergraduate' name='program' value='undergraduate'>
        <label for='undergraduate'>Undergraduate</label>
    </div>
    <div class='radio'>
        <input type='radio' id='other' name='program' value='other'>
        <label for='other'>Other</label>
    </div>
    </fieldset>
</form>" language="markup"></prism-editor></pre>
            </div>
        </div>
        <h3>Inline Radio Buttons</h3>
        <div class="example__container">
            <div class="example__demo">
                <div class="example__demo-section">
                    <form>
                        <fieldset>
                        <div class="form-row">
                            <div class="radio">
                                <input type="radio" id="full-time" name="type" value="full-time" checked="checked">
                                <label for="full-time">Full Time</label>
                            </div>
                            <div class="radio">
                                <input type="radio" id="part-time" name="type" value="part-time">
                                <label for="part-time">Part Time</label>
                            </div>
                            <div class="radio">
                                <input type="radio" id="online" name="type" value="online">
                                <label for="online">Online</label>
                            </div>
                        </div>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<form>
    <fieldset>
        <div class='form-row'>
            <div class='radio'>
                <input type='radio' id='full-time' name='type' value='full-time' checked='checked'>
                <label for='full-time'>Full Time</label>
            </div>
            <div class='radio'>
                <input type='radio' id='part-time' name='type' value='part-time'>
                <label for='part-time'>Part Time</label>
            </div>
            <div class='radio'>
                <input type='radio' id='online' name='type' value='online'>
                <label for='online'>Online</label>
            </div>
        </div>
    </fieldset>
</form>" language="markup"></prism-editor></pre>
            </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'type-scale',
  components: {
    PrismEditor,
  },
};
</script>
