const state = {
  isVisible: false,
};

const mutations = {
  showModal() {
    state.isVisible = true;
  },
  hideModal() {
    state.isVisible = false;
  },
};
const getters = {};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
