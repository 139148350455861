const state = {
  primaryColors: [
    {
      colorName: 'Syracuse University Orange',
      hexName: '#F76900',
      rgbName: 'rgba(247,105,0)',
      variableName: '$su-orange-primary',
    },
    {
      colorName: 'White',
      hexName: '#FFFFFF',
      rgbName: 'rgba(255,255,255)',
      variableName: '$white',
    },
    {
      colorName: 'Primary Blue',
      hexName: '#000E54',
      rgbName: 'rgba(0,14,84)',
      variableName: '$su-blue-primary',
    },
  ],
  secondaryColors: [
    {
      colorName: 'Medium Orange',
      hexName: '#FF431B',
      rgbName: 'rgba(255,67,27)',
      variableName: '$su-orange-medium',
    },
    {
      colorName: 'Light Orange',
      hexName: '#FF8E00',
      rgbName: 'rgba(255,142,0)',
      variableName: '$su-orange-light',
    },
    {
      colorName: 'Medium Blue',
      hexName: '#203299',
      rgbName: 'rgba(32,50,153)',
      variableName: '$su-blue-medium',
    },
    {
      colorName: 'Light Blue',
      hexName: '#2B72D7',
      rgbName: 'rgba(43,114,215)',
      variableName: '$su-blue-light',
    },
    {
      colorName: 'Black',
      hexName: '#000000',
      rgbName: 'rgba(0,0,0)',
      variableName: '$black',
    },
    {
      colorName: 'Medium Gray',
      hexName: '#707780',
      rgbName: 'rgba(112,119,128)',
      variableName: '$su-gray-medium',
    },
    {
      colorName: 'Light Gray',
      hexName: '#ADB3B8',
      rgbName: 'rgba(173,179,184)',
      variableName: '$su-gray-light',
    },
  ],
  specialColors: [
    {
      colorName: 'Dark Gray',
      hexName: '#404040',
      rgbName: 'rgba(64,64,64)',
      variableName: '$su-gray-dark',
    },
    {
      colorName: 'Dark Orange',
      hexName: '#D74100',
      rgbName: 'rgba(215,65,0)',
      variableName: '$su-orange-dark',
    },
  ],
  swatches: [
    {
      tints: [
        {
          tintVariable: '$su-orange-primary-100',
          tintHex: '#F76900',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-90',
          tintHex: '#F8781A',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-80',
          tintHex: '#F98733',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-70',
          tintHex: '#F9964D',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-60',
          tintHex: '#FAA566',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-50',
          tintHex: '#FBB480',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-40',
          tintHex: '#FCC399',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-30',
          tintHex: '#FDD2B3',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-20',
          tintHex: '#FDE1CC',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-primary-10',
          tintHex: '#FEF0E6',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-blue-primary-100',
          tintHex: '#000E54',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-primary-90',
          tintHex: '#1A2665',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-primary-80',
          tintHex: '#333E76',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-primary-70',
          tintHex: '#4D5687',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-primary-60',
          tintHex: '#666E98',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-primary-50',
          tintHex: '#8087AA',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-primary-40',
          tintHex: '#999FBB',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-primary-30',
          tintHex: '#B3B7CC',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-primary-20',
          tintHex: '#CCCFDD',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-primary-10',
          tintHex: '#E6E7EE',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-orange-medium-100',
          tintHex: '#FF431B',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-90',
          tintHex: '#FF5632',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-80',
          tintHex: '#FF6949',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-70',
          tintHex: '#FF7B5F',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-60',
          tintHex: '#FF8E76',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-50',
          tintHex: '#FFA18D',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-40',
          tintHex: '#FFB4A4',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-30',
          tintHex: '#FFC7BB',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-20',
          tintHex: '#FFD9D1',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-medium-10',
          tintHex: '#FFECE8',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-orange-light-100',
          tintHex: '#FF8E00',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-90',
          tintHex: '#FF991A',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-80',
          tintHex: '#FFA533',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-70',
          tintHex: '#FFB04D',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-60',
          tintHex: '#FFBB66',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-50',
          tintHex: '#FFC780',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-40',
          tintHex: '#FFD299',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-30',
          tintHex: '#FFDDB3',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-20',
          tintHex: '#FFE8CC',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-light-10',
          tintHex: '#FFF4E6',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-blue-medium-100',
          tintHex: '#203299',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-medium-90',
          tintHex: '#3647A3',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-medium-80',
          tintHex: '#4D5BAD',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-medium-70',
          tintHex: '#6370B8',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-medium-60',
          tintHex: '#7984C2',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-medium-50',
          tintHex: '#9099CC',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-medium-40',
          tintHex: '#A6ADD6',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-medium-30',
          tintHex: '#BCC2E0',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-medium-20',
          tintHex: '#D2D6EB',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-medium-10',
          tintHex: '#E9EBF5',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-blue-light-100',
          tintHex: '#2B72D7',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-blue-light-90',
          tintHex: '#4080DB',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-light-80',
          tintHex: '#558EDF',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-light-70',
          tintHex: '#6B9CE3',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-light-60',
          tintHex: '#80AAE7',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-light-50',
          tintHex: '#95B9EB',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-light-40',
          tintHex: '#AAC7EF',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-light-30',
          tintHex: '#BFD5F3',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-light-20',
          tintHex: '#D5E3F7',
          color: '#000000',
        },
        {
          tintVariable: '$su-blue-light-10',
          tintHex: '#EAF1FB',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-gray-medium-100',
          tintHex: '#707780',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-90',
          tintHex: '#7E858D',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-80',
          tintHex: '#8D9299',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-70',
          tintHex: '#9BA0A6',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-60',
          tintHex: '#A9ADB3',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-50',
          tintHex: '#B8BBC0',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-40',
          tintHex: '#C6C9CC',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-30',
          tintHex: '#D4D6D9',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-20',
          tintHex: '#E2E4E6',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-medium-10',
          tintHex: '#F1F1F2',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-gray-light-100',
          tintHex: '#ADB3B8',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-90',
          tintHex: '#B5BBBF',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-80',
          tintHex: '#BDC2C6',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-70',
          tintHex: '#C6CACD',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-60',
          tintHex: '#CED1D4',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-50',
          tintHex: '#D6D9DC',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-40',
          tintHex: '#DFE1E3',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-30',
          tintHex: '#E6E8EA',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-20',
          tintHex: '#EFF0F1',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-light-10',
          tintHex: '#F7F7F8',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-gray-dark-100',
          tintHex: '#404040',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-gray-dark-90',
          tintHex: '#535353',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-gray-dark-80',
          tintHex: '#666666',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-gray-dark-70',
          tintHex: '#797979',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-dark-60',
          tintHex: '#8C8C8C',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-dark-50',
          tintHex: '#A0A0A0',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-dark-40',
          tintHex: '#B3B3B3',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-dark-30',
          tintHex: '#C6C6C6',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-dark-20',
          tintHex: '#D9D9D9',
          color: '#000000',
        },
        {
          tintVariable: '$su-gray-dark-10',
          tintHex: '#ECECEC',
          color: '#000000',
        },
      ],
    },
    {
      tints: [
        {
          tintVariable: '$su-orange-dark-100',
          tintHex: '#D74100',
          color: '#FFFFFF',
        },
        {
          tintVariable: '$su-orange-dark-90',
          tintHex: '#DB541A',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-dark-80',
          tintHex: '#DF6733',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-dark-70',
          tintHex: '#E37A4D',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-dark-60',
          tintHex: '#E78d66',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-dark-50',
          tintHex: '#EBA080',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-dark-40',
          tintHex: '#EFB399',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-dark-30',
          tintHex: '#F3C6B3',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-dark-20',
          tintHex: '#F7D9CC',
          color: '#000000',
        },
        {
          tintVariable: '$su-orange-dark-10',
          tintHex: '#FBECE6',
          color: '#000000',
        },
      ],
    },
  ],
  textUtilities: [
    {
      textColor: '.text-black',
      className: 'text-black',
    },
    {
      textColor: '.text-white',
      className: 'text-white background-blue-primary',
    },
    {
      textColor: '.text-gray-light',
      className: 'text-gray-light',
    },
    {
      textColor: '.text-gray',
      className: 'text-gray',
    },
    {
      textColor: '.text-gray-dark',
      className: 'text-gray-dark',
    },
    {
      textColor: '.text-blue-light',
      className: 'text-blue-light',
    },
    {
      textColor: '.text-blue',
      className: 'text-blue',
    },
    {
      textColor: '.text-orange',
      className: 'text-orange background-blue-primary',
    },
    {
      textColor: '.text-orange-dark',
      className: 'text-orange-dark',
    },
  ],
  backgroundUtilities: [
    {
      backgroundColor: '.background-black',
      className: 'background-black text-white',
    },
    {
      backgroundColor: '.background-white',
      className: 'background-white',
    },
    {
      backgroundColor: '.background-gray-light',
      className: 'background-gray-light',
    },
    {
      backgroundColor: '.background-gray',
      className: 'background-gray text-white',
    },
    {
      backgroundColor: '.background-gray-dark',
      className: 'background-gray-dark text-white',
    },
    {
      backgroundColor: '.background-blue-light',
      className: 'background-blue-light text-white',
    },
    {
      backgroundColor: '.background-blue',
      className: 'background-blue text-white',
    },
    {
      backgroundColor: '.background-orange-light',
      className: 'background-orange-light',
    },
    {
      backgroundColor: '.background-orange',
      className: 'background-orange',
    },
    {
      backgroundColor: '.background-orange-dark',
      className: 'background-orange-dark text-white',
    },
  ],
};

const getters = {};

const actions = {
};

const mutations = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
