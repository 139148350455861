<template>
    <header class="page-header">
        <nav class="page-header__navbar">
            <div class="page-header__title">
                <div class="page-header__brand">
                    <a href="#maincontent" id="skip-nav">Skip to Content</a>
                    <router-link :to="{name: 'home'}" class="page-header__brand-link">
                        <img src="../../assets/Logos/su-header-logo.svg"
                        alt="Syracuse University Design System"
                        class="page-header__logo-desktop" />
                        <block-s class="page-header__logo-mobile"/>
                    </router-link>
                </div>
            </div>
            <nav class="page-header__nav" aria-label="site navigation"
                v-if="!isSidebarVisible">
                <ul class="page-header__nav-menu">
                    <li class="page-header__nav-menu-item">
                        <router-link :to="{name: 'breakpoints'}">Layout</router-link>
                    </li>
                    <li class="page-header__nav-menu-item">
                        <router-link :to="{name: 'colors'}">Design</router-link>
                    </li>
                    <li class="page-header__nav-menu-item">
                        <router-link :to="{name: 'buttons'}">Components</router-link>
                    </li>
                    <li class="page-header__nav-menu-item">
                        <router-link :to="{name: 'icon-usage'}">Icons</router-link>
                    </li>
                    <li class="page-header__nav-menu-item">
                        <router-link :to="{name: 'accessibility'}">Accessibility</router-link>
                    </li>
                    <!--<li class="page-header__nav-menu-item">
                        <router-link :to="{name: 'contact-form'}">
                            Prototypes
                        </router-link>
                    </li>-->
                </ul>
            </nav>
            <div class="page-header__nav-mobile">
                <button @click="toggleMobileSidebar" v-if="!isSidebarVisible"
                        aria-expanded="false"
                    >
                    <span class="hidden-button-text">Toggle Sidebar</span>
                    <span class="icon-hamburger"></span>
                </button>
                <button @click="toggleMobileSidebar" v-if="isSidebarVisible"
                        aria-expanded="true">
                    <span class="hidden-button-text">Toggle Sidebar</span>
                    <span class="icon-times"></span>
                </button>
            </div>
        </nav>
    </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import BlockS from '../Icons/block-s.vue';

export default {
  name: 'page-header',
  components: {
    BlockS,
  },
  computed: {
    ...mapState('sidebar', { isSidebarVisible: 'isVisible' }),
  },
  methods: {
    ...mapMutations('sidebar', ['toggleMobileSidebar']),
  },
};
</script>

<style lang="scss">
    .page-header {
        position: fixed;
        display: flex !important;
        top:0;
        height: 80px;
        width: 100%;
        z-index: 10;
        padding: $spacer-4 0;
        border-bottom: 1px solid $su-gray-light-40;
        background-color: $white;
        @include breakpoint(lg) {
            height: 100px;
        }
        &__title {
            font-size: $text-size-8;
            font-weight: $font-weight-bold;
            color: $text-blue;
            letter-spacing: .25rem;
            display: flex;
            align-items: center;
            position: relative;
            @include breakpoint(lg) {
                font-size: $text-size-6;
            }
        }
        &__logo {
            &-desktop {
                display: none;
                @include breakpoint(lg) {
                    display: block;
                }
            }
            &-mobile {
                display: block;
                height: 60px;
                & > svg {
                    height: 100%;
                }
                @include breakpoint(lg) {
                    display: none;
                }
            }
        }
        &__version {
            font-size: $text-size-11;
            letter-spacing: 0;
            font-weight: normal;
            margin-left: $spacer-2;
        }
        &__brand {
            display: flex;
            align-items: center;
            min-width: 110px;
            @include breakpoint(lg) {
                min-width: 300px;
            }
            &-link {
                text-decoration: none;
                color: inherit;
                display: inherit;
            }
        }
        &__navbar {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            padding: 0 $spacer-3;
            width: 100%;
            @include breakpoint(lg) {
                padding: 0 $spacer-5;
            }
        }
        &__nav {
            display: none;
            @include breakpoint(xl) {
                display: flex;
            }
            &-menu {
                @include flex-row;
                list-style-type: none;
                &-item {
                    padding: 0 $spacer-3;
                    font-size: $text-size-9;
                    & > a {
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    & > .router-link-exact-active.router-link-active {
                        text-decoration: underline;
                    }
                }
            }
            &-mobile {
                display: flex;
                font-size: 1.5rem;
                position: relative;
                z-index: 10;
                @include breakpoint(xl) {
                    display: none;
                }
                & > button {
                    background: transparent;
                    border: $border-0;
                }
            }
        }
    }
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(10px);
    }
</style>
