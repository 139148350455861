<!-- eslint-disable max-len -->
<template>
    <prototype-template>
        <div slot="content">
            <div class="padding-6">
                <div class="container-lg">
                    <div class="grid-md grid-columns-auto grid-columns-gap-4 grid-rows-gap-4 padding-4">
                        <div class="card">
                            <div class="image-one-by-one">
                                <img src="https://minio.dev.digitalservices.syr.edu/dds-images/ALC_Buckets_Photos_0020.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=veBTf6PxMn76arzSSKBqJZ4M%2F20210303%2F%2Fs3%2Faws4_request&X-Amz-Date=20210303T194523Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=5eb8ecc272aaf7fc45ed233182135a8f441b2cf334c238cb36dc931f11f05cef" alt="Students Hanover Square"/>
                            </div>
                        </div>
                        <div class="card padding-3">
                            <h1 class="heading-display-h4">Lorem Ipsum Dolor Emet</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </prototype-template>
</template>

<script>
import PrototypeTemplate from '../../../components/Base/prototype-template.vue';

export default {
  name: 'flexbox-prototype',
  components: {
    PrototypeTemplate,
  },
};
</script>
