<!-- eslint-disable max-len -->
<template>
    <div class="margin-top-6">
        <h2 id="responsive-grid" class="anchor-link" tabindex="-1">Responsive Grid Utilites</h2>
        <p>Like the flexbox grid utilities, the css grid utilities are also responsive. To make any css grid utility responsive, simply adding a design system breakpoint.
            For example, <code class="code-highlight">.grid-md</code> will add display: grid to a parent container on viewports md and up.</p>
        <p>In the following example, layout is separated into columns and rows on viewports 1012px and larger. On viewports smaller than 1012px, each box collapses down to evenly sized rows.
            This is achieved by adding <code class="code-highlight">.grid-columns-2-lg</code> onto the parent element and on the first child column.
            The size of the rows are also controlled by <code class="code-highlight">.grid-rows-4-lg</code> on the second column and <code class="code-highlight">.grid-rows-span-3-lg </code> on the first child of the second column.</p>
        <div class="margin-y-4">
            <div class="grid grid-columns-2-lg grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-3">
                <div class="grid grid-columns-2-lg grid-columns-gap-3 grid-rows-gap-3">
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">1</span>
                    </div>
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">2</span>
                    </div>
                </div>
                <div class="grid grid-rows-gap-3 grid-rows-4-lg">
                    <div class="grid-rows-span-3-lg background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">3</span>
                    </div>
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">4</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-columns-2-lg grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-3'>
    <div class='grid grid-columns-2-lg grid-columns-gap-3 grid-rows-gap-3'>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>1</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>2</span>
        </div>
    </div>
    <div class='grid grid-rows-gap-3 grid-rows-4-lg'>
        <div class='grid-rows-span-3-lg background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>3</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>4</span>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
