<template>
    <div class="accordion-item border border-gray-light">
        <div class="accordion-header-text">
          <h3 class="margin-bottom-0">
            <button class="accordion-header
                    full-width
                    background-gray-light-30
                    text-blue
                    border-0
                    margin-0
                    padding-top-2
                    padding-bottom-2
                    padding-left-3
                    padding-right-3"
                    @click="toggleOpen"
                    :aria-expanded="isOpen.toString()">
              {{ title }}
              <div class="accordion-toggle icon-right" :class="getOpen">
                <i class="icon-chevron-right"></i>
              </div>
            </button>
          </h3>
        </div>
        <div class="accordion-content" :class="getOpen">
          <p>{{ content }}</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'accordion-item',
  props: {
    title: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    getOpen() {
      return {
        'is-open': this.isOpen,
      };
    },
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
