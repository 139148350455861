<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
          <icon-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Icon Usage</h1>
        </div>
        <div class="padding-x-3 padding-x-5-md">
          <p>A library of icons are included within the Syracuse University Digital Design System.
            These icons were created to be consistent with Syracuse University branding, are intended to be used as enhancements to your user interface, and to improve the user experience of your site or application.</p>
          <p>Take care when using icons that they are tested for accessibility. Icons should be used to improve communication and give directional cues to the user.
            Instances where only the icon is shown visually, proper labeling for screen readers should be applied.</p>
        </div>
        <div class="padding-x-3 padding-x-5-md">
          <h2>How To Use</h2>
          <p>If you are using the minified css version of the DDS –– such as <strong>latest</strong> or <strong>v.1.5.2</strong> you will need to import the icon font into either a style tag or into a separate css file.</p>
          <p>If you are installing the DDS via npm install or are using the entire DDS project in your own project, you will not need to import the icon font as it is already available to you once you have updated to the newest release.</p>
          <p>Below is the css markup used to import the icon font.</p>
          <div class="example__code margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="@font-face {
  font-family: 'orange-icons';
  src:  url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.eot');
  src:  url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.eot#iefix') format('embedded-opentype'),
        url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.ttf') format('truetype'),
        url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.woff') format('woff'),
        url('https://fastly.cdn.syracuse.edu/fonts/orange-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}" language="scss"></prism-editor></pre>
          </div>
          <p>Once the the font is imported, you are then able to add the icon to your html.
            The icon is added by simply using the appropriate class name for the font to an <code>&#60;i&#62;</code> or <code>&#60;span&#62;</code> tag.</p>
          <p>The size of the icon will inherit its size from either the base body size of 16px or from a parent element. The size of the font can be modified using
            text size utilities.
          </p>
          <p>All the icons currently available and their class names can be found in the <router-link :to="{name: 'icon-library'}">Icon Library</router-link>.</p>
          <div class="border margin-y-4">
            <div class="padding-4">
              <p>
                <span class="icon-comment-active text-orange-dark"></span>
                <span class="margin-left-3">Leave A Comment</span>
              </p>
            </div>
            <div class="example__code">
              <pre contenteditable="true"><prism-editor code="<div class='padding-4'>
  <p>
    <span class='icon-comment-active text-orange-dark'></span>
    <span class='margin-left-3'>Leave A Comment</span>
  </p>
</div>" language="markup"></prism-editor></pre>
            </div>
          </div>
          <h2>Icon Requests</h2>
          <p>The current library of icons is now rather limited, but this list will be continually updated. If there is a need for an icon that does not currently exist, please place a request for the icon as an <a href="https://gitlab.digitalservices.syr.edu/web-development/design-system-site/-/issues">issue in Gitlab</a>.</p>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import IconSidebar from '../../components/Sidebars/icon-sidebar.vue';


export default {
  name: 'home',
  components: {
    PageTemplate,
    IconSidebar,
    PrismEditor,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
</style>
