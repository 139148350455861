-<template>
    <div class="design">
    <page-template>
      <div slot="sidebar">
        <prototypes-sidebar />
      </div>
      <div slot="content">
          <div class="prototype-list">
            <router-link :to="{ name: prototype.prototypeLink }"
              v-for="prototype in prototypes" :key="prototype.prototypeName"
              class="prototype-link">
              <div class="card shadow-depth-1 prototype-card">
                <div class="">
                  <img :src="require('../../assets/images/' + prototype.prototypeImage)"
                      :alt="prototype.prototypeName" />
                </div>
                <div class="card-body">
                  <span>{{ prototype.prototypeName }}</span>
                </div>
              </div>
            </router-link>
          </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PageTemplate from '../../components/Base/page-template.vue';
import PrototypesSidebar from '../../components/Sidebars/prototypes-sidebar.vue';

export default {
  name: 'prototypes',
  components: {
    PageTemplate,
    PrototypesSidebar,
  },
  computed: {
    ...mapState('prototypes', ['categories', 'prototypes']),
  },
};
</script>

<style lang="scss">
    .prototype {
      &-list {
        display: grid;
        grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );
        gap: $spacer-3;
        margin: $spacer-3;
      }
      &-link {
        &:hover,
        &:focus {
          background: none;
        }
      }
      &-card {
      }
    }
</style>
