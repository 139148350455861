<!-- eslint-disable max-len -->
<template>
    <div>
        <h3>Vertical Alignment</h3>
        <p>Change the alignment of elements with the vertical-alignment utilities. Please note that vertical-align only affects inline, inline-block, inline-table, and table cell elements.</p>
        <div class="example__container margin-bottom-5">
            <div class="padding-3">
                <div class="background-gray-light-10">
                    <span class="align-baseline font-size-9 margin-right-3">.align-baseline</span>
                    <span class="align-top font-size-9 margin-right-3">.align-top</span>
                    <span class="align-bottom font-size-9 margin-right-3">.align-bottom</span>
                    <span class="align-text-top font-size-9 margin-right-3">.align-text-top</span>
                    <span class="align-text-bottom font-size-9">.align-text-bottom</span>
                </div>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<span class='align-baseline font-size-9 margin-right-3'>.align-baseline</span>
<span class='align-top font-size-9 margin-right-3'>.align-top</span>
<span class='align-bottom font-size-9 margin-right-3'>.align-bottom</span>
<span class='align-text-top font-size-9 margin-right-3'>.align-text-top</span>
<span class='align-text-bottom font-size-9'>.align-text-bottom</span>" language="markup"></prism-editor></pre>
            </div>
        </div>
        <h3>Text Alignment</h3>
        <p>Easily realign text to components with text alignment classes.</p>
        <div class="example__container margin-bottom-5">
            <div class="padding-3">
                <p class="text-align-left">Left aligned text on all viewport sizes.</p>
                <p class="text-align-center">Center aligned text on all viewport sizes.</p>
                <p class="text-align-right">Right aligned text on all viewport sizes.</p>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<p class='text-align-left'>Left aligned text on all viewport sizes.</p>
<p class='text-align-center'>Center aligned text on all viewport sizes.</p>
<p class='text-align-right'>Right aligned text on all viewport sizes.</p>" language="markup"></prism-editor></pre>
            </div>
        </div>
        <h3>Responsive Text Alignment</h3>
        <p>For left, right, and center alignment, responsive classes are available that use the same viewport width breakpoints as the grid system.</p>
        <table class="border">
            <thead>
                <tr>
                    <th width="25%">Class Name</th>
                    <th width="50%">Behavior</th>
                    <th width="25%">Viewport Sizes</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>.text-align-sm-left</td>
                    <td>Left aligned on only small viewports.</td>
                    <td>543px and under</td>
                </tr>
                <tr>
                    <td>.text-align-sm-right</td>
                    <td>Right aligned on only small viewports.</td>
                    <td>543px and under</td>
                </tr>
                <tr>
                    <td>.text-align-sm-center</td>
                    <td>Center aligned on only small viewports.
                        Left aligned for all other viewport sizes.</td>
                    <td>543px and under</td>
                </tr>
                <tr>
                    <td>.text-align-md-left</td>
                    <td>Left aligned on medium viewports and up.</td>
                    <td>768px and up</td>
                </tr>
                <tr>
                    <td>.text-align-md-right</td>
                    <td>Right aligned on medium viewports and up.</td>
                    <td>768px and up</td>
                </tr>
                <tr>
                    <td>.text-align-md-center</td>
                    <td>Center aligned on  medium viewports and up.
                        Left aligned for all other viewport sizes.</td>
                    <td>768px and up</td>
                </tr>
                <tr>
                    <td>.text-align-lg-left</td>
                    <td>Left aligned on large viewports and up.</td>
                    <td>1012px and up</td>
                </tr>
                <tr>
                    <td>.text-align-lg-right</td>
                    <td>Right aligned on large viewports and up.</td>
                    <td>1012px and up</td>
                </tr>
                <tr>
                    <td>.text-align-lg-center</td>
                    <td>Center aligned on large viewports and up.
                        Left aligned for all other viewport sizes.</td>
                    <td>1012px and up</td>
                </tr>
            </tbody>
        </table>
        <div class="example__container">
            <div class="padding-3">
                <p class="text-align-sm-left">Left aligned on only small viewports.</p>
                <p class="text-align-sm-right">Right aligned on only small viewports.</p>
                <p class="text-align-sm-center">Center aligned on only small viewports.</p>
                <p class="text-align-md-left">Left aligned on medium viewports and up.</p>
                <p class="text-align-md-right">Right aligned on medium viewports and up.</p>
                <p class="text-align-md-center">Center aligned on medium viewports and up.</p>
                <p class="text-align-lg-left">Left aligned on large viewports and up.</p>
                <p class="text-align-lg-right">Right aligned on large viewports and up.</p>
                <p class="text-align-lg-center">Center aligned on large viewports and up.</p>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<p class='text-align-sm-left'>Left aligned on only small viewports.</p>
<p class='text-align-sm-right'>Right aligned on only small viewports.</p>
<p class='text-align-sm-center'>Center aligned on only small viewports.</p>
<p class='text-align-md-left'>Left aligned on medium viewports and up.</p>
<p class='text-align-md-right'>Right aligned on medium viewports and up.</p>
<p class='text-align-md-center'>Center aligned on medium viewports and up.</p>
<p class='text-align-lg-left'>Left aligned on large viewports and up.</p>
<p class='text-align-lg-right'>Right aligned on large viewports and up.</p>
<p class='text-align-lg-center'>Center aligned on large viewports and up.</p>" language="markup"></prism-editor></pre>
            </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'alignment',
  components: {
    PrismEditor,
  },
};
</script>
