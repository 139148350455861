<!-- eslint-disable max-len -->
<template>
    <prototype-template>
        <div slot="content">
            <div class="container-md">
                <div class="content-container">
                    <h1>Facilisis magna etiam tempor orci eu. Metus vulputate eu scelerisque felis imperdiet proin
                        fermentum.</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                        Velit laoreet id donec ultrices tincidunt. Lectus magna fringilla urna porttitor.
                        Ipsum faucibus vitae aliquet nec ullamcorper. Integer enim neque volutpat ac tincidunt vitae semper.
                        Facilisis magna etiam tempor orci eu. Metus vulputate eu scelerisque felis imperdiet proin
                        fermentum. Facilisis gravida neque convallis a cras semper. Libero enim sed faucibus turpis in.
                        Proin fermentum leo vel orci.</p>
                        <h2>Facilisis magna etiam tempor orci eu. Metus vulputate eu scelerisque felis imperdiet proin
                        fermentum.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                        Velit laoreet id donec ultrices tincidunt. Lectus magna fringilla urna porttitor.
                        Ipsum faucibus vitae aliquet nec ullamcorper. Integer enim neque volutpat ac tincidunt vitae semper.
                        Facilisis magna etiam tempor orci eu. Metus vulputate eu scelerisque felis imperdiet proin
                        fermentum. Facilisis gravida neque convallis a cras semper. Libero enim sed faucibus turpis in.
                        Proin fermentum leo vel orci.</p>
                    <ul>
                        <li>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            <ul>
                                <li>Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                                    Velit laoreet id donec ultrices tincidunt.</li>
                                <li>Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                                Velit laoreet id donec ultrices tincidunt.</li>
                            </ul>
                        </li>
                        <li>Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                        Velit laoreet id donec ultrices tincidunt.</li>
                        <li>Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                        Velit laoreet id donec ultrices tincidunt.</li>
                        <li>Facilisis gravida neque convallis a cras semper. Libero enim sed faucibus turpis in.
                        Proin fermentum leo vel orci.</li>
                        <li>Lectus magna fringilla urna porttitor.</li>
                        <li>Metus vulputate eu scelerisque felis imperdiet proin
                        fermentum.
                        </li>
                    </ul>
                    <h3>Lorem Ipsum Dolor Sit Amet Consectetur</h3>
                    <ul>
                        <li>Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                            Velit laoreet id donec ultrices tincidunt.</li>
                        <li>Aliquet bibendum enim facilisis gravida neque convallis a cras semper.
                        Velit laoreet id donec ultrices tincidunt.</li>
                    </ul>
                </div>
            </div>
        </div>
    </prototype-template>
</template>

<script>
import PrototypeTemplate from '../../../components/Base/prototype-template.vue';

export default {
  name: 'article-with-list',
  components: {
    PrototypeTemplate,
  },
};
</script>

<style lang="scss" scoped>
    .content-container {
        padding: 0 $spacer-3;
    }
    .checkbox {
        position: relative;
        & label {
            position: relative;
            display: inline-block;
            padding-left: $spacer-4;
            & > input[type="checkbox"] {
                opacity: 0;
                width: 0;
                height: 0;
                & + label::after {
                    content: none;
                }
                &:checked + label::after {
                    content: "";
                }
                &:checked ~ label::before {
                    background-color: $su-orange-dark-100;
                    border: 1px solid $su-orange-dark-100;
                }
                &:focus + label::before {
                    outline: $su-orange-dark-100 solid 2px;
                    border: 1px solid $white;
                }
            }
            &::before,
        &::after {
            position: absolute;
            content: "";
            // Needed for the line-height to take effect
            display: inline-block;
        }
        &::before {
            height: 1rem;
            width: 1rem;
            border: 1px solid $su-gray-medium-40;
            background-color: $su-gray-medium-40;
            left: 0;
            top: 3px;
        }
        &::after {
            height: 5px;
            width: 9px;
            border-left: 2px solid $white;
            border-bottom: 2px solid $white;
            transform: rotate(-45deg);
            left: 4px;
            top: 7px;
        }
        }
    }
</style>
