<template>
    <div class="accordion" role="region">
        <accordion-item
            v-for="item in accordions" :key="item.title"
            :title="item.title"
            :content="item.content" />
    </div>
</template>

<script>
import AccordionItem from './accordion-item.vue';

export default {
  name: 'accordion',
  components: {
    AccordionItem,
  },
  data() {
    return {
      accordions: [
        {
          title: 'Lorem ipsum dolor sit amet',
          content: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
        {
          title: 'Excepteur sint occaecat cupidatat non proident',
          content: 'Consequat interdum varius sit amet. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum lectus. At varius vel pharetra vel turpis nunc eget lorem.',
        },
      ],
    };
  },
};
</script>
