<!-- eslint-disable max-len -->
<template>
    <div>
        <h2 id="card-image-top" class="anchor-link" tabindex="-1">Card With Image On Top</h2>
        <p>An image can be added to the top of a card component by simply adding an image component within the card. The image classes <code class="code-highlight">.image-sixteen-by-nine</code>, <code class="code-highlight">.image-four-by-three</code>, and <code class="code-highlight">.image-three-by-two</code> are the suggested sizes to use.</p>
        <p>The example below is also combined with the flexbox grid to create a fluid and responsive layout for cards.</p>
        <div class="border">
            <div class="background-gray-light-10 padding-y-3">
            <div class="flex-container">
                <div class="flex-grid">
                <div class="flex-grid-item-6-md margin-bottom-3">
                    <div class="card">
                    <div class="image-sixteen-by-nine">
                        <img src="../../../assets/images/image-size-example.jpg" alt="example image" />
                    </div>
                    <div class="card-header padding-bottom-0">
                        <p class="heading-size-h3">Header Title</p>
                    </div>
                    <div class="card-body">
                        <p class="margin-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    </div>
                </div>
                <div class="flex-grid-item-6-md margin-bottom-3">
                    <div class="card">
                    <div class="image-sixteen-by-nine">
                        <img src="../../../assets/images/image-size-example.jpg" alt="example image" />
                    </div>
                    <div class="card-header padding-bottom-0">
                        <p class="heading-size-h3">Header Title</p>
                    </div>
                    <div class="card-body">
                        <p class="margin-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div>
            <pre contenteditable="true"><prism-editor code="<div class='flex-container'>
    <div class='flex-grid'>
      <div class='flex-grid-item-6-md margin-bottom-3'>
        <div class='card'>
          <div class='image-sixteen-by-nine'>
            <img src='../../../assets/images/image-size-example.jpg' alt='example image' />
          </div>
          <div class='card-header padding-bottom-0'>
            <p class='heading-size-h3'>Header Title</p>
          </div>
          <div class='card-body'>
            <p class='margin-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
        </div>
      </div>
      <div class='flex-grid-item-6-md margin-bottom-3'>
        <div class='card'>
          <div class='image-sixteen-by-nine'>
            <img src='../../../assets/images/image-size-example.jpg' alt='example image' />
          </div>
          <div class='card-header padding-bottom-0'>
            <p class='heading-size-h3'>Header Title</p>
          </div>
          <div class='card-body'>
            <p class='margin-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
        </div>
      </div>
    </div>
  </div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'card-image-top',
  components: {
    PrismEditor,
  },
};
</script>
