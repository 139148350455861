<!-- eslint-disable max-len -->
<template>
    <div class="design-system__page">
        <page-template>
            <div slot="sidebar">
                <layout-sidebar />
            </div>
            <div slot="content">
                <div class="design-system__page-header">
                    <h1>CSS Grid</h1>
                </div>
                <div class="design-system__page-content">
                    <div class="design-system__page-section">
                        <h2>CSS Grid</h2>
                        <p>CSS Grid is powerful layout tool - as it is a two dimensional system. Both rows and columns can be specifically defined.
                            For an in-depth overview of css grid and all its properties, a good resource is the <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout">MDN documentation</a>.
                            It may be helpful to understand css grid prior to using these utilites.</p>
                        <p>Although it is to the developer's discretion on whether flexbox or css grid is more appropriate for specific use cases, the following suggestions are for when css grid is the better option.</p>
                        <ul>
                            <li><strong>You need a layout-first design:</strong> A good use case for page template layout. CSS grid makes is easy to create grid sections for a header, sidebar, page content, and footer.</li>
                            <li><strong>You have a complex design to implement:</strong> The two-dimensional layout system here is perfect to create a complex designs, such as tiled grids and masonry style layouts that display a gallery of images or content cards.</li>
                            <li><strong>You need to have a gap between block elements:</strong> A very helpful aspect of css grid is the ability to define a gap between rows or columns very easily, without having to use the margin property, which can cause some side effects especially if working with many breakpoints.</li>
                            <li><strong>You need to overlap elements:</strong> Overlap elements using CSS grid is very easy, you just need to use the grid-column and grid-row properties and you can have overlapping elements very easily. On the other hand, with Flexbox we still need to use some hacks such as margins, transforms, or absolute positioning.</li>
                        </ul>
                        <p>To first get started with CSS grid, you will first need to add the class <code class="code-highlight">.grid</code> to the parent container. All children within this container will adhere to how the grid is defined.</p>
                        <grid-columns />
                        <column-spanning />
                        <column-start-end />
                        <auto-columns />
                        <grid-rows />
                        <row-spanning />
                        <row-start-end />
                        <auto-rows />
                        <auto-flow />
                        <grid-gap />
                        <grid-nesting />
                        <responsive-grid />
                    </div>
                </div>
            </div>
        </page-template>
    </div>
</template>

<script>
import PageTemplate from '../../../components/Base/page-template.vue';
import LayoutSidebar from '../../../components/Sidebars/layout-sidebar.vue';
import AutoColumns from './AutoColumns.vue';
import AutoFlow from './AutoFlow.vue';
import AutoRows from './AutoRows.vue';
import ColumnSpanning from './ColumnSpanning.vue';
import ColumnStartEnd from './ColumnStartEnd.vue';
import GridColumns from './GridColumns.vue';
import GridGap from './GridGap.vue';
import GridNesting from './GridNesting.vue';
import GridRows from './GridRows.vue';
import ResponsiveGrid from './ResponsiveGrid.vue';
import RowSpanning from './RowSpanning.vue';
import RowStartEnd from './RowStartEnd.vue';


export default {
  name: 'flexbox',
  components: {
    PageTemplate,
    LayoutSidebar,
    GridColumns,
    ColumnSpanning,
    ColumnStartEnd,
    AutoColumns,
    GridRows,
    RowSpanning,
    RowStartEnd,
    AutoRows,
    AutoFlow,
    GridGap,
    GridNesting,
    ResponsiveGrid,
  },
};
</script>
