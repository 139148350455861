<!-- eslint-disable max-len -->
<template>
    <div>
        <p>Textarea elements and their corresponding label can also be wrapped by a <code class="code-highlight">.form-group</code> element.
            Additionally, this can be nested within a <code class="code-highlight">.form-row</code> to be combined with inline or stacked input fields.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <form>
                      <div class="form-group">
                        <label for="comments" class="form-label">Comments</label>
                        <textarea id="comments" class="form-textarea"></textarea>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<form>
  <div class='form-group'>
    <label for='comments' class='form-label'>Comments</label>
    <textarea id='comments' class='form-textarea'></textarea>
  </div>
</form>" language="markup"></prism-editor></pre>
                </div>
            </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'type-scale',
  components: {
    PrismEditor,
  },
};
</script>
