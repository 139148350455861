<template>
    <div class="search-form">
        <div class="search-form__field">
            <label class="search-form__input-label" for="search-programs">Search Programs</label>
            <span class="autocomplete__icon">
                <span class="icon-search" />
            </span>
            <input id="search-programs" class="search-form__input"/>
            <button class="search-form__clear">
                <span class="icon-times" />
                <span class="search-form__clear-label">Clear Search</span>
            </button>
        </div>
        <div class="search-form__results"></div>
    </div>
</template>

<script>
export default {
  name: 'search-form',
  props: {
    programs: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
    .search-form {
        position: relative;
        &__field {
            display: flex;
            align-items: center;
            position: relative;
            padding: 1rem;
            border: $border;
        }
        &__input {
            width: 100%;
            text-indent: .5rem;
            border: none;
            &:focus {
                outline: none;
            }
            &-label {
                @include accessible-hide;
            }
        }
        &__clear {
            background: transparent;
            border: $border-0;
            &-label {
                display: block;
                overflow: hidden;
                height: 0;
                width: 0;
            }
        }
    }
</style>
