<!-- eslint-disable max-len -->
<template>
    <prototype-template>
        <div slot="content">
            <div class="container-lg padding-x-6">
                <div class="padding-y-3">
                    <h1>Ut enim ad minim veniam quis nostrud exercitation</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Sed felis eget velit aliquet sagittis. Adipiscing enim eu turpis egestas.
                        Fermentum odio eu feugiat pretium nibh ipsum. Ornare massa eget egestas purus viverra accumsan. Aliquet nec ullamcorper sit amet risus.
                        Nulla at volutpat diam ut venenatis tellus in. In egestas erat imperdiet sed. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh.
                        Accumsan lacus vel facilisis volutpat. Nulla facilisi etiam dignissim diam quis.
                        Semper auctor neque vitae tempus. In iaculis nunc sed augue. Cursus in hac habitasse platea.
                        Blandit libero volutpat sed cras ornare arcu dui vivamus arcu.
                        Donec massa sapien faucibus et molestie ac.</p>
                </div>
            </div>
            <div class="card card-horizontal">
                <div class="card-horizontal-section">
                    <div class="image-three-by-two">
                        <img src="../../../assets/images/online-female-student-laptop-couch.webp" />
                    </div>
                </div>
                <div class="card-horizontal-section padding-6 flex flex-column flex-justify-center flex-items-start">
                    <h3>Lorem Ipsum Dolor Sit Amet</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    <button class="button button-primary-outline">
                        Learn More
                        <span class="icon-right">
                            <i class="icon-right-arrow"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div class="card card-horizontal flex-row-reverse-lg">
                <div class="card-horizontal-section">
                    <div class="image-three-by-two">
                        <img src="../../../assets/images/online-vibrant-community.webp" />
                    </div>
                </div>
                <div class="card-horizontal-section padding-6 flex flex-column flex-justify-center flex-items-start">
                    <h3>Lorem Ipsum Dolor Sit Amet</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    <button class="button button-primary-outline">
                        Learn More
                        <span class="icon-right">
                            <i class="icon-right-arrow"></i>
                        </span>
                    </button>
                </div>
                <div class="flex">
                    <div>Test</div>
                    <div>Test</div>
                </div>
            </div>
        </div>
    </prototype-template>
</template>

<script>
import PrototypeTemplate from '../../../components/Base/prototype-template.vue';

export default {
  name: 'flexbox-prototype',
  components: {
    PrototypeTemplate,
  },
};
</script>
