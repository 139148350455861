<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <page-template>
      <div slot="sidebar">
        <design-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Colors</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 id="guidelines" class="anchor-link" tabindex="-1">Guidelines</h2>
            <p>The following pillars are the foundation for how color should be used and applied on Syracuse University websites.</p>
            <h3>Communication Over Decoration</h3>
            <p>Use of color should adhere to Syracuse University brand standards as well as being purposeful, rational and supporting of the content.</p>
            <h3>Color Should Be Accessible</h3>
            <p><router-link :to="{name: 'accessibility'}">Run tests for correct color contrast</router-link> when choosing color and never rely solely on color to convey information.</p>
            <h3>Color Should Focus Attention</h3>
            <p>The use of color should help bring attention to what matters most.
              Color should support the hierarchy of the page. Each use of primary Syracuse University Orange, for example, needs to be strategic and impactful.</p>
          </div>
          <div class="design-system__page-section">
            <h2 id="color-palette" class="anchor-link" tabindex="-1">Color Palette</h2>
            <p>Syracuse University's official brand colors make up the palette’s foundation.</p>
            <h3>Primary Colors</h3>
            <div class="design-system__swatch-container">
              <div class="col-md-4 col-xs-12" v-for="color in primaryColors" :key="color.colorName">
                <div class="design-system__swatch">
                  <div class="design-system__swatch-color" :style="{ backgroundColor: color.hexName }"></div>
                  <div class="design-system__swatch-info">
                    <code>
                      {{ color.colorName }}<br>
                      {{ color.hexName }}<br>
                      {{ color.rgbName }}<br>
                      {{ color.variableName }}
                    </code>
                  </div>
                </div>
              </div>
            </div>
            <h3>Secondary Colors</h3>
            <div class="design-system__swatch-container">
              <div class="col-md-4 col-xs-12" v-for="color in secondaryColors" :key="color.colorName">
                <div class="design-system__swatch">
                  <div class="design-system__swatch-color" :style="{ backgroundColor: color.hexName }"></div>
                  <div class="design-system__swatch-info">
                    <code>
                      {{ color.colorName }}<br>
                      {{ color.hexName }}<br>
                      {{ color.rgbName }}<br>
                      {{ color.variableName }}
                    </code>
                  </div>
                </div>
              </div>
            </div>
            <h3>Special Use Colors</h3>
            <div class="design-system__swatch-container">
              <div class="col-md-4 col-xs-12" v-for="color in specialColors" :key="color.colorName">
                <div class="design-system__swatch">
                  <div class="design-system__swatch-color" :style="{ backgroundColor: color.hexName }"></div>
                  <div class="design-system__swatch-info">
                    <code>
                      {{ color.colorName }}<br>
                      {{ color.hexName }}<br>
                      {{ color.rgbName }}<br>
                      {{ color.variableName }}
                    </code>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="color-utilities" class="anchor-link" tabindex="-1">Color Utilities</h2>
            <p>Find utilities to change text and background colors below. They are for the purpose of changing the color of an element or font from their default state.</p>
            <p>All colors provided in the design system must adhere to brand and accessibility standards.</p>
            <h3>Text Color Utilities</h3>
            <div class="flex-wrap margin-bottom-4">
              <div v-for="text in textUtilities"
                :key="text.textColor"
                :class="text.className"
                class="margin-2 padding">{{ text.textColor }}</div>
            </div>
            <h3>Background Color Utilities</h3>
            <p>These utilities can be used to apply a background color to elements.
              The naming convention is <code class="code-highlight">.background</code> combined with the above naming convention, for example: <code class="code-highlight">.background-gray-light-60</code>.
              Find background color utility shortcuts below.</p>
              <div class="flex-wrap">
              <div v-for="background in backgroundUtilities"
                :key="background.backgroundColor"
                :class="background.className"
                class="margin-2 padding">
                {{background.backgroundColor}}
              </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="color-tints" class="anchor-link" tabindex="-1">Color Tints</h2>
            <p>Tints are used in the design system for small elements such as borders, background colors and box shadows.</p>
            <p><strong>Never  use tints for major design elements and ensure their usage   adheres to accessibility standards.</strong></p>
            <div class="design-system__swatch-container">
              <div class="col-md-3 col-xs-12" v-for="swatch in swatches" :key="swatch.colorName">
                <div class="design-system__tint-container margin-1">
                  <div class="design-system__tint" v-for="tint in swatch.tints" :key="tint"
                    :style="{backgroundColor: tint.tintHex, color: tint.color}">
                    <code>{{ tint.tintVariable }}</code>
                    <code>{{ tint.tintHex }}</code>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PageTemplate from '../../components/Base/page-template.vue';
import DesignSidebar from '../../components/Sidebars/design-sidebar.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    DesignSidebar,
  },
  computed: {
    ...mapState('colors', ['swatches', 'textUtilities', 'backgroundUtilities', 'primaryColors', 'secondaryColors', 'specialColors']),
  },
};
</script>

<style lang="scss">
  .design {
    height: 100%;
    &__content {
      padding: $spacer-4;
    }
  }
</style>
