const state = {
  isVisible: false,
  menuItems: [
    {
      linkName: 'Home',
      linkLocation: 'home',
      id: 'home',
      sublinks: [],
    },
    {
      linkName: 'Layout',
      linkLocation: 'breakpoints',
      id: 'layout',
      sublinks: [
        {
          linkName: 'Breakpoints',
          linkLocation: 'breakpoints',
        },
        {
          linkName: 'Columns',
          linkLocation: 'columns',
        },
        {
          linkName: 'Containers',
          linkLocation: 'containers',
        },
        {
          linkName: 'Layout',
          linkLocation: 'layout',
        },
        {
          linkName: 'Flexbox Grid',
          linkLocation: 'flexbox',
        },
        {
          linkName: 'CSS Grid',
          linkLocation: 'grid',
        },
      ],
    },
    {
      linkName: 'Design',
      linkLocation: 'colors',
      id: 'design',
      sublinks: [
        {
          linkName: 'Colors',
          linkLocation: 'colors',
        },
        {
          linkName: 'Typography',
          linkLocation: 'typography',
        },
        {
          linkName: 'Media',
          linkLocation: 'media',
        },
        {
          linkName: 'Spacing',
          linkLocation: 'spacing',
        },
        {
          linkName: 'Borders',
          linkLocation: 'borders',
        },
        {
          linkName: 'Shadow',
          linkLocation: 'box-shadow',
        },
      ],
    },
    {
      linkName: 'Components',
      linkLocation: 'buttons',
      id: 'components',
      sublinks: [
        {
          linkName: 'Buttons',
          linkLocation: 'buttons',
        },
        {
          linkName: 'Forms',
          linkLocation: 'forms',
        },
        {
          linkName: 'Tables',
          linkLocation: 'tables',
        },
        {
          linkName: 'Cards',
          linkLocation: 'cards',
        },
        {
          linkName: 'Accordion',
          linkLocation: 'accordion',
        },
      ],
    },
    {
      linkName: 'Icons',
      linkLocation: 'icon-usage',
      id: 'icons',
      sublinks: [
        {
          linkName: 'Icon Usage',
          linkLocation: 'icon-usage',
        },
        {
          linkName: 'Icon Library',
          linkLocation: 'icon-library',
        },
      ],
    },
  ],
  designSidebar: [
    {
      linkName: 'Colors',
      routeName: 'colors',
      subNav: [
        {
          linkName: 'Guidelines',
          routeName: '#guidelines',
        },
        {
          linkName: 'Color Palette',
          routeName: '#color-palette',
        },
        {
          linkName: 'Color Utilities',
          routeName: '#color-utilities',
        },
        {
          linkName: 'Color Tints',
          routeName: '#color-tints',
        },
      ],
    },
    {
      linkName: 'Typography',
      routeName: 'typography',
      subNav: [
        {
          linkName: 'Usage',
          routeName: '#usage',
        },
        {
          linkName: 'Fonts',
          routeName: '#fonts',
        },
        {
          linkName: 'Type Scale',
          routeName: '#type-scale',
        },
        {
          linkName: 'Headings',
          routeName: '#headings',
        },
        {
          linkName: 'Paragraph Styles',
          routeName: '#paragraph-styles',
        },
        {
          linkName: 'Links',
          routeName: '#links',
        },
        {
          linkName: 'Lists',
          routeName: '#lists',
        },
      ],
    },
    {
      linkName: 'Media',
      routeName: 'media',
      subNav: [
        {
          linkName: 'Responsive Images',
          routeName: '#responsive-images',
        },
        {
          linkName: 'Fixed Width Images',
          routeName: '#fixed-width-images',
        },
        {
          linkName: 'Responsive Video',
          routeName: '#responsive-video',
        },
      ],
    },
    {
      linkName: 'Spacing',
      routeName: 'spacing',
      subNav: [
        {
          linkName: 'Spacing Scale',
          routeName: '#spacing-scale',
        },
        {
          linkName: 'Naming Convention',
          routeName: '#naming-convention',
        },
        {
          linkName: 'Margin',
          routeName: '#margin',
        },
        {
          linkName: 'Responsive Margin',
          routeName: '#responsive-margins',
        },
        {
          linkName: 'Padding',
          routeName: '#padding',
        },
        {
          linkName: 'Responsive Padding',
          routeName: '#responsive-padding',
        },
      ],
    },
    {
      linkName: 'Borders',
      routeName: 'borders',
      subNav: [
        {
          linkName: 'Default Border',
          routeName: '#default-border',
        },
        {
          linkName: 'Directional Border',
          routeName: '#directional-border',
        },
        {
          linkName: 'Border Weight',
          routeName: '#border-weight',
        },
        {
          linkName: 'Border Color',
          routeName: '#border-color',
        },
        {
          linkName: 'Border Radius',
          routeName: '#border-radius',
        },
      ],
    },
    {
      linkName: 'Shadow',
      routeName: 'box-shadow',
      subNav: [
        {
          linkName: 'Box Shadow',
          routeName: '#box-shadow',
        },
        {
          linkName: 'Text Shadow',
          routeName: '#text-shadow',
        },
      ],
    },
    {
      linkName: 'Overlay',
      routeName: 'overlay',
      subNav: [
      ],
    },
  ],
  layoutSidebar: [
    {
      linkName: 'Breakpoints',
      routeName: 'breakpoints',
    },
    {
      linkName: 'Columns',
      routeName: 'columns',
      subNav: [
        {
          linkName: 'Float Based Columns',
          routeName: '#float-columns',
        },
        {
          linkName: 'Nesting Columns',
          routeName: '#nesting-columns',
        },
        {
          linkName: 'Offset Columns',
          routeName: '#offset-columns',
        },
        {
          linkName: 'Responsive Columns',
          routeName: '#responsive-columns',
        },
        {
          linkName: 'Gutters',
          routeName: '#gutters',
        },
      ],
    },
    {
      linkName: 'Containers',
      routeName: 'containers',
      subNav: [],
    },
    {
      linkName: 'Layout',
      routeName: 'layout',
      subNav: [
        {
          linkName: 'Display',
          routeName: '#display',
        },
        {
          linkName: 'Position',
          routeName: '#position',
        },
        {
          linkName: 'Alignment',
          routeName: '#alignment',
        },
        {
          linkName: 'Width',
          routeName: '#width',
        },
      ],
    },
    {
      linkName: 'Flexbox Grid',
      routeName: 'flexbox',
      subNav: [
        {
          linkName: 'The Container',
          routeName: '#the-container',
        },
        {
          linkName: 'Container Sizes',
          routeName: '#container-sizes',
        },
        {
          linkName: 'Container Center',
          routeName: '#container-center',
        },
        {
          linkName: 'Automatic Columns',
          routeName: '#automatic-columns',
        },
        {
          linkName: 'Responsive Columns',
          routeName: '#responsive-columns',
        },
        {
          linkName: 'Column Widths',
          routeName: '#flex-column-widths',
        },
        {
          linkName: 'Mixed Column Widths',
          routeName: '#mixed-column-widths',
        },
        {
          linkName: 'Push And Pull',
          routeName: '#push-pull',
        },
        {
          linkName: 'Right Align Last',
          routeName: '#right-align',
        },
        {
          linkName: 'Nesting Grid',
          routeName: '#nesting-grid',
        },
      ],
    },
    {
      linkName: 'Flexbox Utilities',
      routeName: 'flexbox-utilities',
      subNav: [
        {
          linkName: 'Flex Container',
          routeName: '#flex-container',
        },
        {
          linkName: 'Justify Content',
          routeName: '#justify-content',
        },
        {
          linkName: 'Align Items',
          routeName: '#align-items',
        },
        {
          linkName: 'Align Content',
          routeName: '#align-content',
        },
        {
          linkName: 'Flex Grow & Shrink',
          routeName: '#flex-grow-shrink',
        },
        {
          linkName: 'Align Self',
          routeName: '#align-self',
        },
        {
          linkName: 'Responsive Flex Utilities',
          routeName: '#responsive-flex-utilities',
        },
      ],
    },
    {
      linkName: 'CSS Grid',
      routeName: 'grid',
      subNav: [
        {
          linkName: 'Grid Columns',
          routeName: '#grid-columns',
        },
        {
          linkName: 'Column Spanning',
          routeName: '#column-span',
        },
        {
          linkName: 'Column Start/End',
          routeName: '#column-start-end',
        },
        {
          linkName: 'Auto Columns',
          routeName: '#auto-columns',
        },
        {
          linkName: 'Grid Rows',
          routeName: '#grid-rows',
        },
        {
          linkName: 'Row Spanning',
          routeName: '#row-span',
        },
        {
          linkName: 'Row Start/End',
          routeName: '#row-start-end',
        },
        {
          linkName: 'Auto Rows',
          routeName: '#auto-rows',
        },
        {
          linkName: 'Grid Auto Flow',
          routeName: '#auto-flow',
        },
        {
          linkName: 'Grid Gap',
          routeName: '#grid-gap',
        },
        {
          linkName: 'Grid Nesting',
          routeName: '#grid-nesting',
        },
        {
          linkName: 'Responsive Grid',
          routeName: '#responsive-grid',
        },
      ],
    },
  ],
  componentsSidebar: [
    {
      linkName: 'Buttons',
      routeName: 'buttons',
      subNav: [],
    },
    {
      linkName: 'Forms',
      routeName: 'forms',
      subNav: [
        {
          linkName: 'Inputs',
          routeName: '#inputs',
        },
        {
          linkName: 'Text Area',
          routeName: '#text-area',
        },
        {
          linkName: 'Select',
          routeName: '#select',
        },
        {
          linkName: 'Checkbox',
          routeName: '#checkbox',
        },
        {
          linkName: 'Radio',
          routeName: '#radio',
        },
      ],
    },
    {
      linkName: 'Tables',
      routeName: 'tables',
      subNav: [
        {
          linkName: 'Basic Table',
          routeName: '#basic-table',
        },
        {
          linkName: 'Striped Table',
          routeName: '#striped-table',
        },
        {
          linkName: 'Multi-level Table',
          routeName: '#multi-level-table',
        },
      ],
    },
    {
      linkName: 'Cards',
      routeName: 'cards',
      subNav: [
        {
          linkName: 'Basic Card',
          routeName: '#basic-card',
        },
        {
          linkName: 'Card Image Top',
          routeName: '#card-image-top',
        },
        {
          linkName: 'Horizontal Card Stack',
          routeName: '#horizontal-card-stack',
        },
      ],
    },
    {
      linkName: 'Accordion',
      routeName: 'accordion',
      subNav: [],
    },
    {
      linkName: 'Modal',
      routeName: 'modal',
      subNav: [],
    },
    {
      linkName: 'Headers',
      routeName: 'headers',
      subNav: [],
    },
    // {
    // linkName: 'Loading Graphics',
    // routeName: 'loading-graphics',
    // subNav: [
    // ],
    // },
  ],
};

const mutations = {
  toggleMobileSidebar() {
    state.isVisible = !state.isVisible;
  },
  setSidebarVisibility(isVisible) {
    state.isVisible = isVisible;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
