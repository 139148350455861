<!-- eslint-disable max-len -->
<template>
    <div>
        <div class="border row gutter-md-spacious padding-y-3">
            <div class="col-3 float-left">
                <div class="border padding">.col-md-3</div>
            </div>
            <div class="col-3 float-left">
                <div class="border padding">.col-md-3</div>
            </div>
            <div class="col-3 float-left">
                <div class="border padding">.col-md-3</div>
            </div>
            <div class="col-3 float-left">
                <div class="border padding">.col-md-3</div>
            </div>
        </div>
        <div class="example__code border margin-top">
            <pre contenteditable="true"><prism-editor code="<div class='border row gutter-md-spacious padding-y-3'>
    <div class='col-3 float-left'>
        <div class='border padding'>.col-md-3</div>
    </div>
    <div class='col-3 float-left'>
        <div class='border padding'>.col-md-3</div>
    </div>
    <div class='col-3 float-left'>
        <div class='border padding'>.col-md-3</div>
    </div>
    <div class='col-3 float-left'>
        <div class='border padding'>.col-md-3</div>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  components: {
    PrismEditor,
  },
};
</script>
