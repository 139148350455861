<!-- eslint-disable max-len -->
<template>
    <div>
        <div class="design-system__typography-section">
            <h3>Blockquote</h3>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.<span>Jonathon J. Doe</span></blockquote>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.<span>Jonathon J. Doe</span></blockquote>" language="markup"></prism-editor></pre>
                </div>
            </div>
        </div>
        <div class="design-system__typography-section">
            <h3>Intro Paragraph Primary</h3>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <p class="intro-text-primary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.</p>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<p class='intro-text-primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.</p>" language="markup"></prism-editor></pre>
                </div>
            </div>
        </div>
        <div class="design-system__typography-section">
            <h3>Default Paragraph</h3>
            <div class="example__container">
                <div class="example__demo">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.</p>
                  <p>Nibh ipsum consequat nisl vel pretium. Augue interdum velit euismod in pellentesque massa placerat duis. Augue ut lectus arcu bibendum at. Lacinia quis vel eros donec ac odio tempor orci. Eu mi bibendum neque egestas congue quisque. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi. </p>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.</p>
<p>Nibh ipsum consequat nisl vel pretium. Augue interdum velit euismod in pellentesque massa placerat duis. Augue ut lectus arcu bibendum at. Lacinia quis vel eros donec ac odio tempor orci. Eu mi bibendum neque egestas congue quisque. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi. </p>" language="markup"></prism-editor></pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  components: {
    PrismEditor,
  },
};
</script>
