<!-- eslint-disable max-len -->
<template>
    <div class="margin-top-6">
        <h2 id="column-span" class="anchor-link" tabindex="-1">Spanning Grid Columns</h2>
        <p>In addition to having equally sized columns, you are able to define some blocks as spanning multiple columns. To do so, you first define your grid columns on the parent container.
            Next, you add the class <code class="code-highlight">.grid-columns-span-{x}</code>, with x being the number of columns you wish to span - to the child element. The values that are available to use are 1 through 12.</p>
        <p>In the following example, block 2 and block 6 have the class <code class="code-highlight">.grid-columns-span-2</code>, which will have each span 2 columns out of the 4 columns in the grid.</p>
        <div class="margin-y-4">
            <div class="grid grid-columns-4 grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4">
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">1</span>
                </div>
                <div class="grid-columns-span-2 background-blue-medium padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">2</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">3</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">4</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">5</span>
                </div>
                <div class="grid-columns-span-2 background-blue-medium padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">6</span>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-columns-4 grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4'>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>1</span>
    </div>
    <div class='grid-columns-span-2 background-blue-medium padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>2</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>3</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>4</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>5</span>
    </div>
    <div class='grid-columns-span-2 background-blue-medium padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>6</span>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
