<!-- eslint-disable max-len -->
<template>
  <div>
    <table role="table" class="table-striped">
      <thead role="rowgroup">
        <tr role="row">
          <th role="columnheader">Name</th>
          <th role="columnheader">Title</th>
          <th role="columnheader">Department</th>
          <th role="columnheader">Email Address</th>
        </tr>
      </thead>
      <tbody>
        <tr role="row" v-for="employee in employees" :key="employee.name">
          <td role="cell">
            <div class="mobile-th">Name</div>
            <div class="mobile-td">{{ employee.name }}</div>
          </td>
          <td role="cell">
            <div class="mobile-th">Title</div>
            <div class="mobile-td">{{ employee.title }}</div>
          </td>
          <td role="cell">
            <div class="mobile-th">Department</div>
            <div class="mobile-td">{{ employee.department }}</div>
          </td>
          <td role="cell">
            <div class="mobile-th">Email Address</div>
            <div class="mobile-td"><a href="#"> {{ employee.emailAddress }}</a></div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="example__code margin-top-5 border-1">
      <pre contenteditable="true"><prism-editor code="<table role='table' class='table-striped'>
  <thead role='rowgroup'>
    <tr role='row'>
      <th role='columnheader'>Name</th>
      <th role='columnheader'>Title</th>
      <th role='columnheader'>Department</th>
      <th role='columnheader'>Email Address</th>
    </tr>
  </thead>
  <tbody>
    <tr role='row'>
      <td role='cell'>
        <div class='mobile-th'>Name</div>
        <div class='mobile-td'>Adele Dubois</div>
      </td>
      <td role='cell'>
        <div class='mobile-th'>Title</div>
        <div class='mobile-td'>Communications Manager</div>
      </td>
      <td role='cell'>
        <div class='mobile-th'>Department</div>
        <div class='mobile-td'>Enrollment and the Student Experience</div>
      </td>
      <td role='cell'>
        <div class='mobile-th'>Email Address</div>
        <div class='mobile-td'><a href='#'>amdubois@syr.edu'</a></div>
      </td>
    </tr>
  </tbody>
</table>" language="markup"></prism-editor></pre>
    </div>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'striped-table',
  components: {
    PrismEditor,
  },
  data() {
    return {
      employees: [
        {
          name: 'Adele Dubois',
          title: 'Communications Manager',
          department: 'Enrollment and the Student Experience',
          emailAddress: 'amdubois@syr.edu',
        },
        {
          name: 'Nader Malouf',
          title: 'Admissions Counselor, Summer Programs',
          department: 'Financial Aid',
          emailAddress: 'nbmalouf@syr.edu',
        },
        {
          name: 'Anshul Chaudhary',
          title: 'Associate Director of Claims',
          department: 'Campus Safety and Emergency Services',
          emailAddress: 'alchaudhary@syr.edu',
        },
        {
          name: 'Jack Kinkaid',
          title: 'Associate Professor, Reading and Language Arts',
          department: 'School of Education',
          emailAddress: 'jkinkaid@syr.edu',
        },
      ],
    };
  },
};
</script>
