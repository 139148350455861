<template>
    <div class="basic-template">
        <mobile-sidebar />
        <page-header />
        <div class="basic-template__container" :class="sidebarVisibility" >
            <main class="basic-template__content">
                <div class="basic-template__content-container anchor-link" id="maincontent">
                    <slot name="content" />
                </div>
            </main>
        </div>
        <page-footer />
    </div>
</template>

<script>
import PageHeader from './page-header.vue';
import PageFooter from './page-footer.vue';
import MobileSidebar from '../Sidebars/mobile-sidebar.vue';

export default {
  name: 'basic-template',
  components: {
    PageHeader,
    PageFooter,
    MobileSidebar,
  },
};
</script>

<style lang="scss" scoped>
    .basic-template {
        position: relative;
        &__container {
            display:flex;
            position: relative;
            margin-top: 80px;
            &.is-active {
                height: 0;
            }
            @include breakpoint(xl){
                height: 100%;
                min-height: 800px;
                display: flex;
                margin-top: 100px;
            }
        }
        &__content {
            width: 100%;
            display: flex;
            background-color: $white;
            justify-content: center;
            & > .anchor {
                position: absolute;
                left: 0px;
                top: -90px;
            }
            &-container {
                overflow: auto;
                width: 100%;
            }
        }
    }
</style>
