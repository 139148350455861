<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <components-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Tables</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 id="basic-table" class="anchor-link" tabindex="-1">Basic Table</h2>
            <basic-table />
          </div>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 id="striped-table" class="anchor-link" tabindex="-1">Striped Table</h2>
            <striped-table />
          </div>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 id="multi-level-table" class="anchor-link" tabindex="-1">Multi-Level Table</h2>
            <Multi-Level-table />
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PageTemplate from '../../../components/Base/page-template.vue';
import ComponentsSidebar from '../../../components/Sidebars/components-sidebar.vue';
import BasicTable from './BasicTable.vue';
import StripedTable from './StripedTable.vue';
import MultiLevelTable from './MultiLevelTable.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    ComponentsSidebar,
    BasicTable,
    StripedTable,
    MultiLevelTable,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">

</style>
