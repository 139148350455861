/* eslint-disable vue/no-parsing-error */
<!-- eslint-disable max-len -->
<template>
    <div>
        <h3>Breakpoints</h3>
        <p>Abbreviated breakpoints  keep the class names concise, while a responsive utility class stays enabled unless it gets overridden with another responsive utility class featuring a higher breakpoint.</p>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <table class="border">
                    <thead>
                        <tr>
                            <th>Shorthand</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>xs</td>
                            <td>min-width: 0</td>
                        </tr>
                        <tr>
                            <td>sm</td>
                            <td>min-width: 544px</td>
                        </tr>
                        <tr>
                            <td>md</td>
                            <td>min-width: 768px</td>
                        </tr>
                        <tr>
                            <td>lg</td>
                            <td>min-width: 1012px</td>
                        </tr>
                        <tr>
                            <td>xl</td>
                            <td>min-width: 1280px</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="margin-top-6">
            <p>In the below example, the columns will be 4 columns across on viewports 1012px and up, 2 across for viewports between 544px and 1011px and will stack on viewports smaller than 767px.</p>
        </div>
        <div class="border">
            <div class="padding">
                <div class="row padding">
                    <div class="col-lg-3 col-md-6 col-xs-12 float-left border padding">Columns stack when mobile</div>
                    <div class="col-lg-3 col-md-6 col-xs-12 float-left border padding">Columns stack when mobile</div>
                    <div class="col-lg-3 col-md-6 col-xs-12 float-left border padding">Columns stack when mobile</div>
                    <div class="col-lg-3 col-md-6 col-xs-12 float-left border padding">Columns stack when mobile</div>
                </div>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='row padding'>
    <div class='col-lg-3 col-md-6 col-xs-12 float-left border padding'>Columns stack when mobile</div>
    <div class='col-lg-3 col-md-6 col-xs-12 float-left border padding'>Columns stack when mobile</div>
    <div class='col-lg-3 col-md-6 col-xs-12 float-left border padding'>Columns stack when mobile</div>
    <div class='col-lg-3 col-md-6 col-xs-12 float-left border padding'>Columns stack when mobile</div>
</div>" language="markup"></prism-editor></pre>
            </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  components: {
    PrismEditor,
  },
};
</script>
