<template>
    <div class="prototype-template">
        <page-header />
        <main>
            <slot name="content" />
        </main>
    </div>
</template>

<script>
import PageHeader from './page-header.vue';


export default {
  name: 'home',
  components: {
    PageHeader,
  },
};
</script>
