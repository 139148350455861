<!-- eslint-disable max-len -->
<template>
    <div class="design-system__page">
        <page-template>
            <div slot="sidebar">
                <layout-sidebar />
            </div>
            <div slot="content">
                <div class="design-system__page-header">
                    <h1 class="margin-bottom">Flexbox Utilities</h1>
                    <p>A set of flexbox utilities to add responsiveness and flexibility to your layouts.
                        The design system flexbox utilities provide a set of CSS classes you can use to lay out items in a container. These utilities take advantage of CSS flexbox and are designed to be used when fine-tuning the layout of items at the individual component level.</p>
                    <p>These flexbox utilities are not indended for page layout. It is recommended that you use either the <router-link :to="{name: 'flexbox'}">Flexbox Grid</router-link> or <router-link :to="{name: 'columns'}">Float Based Column</router-link> systems.</p>
                </div>
                <div class="design-system__page-content">
                    <div class="design-system__page-section">
                        <h2 id="flex-container" class="anchor-link" tabindex="-1">Flex Container</h2>
                        <p>The flex container is the parent element to which you would apply the <code class="code-highlight">display: flex;</code> property.</p>
                        <h3>Flex Container Utilites</h3>
                        <p>The following flex utilities are available to apply to the parent control the order and arrangement child elements.</p>
                        <ul>
                            <li><code>.flex</code> - This class is required on the parent element for all other flex utilities to work.</li>
                            <li><code>.flex-inline</code></li>
                            <li><code>.flex-row</code></li>
                            <li><code>.flex-row-reverse</code></li>
                            <li><code>.flex-column</code></li>
                            <li><code>.flex-column-reverse</code></li>
                            <li><code>.flex-wrap</code></li>
                            <li><code>.flex-no-wrap</code></li>
                            <li><code>.flex-wrap-reverse</code></li>
                        </ul>
                        <h3 >Flex Content Alignment Utilities</h3>
                        <p>These flex utilities are also applied to the parent element and control the alignment of child elements.</p>
                        <p>For a good primer on flexbox alignment behavior, refer to the documentation on <a href="https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Flexbox">MDN</a>.</p>
                        <h4 id="justify-content" tabindex="-1" class="is-bold anchor-link">Justify Content</h4>
                        <p>The following utilities use the <code class="code-highlight">justify-content</code> property.</p>
                        <ul>
                            <li>
                                <code>.flex-justify-start</code>
                            </li>
                            <li>
                                <code>.flex-justify-end</code>
                            </li>
                            <li>
                                <code>.flex-justify-center</code>
                            </li>
                            <li>
                                <code>.flex-justify-space-between</code>
                            </li>
                            <li>
                                <code>.flex-justify-space-around</code>
                            </li>
                            <li>
                                <code>.flex-justify-space-evenly</code>
                            </li>
                        </ul>
                        <h4 id="align-items" tabindex="-1" class="is-bold anchor-link">Align Items</h4>
                        <p>The following utilities use the <code class="code-highlight">align-items</code> property.</p>
                        <ul>
                            <li>
                                <code>.flex-items-start</code>
                            </li>
                            <li>
                                <code>.flex-items-end</code>
                            </li>
                            <li>
                                <code>.flex-items-center</code>
                            </li>
                            <li>
                                <code>.flex-items-stretch</code>
                            </li>
                            <li>
                                <code>.flex-items-baseline</code>
                            </li>
                        </ul>
                        <h4 id="align-content" tabindex="-1" class="is-bold anchor-link">Align Content</h4>
                        <p>The following utilities use the <code class="code-highlight">align-content</code> property.</p>
                        <ul>
                            <li>
                                <code>.flex-content-start</code>
                            </li>
                            <li>
                                <code>.flex-content-end</code>
                            </li>
                            <li>
                                <code>.flex-content-center</code>
                            </li>
                            <li>
                                <code>.flex-content-stretch</code>
                            </li>
                            <li>
                                <code>.flex-content-baseline</code>
                            </li>
                        </ul>
                    </div>
                    <div class="design-system__page-section">
                        <h2>Flex Item Utilities</h2>
                        <p>A flex item is generally a direct child of any flex container element. The following utilities can be added to a flex item to control alignment or size.</p>
                        <h4 id="flex-grow-shrink" tabindex="-1" class="is-bold anchor-link">Flex Grow and Shrink</h4>
                        <ul>
                            <li>
                                <code>.flex-grow-1</code>
                            </li>
                            <li>
                                <code>.flex-grow-0</code>
                            </li>
                            <li>
                                <code>.flex-shrink-1</code>
                            </li>
                            <li>
                                <code>.flex-shrink-0</code>
                            </li>
                        </ul>
                        <h4 id="align-self" tabindex="-1" class="is-bold anchor-link">Align Self</h4>
                        <p>The following utilities use the <code class="code-highlight">align-self</code> property.</p>
                        <ul>
                            <li>
                                <code>.flex-self-start</code>
                            </li>
                            <li>
                                <code>.flex-self-end</code>
                            </li>
                            <li>
                                <code>.flex-self-center</code>
                            </li>
                            <li>
                                <code>.flex-self-stretch</code>
                            </li>
                            <li>
                                <code>.flex-self-baseline</code>
                            </li>
                        </ul>
                    </div>
                    <div class="design-system__page-section">
                        <h2 id="responsive-flex-utilities" tabindex="-1" class="is-bold anchor-link">Responsive Flex Utilities</h2>
                        <p>The Digital Design System provides responsive version of all the above class names.
                            Each class name can be made responsive by simply adding a design system breakpoint value to the end of the class name.
                            For example, <code class="code-highlight">.flex-md</code> will add <code class="code-highlight">display: flex</code> to a parent container on viewports md and up.</p>

                        <p>In the below example, <code class="code-highlight">.flex-md</code> is applied to the parent container and <code class="code-highlight">.flex-grow-1-md</code> to the child items.
                        This will apply <code class="code-highlight">display: flex</code> to the parent and evenly distribute the children horizontally on viewports md and up.
                        On smaller viewports this layout will collapse to a stacked column.</p>
                        <div class="border padding-3">
                            <div class="flex-md background-blue-primary-20 padding-2">
                                <div class="text-white background-blue-primary padding-2 margin-2 flex-grow-1-md">
                                    Flex Item
                                </div>
                                <div class="text-white background-blue-primary padding-2 margin-2 flex-grow-1-md">
                                    Flex Item
                                </div>
                                <div class="text-white background-blue-primary padding-2 margin-2 flex-grow-1-md">
                                    Flex Item
                                </div>
                            </div>
                        </div>
                        <pre contenteditable="true"><prism-editor code="<div class='flex-md background-blue-primary-20 padding-2'>
    <div class='text-white background-blue-primary padding-2 margin-2 flex-grow-1-md'>
        Flex Item
    </div>
    <div class='text-white background-blue-primary padding-2 margin-2 flex-grow-1-md'>
        Flex Item
    </div>
    <div class='text-white background-blue-primary padding-2 margin-2 flex-grow-1-md'>
        Flex Item
    </div>
</div>" language="markup"></prism-editor></pre>
                    </div>
                </div>
            </div>
        </page-template>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../../components/Base/page-template.vue';
import LayoutSidebar from '../../../components/Sidebars/layout-sidebar.vue';

export default {
  name: 'flexbox-utilities',
  components: {
    PageTemplate,
    LayoutSidebar,
    PrismEditor,
  },
};
</script>
