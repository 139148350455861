<template>
    <div class="sidebar">
        <h3 class="sidebar__section-title">Layout</h3>
         <ul class="sidebar__menu">
            <li v-for="item in layoutSidebar" :key="item.linkName">
                <router-link :to="{name: item.routeName}" class="sidebar__menu-link">
                    {{item.linkName}}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ScrollerMixin from './scrollmixin';

export default {
  mixins: [ScrollerMixin],
  name: 'design-sidebar',
  computed: {
    ...mapState('sidebar', ['layoutSidebar']),
  },
};
</script>
