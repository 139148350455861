<template>
    <div class="page-template-alt">
        <mobile-sidebar />
        <page-header />
        <div class="page-template-alt__container" :class="sidebarVisibility" >
            <nav class="page-template-alt__sidebar" aria-label="sidebar navigation">
                <slot name="sidebar" class="sidebar-slot" />
            </nav>
            <main class="page-template-alt__content">
                <div class="page-template-alt__content-container anchor-link" id="maincontent">
                    <slot name="content" />
                </div>
            </main>
        </div>
        <page-footer />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import PageHeader from './page-header.vue';
import PageFooter from './page-footer.vue';
import MobileSidebar from '../Sidebars/mobile-sidebar.vue';


export default {
  name: 'home',
  components: {
    PageHeader,
    PageFooter,
    MobileSidebar,
  },
  computed: {
    ...mapState('sidebar', { isSidebarVisible: 'isVisible' }),
    sidebarVisibility() {
      return {
        'is-active': this.isSidebarVisible,
      };
    },
  },
};
</script>

<style lang="scss">
    .page-template-alt {
        position: relative;
        &__container {
            display:flex;
            position: relative;
            margin-top: 80px;
            &.is-active {
                height: 0;
            }
            @include breakpoint(xl){
                height: 100%;
                min-height: 800px;
                display: flex;
                margin-top: 100px;
            }
        }
        &__sidebar {
            width: 0;
            height: 0;
            overflow: hidden;
            @include breakpoint(xl) {
                width: auto;
                height: auto;
                display: flex;
                flex-shrink: 0;
                background: $white;
                box-shadow: inset 10px 10px 209px 194px rgba(247,247,248,1);
                border-right: 1px solid $su-gray-light-40;
            }
        }
        &__subnav {
            display: none;
            @include breakpoint(xxl) {
                display: flex;
                min-width: 275px;
                background: $white;
            }
            &.is-inactive {
                display: none;
            }
        }
        &__content {
            width: 100%;
            display: flex;
            background-color: $white;
            justify-content: center;
            & > .anchor {
                position: absolute;
                left: 0px;
                top: -90px;
            }
            &-container {
                overflow: auto;
                width: 100%;
            }
        }
    }
</style>
