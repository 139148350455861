<!-- eslint-disable max-len -->
<template>
    <div class="modal" :class="modalVisibility"
        role="alertdialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        :aria-hidden="setAria">
        <div class="modal-container">
            <div class="modal-header">
                <span class="heading-display-h3">Modal Headline</span>
                <button class="modal-close"
                        role="button"
                        aria-label="Close modal"
                        @click="closeModal">
                    <span class="icon-times"></span>
                    <span class="modal-close-label">Close</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
            <div class="modal-footer flex-justify-end">
                <button class="button button-primary"
                        role="button"
                        aria-label="Close modal"
                        @click="closeModal">Close</button>
            </div>
        </div>
        <div class="modal-overlay" @click="closeModal"></div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'modal',
  computed: {
    ...mapState('modal', {
      isVisible: 'isVisible',
    }),
    setAria() {
      if (this.isVisible === true) {
        return 'false';
      }
      return true;
    },
    modalVisibility() {
      return {
        'is-active': this.isVisible,
      };
    },
  },
  methods: {
    ...mapMutations('modal', ['hideModal']),
    closeModal() {
      this.hideModal();
    },
  },
};
</script>
