<template>
    <div class="mobile-sidebar" v-if="isSidebarVisible">
        <nav class="mobile-sidebar__nav" aria-label="mobile navigation">
            <ul class="mobile-sidebar__nav-list">
                <mobile-submenu v-for="item in menuItems"
                    :key="item.linkName"
                    :link-name="item.linkName"
                    :link-location="item.linkLocation"
                    :id="item.id"
                    :sublinks="item.sublinks"
                    :sublink-linkname="item.sublinks.linkName" />
            </ul>
        </nav>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import MobileSubmenu from './mobile-submenu.vue';

export default {
  name: 'mobile-sidebar',
  components: {
    MobileSubmenu,
  },
  computed: {
    ...mapState('sidebar', { isSidebarVisible: 'isVisible' }),
    ...mapState('sidebar', ['menuItems']),
    sidebarVisibility() {
      return {
        'is-active': this.isSidebarVisible,
        true: this.isSidebarVisible,
      };
    },
  },
  methods: {
    ...mapMutations('sidebar', ['toggleMobileSidebar']),
  },
};
</script>

<style lang="scss">
    .mobile-sidebar {
        background: $white;
        position: fixed;
        top: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        &__nav {
            margin-top: 100px;
            &-list {
                list-style-type: none;
                padding: 0;
            }
            &-item {
                font-size: $text-size-9;
                margin-bottom: 0;
                &:not(:last-child){
                    border-bottom: 1px solid $border-gray-light;
                }
                & > a.router-link-active {
                    text-decoration: none;
                }
                & > a.router-link-exact-active.router-link-active {
                    text-decoration: none;
                }
                &-link {
                    padding: $spacer-2 $spacer-3 $spacer-2;
                    display: flex;
                    justify-content: space-between;
                    & > a {
                        text-decoration: none;
                    }
                }
            }
            &-submenu {
                list-style-type: none;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                box-shadow: inset 1px 3px 20px $su-gray-light-20;
                padding: 0;
                margin: 0;
                &-item {
                    padding: $spacer-2 0 $spacer-2 $spacer-4;
                    margin-bottom: 0;
                    &:not(:last-child){
                        border-bottom: 1px solid $border-gray-light;
                    }
                    &:first-child {
                        border-top: 1px solid $border-gray-light;
                    }
                }
                &.is-expanded {
                    max-height: 1200px;
                }
            }
        }
        &__menu-expand {
            transition: all 0.3s ease-in-out;
            border: $border-0;
            background: transparent;
            &.is-expanded {
                transform: rotate(180deg);
            }
        }
    }
</style>
