<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <components-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Forms</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 id="inputs" class="anchor-link" tabindex="-1">Inputs</h2>
            <inputs />
          </div>
          <div class="design-system__page-section">
            <h2 class="anchor-link" id="text-area" tabindex="-1">Text Area</h2>
            <text-area />
          </div>
          <div class="design-system__page-section">
            <h2 id="select" class="anchor-link" tabindex="-1">Select</h2>
            <select-input />
          </div>
          <div class="design-system__page-section">
            <h2 id="checkbox" class="anchor-link" tabindex="-1">Checkbox</h2>
            <checkbox />
          </div>
          <div class="design-system__page-section">
            <h2 id="radio" class="anchor-link" tabindex="-1">Radio</h2>
            <radio-button />
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PageTemplate from '../../../components/Base/page-template.vue';
import ComponentsSidebar from '../../../components/Sidebars/components-sidebar.vue';
import Inputs from './Inputs.vue';
import TextArea from './TextArea.vue';
import SelectInput from './SelectInput.vue';
import Checkbox from './Checkbox.vue';
import RadioButton from './RadioButton.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    ComponentsSidebar,
    Inputs,
    TextArea,
    SelectInput,
    Checkbox,
    RadioButton,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">

</style>
