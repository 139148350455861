<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <components-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Buttons</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2>Best Practices</h2>
            <p>Button elements are used primarily for actions
              such as “Submit”, “Close” or “Download”. Links can be styled like buttons, using the following classes below,
              but they should be visually differentiated from buttons. For example, good practice would be adding an icon to the styled link.
            </p>
          </div>
          <div class="design-system__page-section">
            <h2>Examples</h2>
            <div class="example__section">
              <h3>Primary Buttons</h3>
              <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <button class="button button-primary margin">
                      Primary Button
                    </button>
                    <button class="button button-secondary margin">
                      Secondary Button
                    </button>
                    <button class="button button-tertiary margin">Tertiary Button</button>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<button class='button button-primary'>Primary Button</button>
<button class='button button-secondary'>Secondary Button</button>
<button class='button button-tertiary'>Tertiary Button</button>" language="markup"></prism-editor></pre>
                </div>
              </div>
            </div>
            <div class="example__section">
              <h3>Outlined Buttons</h3>
              <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <button class="button button-primary-outline margin">Primary Outline</button>
                    <button class="button button-secondary-outline margin">
                      Secondary Outline
                    </button>
                    <button class="button button-tertiary-outline margin">Tertiary Outline</button>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<button class='button button-primary-outline'>Primary Outline</button>
<button class='button button-secondary-outline'>Secondary Outline</button>
<button class='button button-tertiary-outline'>Tertiary Outline</button>" language="markup"></prism-editor></pre>
                </div>
              </div>
            </div>
            <div class="example__section">
              <h3>Rounded Buttons</h3>
              <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <button class="button button-primary rounded margin">Primary Button</button>
                    <button class="button button-secondary rounded margin">
                      Secondary Button
                    </button>
                    <button class="button button-tertiary rounded margin">Tertiary Button</button>
                  </div>
                  <div class="example__demo-section">
                    <button class="button button-primary-outline rounded margin">
                      Primary Outline
                    </button>
                    <button class="button button-secondary-outline rounded margin">
                    Secondary Outline
                    </button>
                    <button class="button button-tertiary-outline rounded margin">
                      Tertiary Outline
                    </button>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<button class='button button-primary rounded'>Primary Button</button>
<button class='button button-secondary rounded'>Secondary Button</button>
<button class='button button-tertiary rounded'>Tertiary Button</button>
<button class='button button-primary-outline rounded'>Primary Outline</button>
<button class='button button-secondary-outline rounded'>Secondary Outline</button>
<button class='button button-tertiary-outline rounded'>Tertiary Outline</button>" language="markup"></prism-editor></pre>
                </div>
              </div>
            </div>
            <div class="example__section">
              <h3>Buttons With Icon Right</h3>
              <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <button class="button button-primary margin">Learn More
                      <span class="icon-right">
                        <i class="icon-chevron-right"></i>
                      </span>
                    </button>
                    <button class="button button-secondary margin">
                      Read Story
                      <span class="icon-right">
                        <i class="icon-arrow-right"></i>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<button class='button button-primary margin'>Learn More
  <span class='icon-right'>
    <i class='icon-chevron-right'></i>
  </span>
</button>
<button class='button button-secondary margin'>Read Story
  <span class='icon-right'>
    <i class='icon-arrow-right'></i>
  </span>
</button>" language="markup"></prism-editor></pre>
                </div>
              </div>
            </div>
            <div class="example__section">
              <h3>Buttons With Icon Left</h3>
              <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <button class="button button-primary margin">
                      <span class="icon-left">
                        <i class="icon-mail"></i>
                      </span>
                      Send Email
                    </button>
                    <button class="button button-secondary margin">
                      <span class="icon-left">
                        <i class="icon-telephone"></i>
                      </span>
                      Contact
                    </button>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<button class='button button-primary margin'>
  <span class='icon-left'>
    <i class='icon-mail'></i>
  </span>
  Send Email
</button>
<button class='button button-secondary margin'>
  <span class='icon-left'>
    <i class='icon-telephone'></i>
  </span>
  Contact
</button>" language="markup"></prism-editor></pre>
                </div>
              </div>
            </div>
            <div class="example__section">
              <h3>Buttons Variants</h3>
              <div class="example__container">
                <div class="example__demo background-blue-primary">
                  <div class="example__demo-section">
                    <button class="button button-inverse-white margin">
                      Button Inverse
                    </button>
                    <button class="button button-inverse-orange margin">
                      Button Inverse
                    </button>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<button class='button button-inverse-white margin'>
  Button Inverse
</button>
<button class='button button-inverse-orange margin'>
  Button Inverse
</button>" language="markup"></prism-editor></pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import ComponentsSidebar from '../../components/Sidebars/components-sidebar.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    ComponentsSidebar,
    PrismEditor,
  },
};
</script>

<style lang="scss">

</style>
