<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <page-template>
      <div slot="sidebar">
        <components-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Accordion</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2>Accordion</h2>
            <p>An accordion is a useful component for displaying large groups of related content while having the ability to save space on a site.
              Appropriate use cases are FAQ's, or for a list of features or options on a particular subject.</p>

              <p>Please note that what the design system is providing, is the html markup and css styling. Function will need to be provided in your language of choice.
                When adding the functionality to an accordion, you must also be aware of accessibility &mdash; for example, making sure that <code class="code-highlight">aria-expanded = 'true'</code> has been added to <code class="code-highlight">.accordion-header</code>. </p>
                <p>The design system also provides the class name of <code class="code-highlight">.is-open</code> to <code class="code-highlight">.accordion-content</code> and <code class="code-highlight">.accordion-toggle</code>
                which can be activated to hide or show the accordion content as well as change the appearance of the toggle icon</p>
              <div class="container-lg">
                <accordion />
                <div class="example__code margin-top-5 border-1">
                  <pre contenteditable="true"><prism-editor code="<div role='region' class='accordion'>
  <div class='accordion-item border border-gray-light'>
    <div class='accordion-header-text'>
      <h3 class='margin-bottom-0'>
        <button class='accordion-header full-width backround-gray-light-30 border-0 margin-0 padding-top-2 padding-bottom-2 padding-left-3 padding-right-3 aria-expanded='true'>
          Lorem ipsum dolor sit amet
          <div class='accordion-toggle icon-right' class='is-open'>
            <i class='icon-chevron-right'></i>
          </div>
        </button>
      </h3>
    </div>
    <div class='accordion-content is-open'>
      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
    </div>
  </div>
  <div class='accordion-item border border-gray-light'>
    <div class='accordion-header-text'>
      <h3 class='margin-bottom-0'>
        <button class='accordion-header full-width backround-gray-light-30 border-0 margin-0 padding-top-2 padding-bottom-2 padding-left-3 padding-right-3 aria-expanded='true'>
          Excepteur sint occaecat cupidatat non proident
          <div class='accordion-toggle icon-right' class='is-open'>
            <i class='icon-chevron-right'></i>
          </div>
        </button>
      </h3>
    </div>
    <div class='accordion-content is-open'>
      <p><p>Consequat interdum varius sit amet. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum lectus. At varius vel pharetra vel turpis nunc eget lorem.</p>
    </div>
  </div>
</div>" language="markup"></prism-editor></pre>
                </div>
              </div>
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import ComponentsSidebar from '../../components/Sidebars/components-sidebar.vue';
import Accordion from '../../components/Components/accordion/accordion.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    ComponentsSidebar,
    Accordion,
    PrismEditor,
  },
};
</script>

<style lang="scss">
  .design {
    height: 100%;
    &__content {
      padding: $spacer-4;
    }
  }
</style>
