<!-- eslint-disable max-len -->
<template>
    <div>
        <p>Adjust the display of an element with <code class="code-highlight">.display-block</code>, <code class="code-highlight">.display-none</code>,
        <code class="code-highlight">.display-inline</code>, <code class="code-highlight">.display-inline-block</code>, <code class="code-highlight">.display-table</code>,
        <code class="code-highlight">.display-table-row</code> and <code class="code-highlight">.display-table-cell</code> utilities.</p>
        <div class="padding border margin-bottom">
            <div class="display-inline padding margin-right border">.display-inline</div>
            <div class="display-inline-block margin padding border">.display-inline-block</div>
            <div class="display-block padding border">.display-block</div>
            <div class="display-table padding-2 border margin-top">
                <div class="display-table-cell padding-2 border">.display-table-cell</div>
                <div class="display-table-cell padding-2 border">.display-table-cell</div>
            </div>
            <div class="display-none">.display-none</div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='padding border'>
    <div class='display-inline padding margin-right border'>.display-inline</div>
    <div class='display-inline-block margin padding border'>.display-inline-block</div>
    <div class='display-block padding border'>.display-block</div>
    <div class='display-table padding-2 border margin-top'>
        <div class='display-table-cell padding-2 border'>.display-table-cell</div>
        <div class='display-table-cell padding-2 border'>.display-table-cell</div>
    </div>
    <div class='display-none'>.display-none</div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <h3>Responsive Display</h3>
        <p>A selection of display utilities can be applied or changed per breakpoint. <code class="code-highlight">.display-block, .display-none, .display-inline</code> and <code class="code-highlight">.display-inline-block</code> are available as responsive utilities using the following formula:
            <code class="code-highlight">display-[breakpoint]-[property]</code>. For example: <code class="code-highlight">display-md-inline-block</code>.
            Each responsive display utility is applied to the specified breakpoint and up.</p>
        <div class="border margin-bottom-4">
            <div class="padding">
                <div class="display-md-inline-block display-sm-block margin padding border">Inline-block on desktop. Block on mobile.</div>
                <div class="display-md-inline-block display-sm-block margin padding border">Inline-block on desktop. Block on mobile.</div>
            </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='display-md-inline-block display-sm-block margin padding border'>Inline-block on desktop. Block on mobile.</div>
<div class='display-md-inline-block display-sm-block margin padding border'>Inline-block on desktop. Block on mobile.</div>" language="markup"></prism-editor></pre>
                </div>
        </div>
        <h3>Responsive Hide</h3>
        <p>Hide utilities can be applied or changed per breakpoint using the following formula:
            <code class="code-highlight">hide-[breakpoint]</code>, for example: <code class="code-highlight">hide-sm</code>. Hide utilities act differently from other responsive styles and are applied to each <strong>breakpoint-range only</strong>.
        </p>
        <div class="row margin-bottom-4">
            <div class="col-md-6 col-sm-12">
                <table class="border">
                    <thead>
                        <tr>
                            <th>Shorthand</th>
                            <th>Range</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>-sm</td>
                            <td>0—543px</td>
                        </tr>
                        <tr>
                            <td>-md</td>
                            <td>544px—767px</td>
                        </tr>
                        <tr>
                            <td>-lg</td>
                            <td>768px—1011px</td>
                        </tr>
                        <tr>
                            <td>-xl</td>
                            <td>1012px and above</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="border background-gray-light-10 padding-3">
            <div class="flex-responsive-md">
                <div class="card margin-right-3 margin-bottom-3">
                    <div class="card-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </div>
                    <div class="card-footer">
                        <button class="button button-primary hide-sm">Hidden on Mobile</button>
                    </div>
                </div>
                <div class="card margin-bottom-3">
                    <div class="card-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </div>
                    <div class="card-footer">
                        <button class="button button-primary hide-xl">Hidden on Desktop</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='flex-responsive-md'>
    <div class='card margin-right-3 margin-bottom-3'>
        <div class='card-body'>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
        </div>
        <div class='card-footer'>
            <button class='button button-primary hide-sm'>Hidden on Mobile</button>
        </div>
    </div>
    <div class='card margin-bottom-3'>
        <div class='card-body'>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
        </div>
        <div class='card-footer'>
            <button class='button button-primary hide-xl'>Hidden on Desktop</button>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
        <div class="margin-top-3">
            <h3>Show Content for Screen Readers</h3>
            <p>There are occasional instances where content should be made available to screen reader users but hidden from sighted users. In the vast majority cases, content that is available visually should be available to screen reader users, and vice versa.
                However, there are a few cases where information or meaning is apparent visually but may not be apparent to screen reader users.
                In these rare cases, it may be appropriate to cause content to be read by a screen reader, but have the content remain invisible to sighted users.</p>
            <p>To hide an element, such as a button label, the class of <code class="code-highlight">.hide-accessible</code> can be added. In the below example, to be accessible the menu button needs to be labeled, but for design purposes, we only want the menu icon to show.
            The <code class="code-highlight">.hide-accessible</code> class will hide the label but still available to a screen reader.</p>
        </div>
        <div class="border margin-top-3 padding-6 background-gray-light-20">
            <div class="background-white padding-2 flex flex-justify-end">
                <button class="button button-transparent">
                    <span class="hide-accessible">Menu</span>
                    <span class="icon-hamburger font-size-8"></span>
                </button>
            </div>
        </div>
        <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='background-white padding-2 flex flex-justify-end'>
    <button class='button button-transparent'>
        <span class='hide-accessible'>Menu</span>
        <span class='icon-hamburger font-size-8'></span>
    </button>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  components: {
    PrismEditor,
  },
};
</script>
