<!-- eslint-disable max-len -->
<template>
    <div class="design">
        <page-template>
            <div slot="sidebar">
                <components-sidebar />
            </div>
            <div slot="content" :aria-hidden="isVisible">
                <div class="design-system__page-header">
                    <h1>Modal</h1>
                </div>
                <div class="design-system__page-content">
                    <div class="design-system__page-section">
                        <h2>Modal</h2>
                        <p>The modal structure is very simple:</p>
                        <p>
                            <ul>
                                <li><code class="code-highlight">.modal</code> is the wrapper for overlay and the modal content.
                                <ul>
                                    <li><code class="code-highlight">.modal-container</code> is the container for the modal content.</li>
                                    <li><code class="code-highlight">.modal-header</code> is the header for the modal content and contains a spot for headline text and a close button.</li>
                                    <li><code class="code-highlight">.modal-body</code> is for the main content of the modal.</li>
                                    <li><code class="code-highlight">.modal-footer</code> is the footer for the modal. Often this area is used for buttons for close or submit actions.</li>
                                    <li><code class="code-highlight">.modal-overlay</code> is a semi-transparent background that covers the full height and width of the viewport on desktop.</li>
                                </ul>
                            </li>
                            </ul>
                        </p>
                        <p>To activate the modal, just add the is-active modifier on <code class="code-highlight">.modal</code>.
                            Currently the Digital Design system does not include javascript, so you will need to provide your own implementation for the modal triggers.
                        </p>
                        <p><strong>Note: An accessibility check will be required when implementing a modal, for in some cases javascript will be needed to change the tab order of the modal.</strong></p>
                        <div class="padding-y-6">
                            <button class="button button-primary" role="button" @click="showModal">Click to launch example modal</button>
                        </div>
                        <modal />
                        <div class="example__code">
                            <pre contenteditable="true"><prism-editor code="<div class='modal is-active' role='alertdialog' aria-labelledby='modalTitle' aria-describedby='modalDescription' aria-hidden='false'>
        <div class='modal-container'>
            <div class='modal-header'>
                <span class='heading-display-h3'>Modal Headline</span>
                <button class='modal-close' role='button' aria-label='Close modal'>
                    <span class='icon-times'></span>
                    <span class='modal-close-label'>Close</span>
                </button>
            </div>
            <div class='modal-body'>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
            <div class='modal-footer flex-justify-end'>
                <button class='button button-primary' role='button' aria-label='Close modal'>Close</button>
            </div>
        </div>
        <div class='modal-overlay'></div>
    </div>" language="markup"></prism-editor>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </page-template>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import ComponentsSidebar from '../../components/Sidebars/components-sidebar.vue';
import Modal from '../../components/Components/modals/modal.vue';

export default {
  name: 'modal-page',
  components: {
    PageTemplate,
    PrismEditor,
    ComponentsSidebar,
    Modal,
  },
  computed: {
    ...mapState('modal', {
      isVisible: 'isVisible',
    }),
  },
  methods: {
    ...mapMutations('modal', ['showModal']),
  },
};
</script>
