<!-- eslint-disable max-len -->
<template>
    <prototype-page>
        <div slot="content">
            <div class="media">
              <img src="../../../assets/images/Assets_Hero@2x.jpg" alt="Photo of Andrew Regalado">
              <div class="offset-1 col-3 border padding-5">
                  <p class="heading-display-h6 is-bold is-caps">be orange</p>
                  <h1 class="heading-display-h1">Andrew Regalado Choreographs His Own Path to Public Service</h1>
                  <p class="intro-text-primary">Aspiring to be part of something greater than himself, the former professional
                  dancer is seizing chances to lead as a political science and policy studies major.</p>
                  <p class="heading-display-h6 margin-top-6 background-white-light-30">January 17, 2020 | By Brandon Dyer |</p>
                  <p>Andrew Regalado ’20 grew up in Chino Hills, California, determined to be a public servant. “I have always been
                  passionate about making a difference,” he says. When it was time to explore college options, he knew he wanted to
                  venture beyond his Southern California roots and be part of a geographically diverse student body. Syracuse
                  University drew his interest. He contacted <a href="#">Syracuse University Los Angeles</a> and learned about the hands-on learning
                  offered through the <a href="#">Maxwell School of Citizenship and Public Affairs</a>. He was inspired to be Orange without ever
                  visiting campus. Syracuse University’s <a href="#">Southern California Alumni Club</a> saw his potential and awarded him a
                  scholarship, instilling a confidence in him that helped with his cross-country transition. “I will always remember my
                  first step on campus as one that set me up for success for the four years ahead,” he says.
                  </p>
                  <h4 class="heading-display-h4">Inspired by Family</h4>
                  <p class="intro-text-primary margin-top-3">Moving across the country would be an extraordinary step for most first-year students, but Regalado’s family has
                  always driven him to be exceptional. A first-generation American citizen, he is the youngest brother in a family of six.
                  He’s also a member of a larger-than-life extended family, with a dozen aunts and uncles and family gatherings that
                  draw over 100 people. Even as a child, Regalado looked for ways he could set himself apart and make his family
                  proud. A talented dancer, he turned professional at age 8 after he convinced his mother to bring him to an open
                  casting call. “When people ask about the first time I tried making money, I describe that casting call,” he says.
                  <br>
                  <br>
                  On his mother’s advice, Regalado created his first résumé before he entered the fourth grade. He auditioned and
                  traveled to dance conventions all over the country. Being a professional dancer taught him the value of learning
                  through experience, he says. “No matter your age, if you’re on a set in Hollywood, you need to be a professional, and
                  you need to be prepared to work.”</p>
                  <h4 class="heading-display-h4">A New Chapter</h4>
                  <p class="intro-text-primary margin-top-3">While professional dancing helped him earn money for his education, his interest in public service eventually won out.
                  The sense of determination that had driven him to the entertainment industry also motivated him to leave his comfort
                  zone for college. He wanted to attend a school that would challenge his preconceptions—one that could provide a
                  new setting and the ability to apply his skills right away. He also wanted to surround himself with individuals who
                  inspired him to be his best in the same way his family did.</p>
                  <img src="../../../assets/images/Assets_article-image.jpg" alt="Photo of Andrew Regalado in classroom">
                  <p class="intro-text-primary"><span class="separate">Andrew Regalado looked for a university with a geographically diverse student body. Syracuse University, with
                  students representing every single state and over 150 countries, immediately caught his attention. “That’s why I
                  didn’t apply to a single school in California,” he says.</span></p>
                  <hr>
              </div>
              <div class="container-sm border padding-1 background-blue-light-10">
                <h4 class="heading-display-h4">More Stories</h4>
                <ul class="list-menu">
                  <li><a href="#"><span class="is-underlined">Harry Dydo is Right Where<br> He Belongs</span></a><br></li>
                  <li class="names">MARYBETH HORSINGTON</li>
                  <li><a href="#"><span class="is-underlined">Hanz Valbuena Builds an<br> International Community</span></a></li>
                  <li class="names">JAY COX</li>
                  <li><a href="#"><span class="is-underlined">Maxwell School Speakers<br> Through the Years</span></a></li>
                  <li class="names">DANA COOKE</li>
                </ul>
                <h4 class="heading-display-h4 padding-top-4">Applying to College?</h4>
                <p class="intro-text-primary margin-right-6 padding-right-6 padding-bottom-4">We’re here to help you with your college or graduate school search. We’ll send you
                information on your academic interest, upcoming events, and reminders so you can decide if Syracuse is for you.</p>
                <button class="button button-primary">Request Information</button>
                <h4 class="heading-display-h4 padding-top-5">About Syracuse</h4>
                <p class="intro-text-primary margin-right-6 padding-right-6">Orange isn’t just a color—it’s away of life. Here’s a quick look at why Syracuse is
                recognized as a student-focused global research university that’s renowned for academic rigor, richly diverse learning
                experiences, and a spirit of discovery.</p>
                <ul class="list">
                  <li class="padding-bottom-1"><a href="#">Facts, Figures, and Rankings</a></li>
                  <li class="padding-bottom-1"><a href="#">Undergraduate Majors and Minors</a></li>
                  <li class="padding-bottom-1"><a href="#">Graduate Degree Programs</a></li>
                  <li class="padding-bottom-1"><a href="#">Admissions Information and Help</a></li>
                  <li class="padding-bottom-1"><a href="#">Affordability and Financial Aid</a></li>
                  <li class="padding-bottom-1"><a href="#">What Does it Mean to #BeOrange?</a></li>
                </ul>
              </div>
            </div>
        </div>
    </prototype-page>
</template>

<script>
import PrototypePage from '../../../components/Base/prototype-page.vue';

export default {
  name: 'contact-form',
  components: {
    PrototypePage,
  },
};
</script>

<style lang="scss" scoped>
  img{
    max-width: 100%;
    height: auto;
    position:relative;
    min-width:100%;
    padding-bottom:8px;
  }
  .offset-1{
    margin:-5rem;
    position:relative;
    z-index:1;
    background-color:#FFFFFF;
    width:52%;
    height:100%;
    border:none;
  }
  .container-sm{
    width:30%;
    float:right;
    border:none;
    position:relative;
    background-color:#F7F7F8;
    margin-top:-1500px;
  }
  .separate{
    color:#404040;
  }
  .names{
    font-size: .500rem;
    padding-top:8px;
    padding-bottom:16px;
  }
  @media screen and (max-width:700px)
  {
    .media{
      display:flex;
      justify-content:flex-start;
      align-content:center;
      align-items:center;
      flex-direction:column;
    }
    .offset-1{
      margin-right:35px;
      width:90%;
      margin-top:-1rem;
    }
    .container-sm{
      margin-top:100px;
      width:90%;
    }
  }
</style>
