<!-- eslint-disable max-len -->
<template>
    <div class="margin-top-6">
        <h2 id="grid-nesting" class="anchor-link" tabindex="-1">Grid Nesting</h2>
        <p>The true power of css grid is the ability to create layouts where you can combine the ability of adding rows and columns in complex arrangements. Nesting your grid allows you to add rows inside of a column or vice versa.</p>
        <p>To nest your grid, you will just need to add the class of <code class="code-highlight">.grid</code> to your child elements, and then any other grid utility to that child.</p>
        <p>In the following example, we have defined a grid with 2 grid columns. In the first column there are 2 columns inside of it. The second column has 3 grid rows.</p>
        <div class="margin-y-4">
            <div class="grid grid-columns-2 grid-columns-gap-3 background-blue-primary-10 padding-3">
                <div class="grid grid-columns-2 grid-columns-gap-3">
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">1</span>
                    </div>
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">2</span>
                    </div>
                </div>
                <div class="grid grid-rows-gap-3">
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">3</span>
                    </div>
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">4</span>
                    </div>
                    <div class="background-blue-primary padding-4 text-white text-align-center">
                        <span class="is-bold font-size-8">5</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-columns-2 grid-columns-gap-3 background-blue-primary-10 padding-3'>
    <div class='grid grid-columns-2 grid-columns-gap-3'>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>1</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>2</span>
        </div>
    </div>
    <div class='grid grid-rows-gap-3'>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>3</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>4</span>
        </div>
        <div class='background-blue-primary padding-4 text-white text-align-center'>
            <span class='is-bold font-size-8'>5</span>
        </div>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
