<!-- eslint-disable max-len -->
<template>
  <div class="design-system__page">
    <page-template>
      <div slot="sidebar">
        <design-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Media</h1>
        </div>
        <div class="design-system__page-content">
            <div class="design-system__page-section">
                <h2 id="responsive-images" class="anchor-link" tabindex="-1">Responsive Images</h2>
                <p>By default, images are responsive and will stretch to the width of their parent container while the height will remain proportional.</p>
                <div class="padding-3 border">
                    <img src="../../assets/images/image-size-example.jpg" alt="Responsive image" />
                </div>
                <div class="example__code margin-bottom-5">
                    <pre contenteditable="true"><prism-editor code="<div class='padding-3 border'>
    <img src='../../assets/images/image-size-example.jpg' alt='Responsive image' />
</div>" language="markup"></prism-editor></pre>
                </div>
                <h3>Responsive Aspect Ratios</h3>
                <p>In addition to having an image be responsive, it is often desirable to have an image conform to specific aspect ratios at a variety of viewport sizes.
                    Below are utility classes for several commonly used aspect ratios. If an image used is not the same as your desired aspect ratio, the image will be cropped to conform to the aspect ratio.
                    Image placement is to the top left of the container.</p>
                <table class="image-table">
                    <thead>
                        <tr>
                            <th>Class Name</th>
                            <th>Image</th>
                            <th>Image Ratio</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="image-table__text-cell">.image-one-by-one</td>
                            <td>
                                <div class="image-table__image-cell">
                                    <div class="image-one-by-one">
                                        <img src="../../assets/images/480x480.svg" alt="sample 1 by 1 ratio"/>
                                    </div>
                                </div>
                            </td>
                            <td class="image-table__text-cell">1:1 Ratio</td>
                        </tr>
                        <tr>
                            <td class="image-table__text-cell">.image-sixteen-by-nine</td>
                            <td>
                                <div class="image-table__image-cell">
                                    <div class="image-sixteen-by-nine">
                                        <img src="../../assets/images/480x270.svg" alt="sample 16 by 9 ratio"/>
                                    </div>
                                </div>
                            </td>
                            <td class="image-table__text-cell">16:9 Ratio</td>
                        </tr>
                        <tr>
                            <td class="image-table__text-cell">.image-four-by-three</td>
                            <td>
                                <div class="image-table__image-cell">
                                    <div class="image-four-by-three">
                                        <img src="../../assets/images/480x360.svg" alt="sample 4 by 3 ratio"/>
                                    </div>
                                </div>
                            </td>
                            <td class="image-table__text-cell">4:3 Ratio</td>
                        </tr>
                        <tr>
                            <td class="image-table__text-cell">.image-three-by-two</td>
                            <td>
                                <div class="image-table__image-cell">
                                    <div class="image-three-by-two">
                                        <img src="../../assets/images/480x320.svg" alt="sample 3 by 2 ratio"/>
                                    </div>
                                </div>
                            </td>
                            <td class="image-table__text-cell">3:2 Ratio</td>
                        </tr>
                        <tr>
                            <td class="image-table__text-cell">.image-two-by-three</td>
                            <td>
                                <div class="image-table__image-cell">
                                    <div class="image-two-by-three">
                                        <img src="../../assets/images/480x720.svg" alt="sample 2 by 3 ratio"/>
                                    </div>
                                </div>
                            </td>
                            <td class="image-table__text-cell">2:3 Ratio</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="design-system__page-section">
                <div class="border padding-3 background-gray-light-10">
                    <div class="image-sixteen-by-nine">
                        <img src="../../assets/images/image-size-example.jpg" alt="example image" />
                    </div>
                    <div class="background-white padding-3">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='border padding-3 background-gray-light-10'>
    <div class='image-sixteen-by-nine'>
        <img src='../../assets/images/image-size-example.jpg' alt='example image' />
    </div>
    <div class='background-white padding-3'>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <div class="design-system__page-section">
                <h2 id="fixed-width-images" class="anchor-link" tabindex="-1">Fixed Width Images</h2>
                <p>In addition to responsive images the design system provides a series of common fixed width image sizes, as well as a reset an image to its actual dimensions.
                    Fixed width image sizes are useful for avatars and thumbnail images.</p>
                <table class="image-table">
                    <thead>
                        <tr>
                            <th>Class Name</th>
                            <th>Image</th>
                            <th>Image Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="image-table__text-cell">.image-64x64</td>
                            <td>
                                <div class="image-table__image-cell">
                                    <div class="image-64x64">
                                        <img src="../../assets/images/64x64.svg" alt="sample 64 x 64 pixels"/>
                                    </div>
                                </div>
                            </td>
                            <td class="image-table__text-cell">64 x 64px</td>
                        </tr>
                        <tr>
                            <td class="image-table__text-cell">.image-96x96</td>
                            <td>
                                <div class="image-table__image-cell">
                                    <div class="image-96x96">
                                        <img src="../../assets/images/96x96.svg" alt="sample 96 x 96 pixels"/>
                                    </div>
                                </div>
                            </td>
                            <td class="image-table__text-cell">96 x 96px</td>
                        </tr>
                        <tr>
                            <td class="image-table__text-cell">.image-128x128</td>
                            <td>
                                <div class="image-table__image-cell">
                                    <div class="image-128x128">
                                        <img src="../../assets/images/128x128.svg" alt="sample 128 x 128 pixels"/>
                                    </div>
                                </div>
                            </td>
                            <td class="image-table__text-cell">128 x 128px</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="design-system__page-section">
                <h2 id="responsive-video" class="anchor-link" tabindex="-1">Responsive Video</h2>
                <p>The responsive video class functions similarly to the responsive image classes.
                    The <code class="code-highlight">.video</code> class will stretch to 100% width of its container and will maintain a 16:9 aspect ratio.</p>
                <div class="border padding-3">
                    <div class="video">
                        <iframe src="https://www.youtube.com/embed/BHACKCNDMW8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='video'>
    <iframe src='https://www.youtube.com/embed/BHACKCNDMW8' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>
</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import DesignSidebar from '../../components/Sidebars/design-sidebar.vue';

export default {
  name: 'home',
  components: {
    PageTemplate,
    DesignSidebar,
    PrismEditor,
  },
};
</script>

<style lang="scss">
    .image-table {
        border: $border;
        &__image-cell {
            width: 140px;
            @include breakpoint(md) {
                width: 480px;
            }
        }
        &__text-cell {
            width: 25%;
        }
        & td {
            padding: 8px;
            @include breakpoint(md) {
                padding: 16px;
            }
        }
    }
</style>
