<!-- eslint-disable max-len -->
<template>
  <div class="home">
    <basic-template>
      <div slot="content">
        <div class="home__hero">
          <div class="home__hero-content">
            <div class="home__hero-content-container">
              <div class="home__hero-logo-container">
                <img class="home__hero-logo" src="../assets/Logos/syracuse-university.svg"
                alt="Syracuse University"/>
              </div>
              <div class="home__hero-title"><h1>Digital Design System</h1></div>
              <div class="text-white padding-bottom-3"><span class="font-size-9 padding-right-2">Version</span><span class="font-size-11"> 1.6.5</span></div>
            </div>
          </div>
          <div class="home__hero-image-container">
            <img class="home__hero-image" src="../assets/Logos/block-s.svg" alt=""/>
          </div>
        </div>
        <div class="home__content">
          <div class="design-system__page-content">
            <div class="design-system__page-section">
              <p>The Digital Design System has been crafted to build consistent user experiences, striking a balance between flexibility and adherence to Syracuse University brand standards.
                Built to be mobile-first, the design system features accessible markup as well as components using semantic HTML 5 markup and flexbox.</p>
              <h2 id="get-started" class="anchor-link" tabindex="-1">Get Started</h2>
              <h3>Minified CSS</h3>
              <p>The css is also available from a CDN, use the following link tag to add a globally available cached version of the design system css to your project.</p>
              <h4>Latest CDN Version</h4>
              <div class="background-gray-light-10 border margin-bottom-3">
                <pre contenteditable="true"><prism-editor code="<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.6.5/dds.min.css'>" language="markup"></prism-editor></pre>
              </div>
              <h4>To Use Latest Version</h4>
              <div class="background-gray-light-10 border margin-bottom-3">
                <pre contenteditable="true"><prism-editor code="<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/latest/dds.min.css'>" language="markup"></prism-editor></pre>
              </div>
              <h4 class="margin-top-3">Archived Versions</h4>
              <pre contenteditable="true"><prism-editor code="<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.0/dds.min.css'>
<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.1.1/dds.min.css'>
<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.2.1/dds.min.css'>
<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.3.1/dds.min.css'>
<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.4.1/dds.min.css'>
<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.4.2/dds.min.css'>
<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.5.2/dds.min.css'>
<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.6.2/dds.min.css'>
<link rel='stylesheet' href='https://fastly.cdn.syracuse.edu/dds/v1.6.3/dds.min.css'>" language="markup"></prism-editor></pre>
              <h3 class="margin-top-3">Clone or Download Project</h3>
              <p>The project is accessible through the <a href="https://gitlab.digitalservices.syr.edu/web-development/design-system-sass">University Gitlab account</a>.
              For more information please <a href="mailto:op@ot.syr.edu">contact the Digital team</a> in the Division of Marketing and Communications.</p>
              <h3>NPM Install</h3>
              <div class="background-gray-light-10 border">
                <pre contenteditable="true"><prism-editor code="npm install git+https://npm-install:3sA_EZq7E4Gr6fNxjuif@gitlab.digitalservices.syr.edu/web-development/design-system-sass.git" language="markup"></prism-editor></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </basic-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import BasicTemplate from '../components/Base/basic-template.vue';

export default {
  name: 'home',
  components: {
    BasicTemplate,
    PrismEditor,
  },
};
</script>

<style lang="scss">
  .home {
    height: 100%;
    &__hero {
      background: $su-blue-primary-100;
      height: 100vh;
      overflow: hidden;
      display: flex;
      flex-direction: column-reverse;
      padding: 1rem;
      position: relative;
      @media screen and (max-height: 736px) {
        height: 100vh;
      }
      @include breakpoint(md) {
        flex-direction: row;
        height: 75vh;
        margin-top: 0;
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        @include breakpoint(md) {
          align-items: flex-end;
          margin-right: 2rem;
        }
        &-container {
          display: flex;
          flex-flow: column;
          align-items: center;
        }
      }
      &-logo {
        &-container {
          width: 100%;
          margin-bottom: 1.5rem;
          margin-top: 1rem;
        }
      }
      &-image {
        height: 100% !important;
        margin-top: -$spacer-6;
        @include breakpoint(md) {
          right: -10px;
          bottom: -18px;
          height: 100%;
          position: absolute;
          margin-top: 0;
        }
        &-container {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          position: relative;
          height: 100%;
          width: 100%;
          @include breakpoint(md) {
            flex-direction: row;
            justify-content: flex-end;
            position: relative;
          }
        }
      }
      &-title {
        h1{
        color: $white;
        font-weight: 500;
        font-size: 2rem;
        width: 100%;
        margin: .5rem 0;
        padding: 1rem 0;
        border-top: 2px solid $white;
        border-bottom: 2px solid $white;
        text-align: center;
        }
      }
    }
    &__content {
      padding: $spacer-4;
      width: 100%;
      max-width: 65em;
      margin: 0 auto;
    }
  }
</style>
