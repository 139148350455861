<!-- eslint-disable max-len -->
<template>
    <div class="margin-top-6">
        <h2 id="column-start-end" class="anchor-link" tabindex="-1">Grid Column Start/End</h2>
        <p>Use the <code class="code-highlight">.grid-columns-start-{x}</code> and <code class="code-highlight">.grid-columns-end-{x}</code> utilities to make an element start or end at a specifically grid line. These can also be combined with the <code class="code-highlight">.grid-columns-span-{x}</code> utilities to span a specific number of columns.</p>
        <p>Note that CSS grid lines start at 1, not 0, so a full-width element in a 6-column grid would start at line 1 and end at line 7. The values that are available to use are 1 through 13.</p>
        <p>In the following example we are using the class <code class="code-highlight">.grid-columns-start-2</code> and <code class="code-highlight">.grid-columns-span-4</code> on block #1 to have it start at the second column and span 4 columns.</p>
        <div class="margin-y-4">
            <div class="grid grid-columns-6 grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4">
                <div class="background-blue-medium-70"></div>
                <div class="grid-columns-start-2 grid-columns-span-4 background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">1</span>
                </div>
                <div class="background-blue-medium-70"></div>
                <div class="grid-columns-start-1 grid-columns-end-3 background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">2</span>
                </div>
                <div class="background-blue-medium-70"></div>
                <div class="background-blue-medium-70"></div>
                <div class="grid-columns-start-5 grid-columns-span-2 background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">3</span>
                </div>
                <div class="grid-columns-start-1 grid-columns-end-7 background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">4</span>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-columns-6 grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4'>
    <div class='background-blue-medium-70'></div>
    <div class='grid-columns-start-2 grid-columns-span-4 background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>1</span>
    </div>
    <div class='background-blue-medium-70'></div>
    <div class='grid-columns-start-1 grid-columns-end-3 background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>2</span>
    </div>
    <div class='background-blue-medium-70'></div>
    <div class='background-blue-medium-70'></div>
    <div class='grid-columns-start-5 grid-columns-span-2 background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>3</span>
    </div>
    <div class='grid-columns-start-1 grid-columns-end-7 background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>4</span>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
