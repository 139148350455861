<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <page-template>
      <div slot="sidebar">
        <design-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Branded Logos</h1>
        </div>
        <div class="background-gray-light-10">
            <div class="design-system__page-content">
                <div class="design-system__page-section">
                    <div class="logo-list">
                        <div class="card margin-bottom">
                            <div class="card-header">
                                <h2 class="margin-0">Syracuse University Logos</h2>
                            </div>
                            <div class="card-body">
                            </div>
                        </div>
                        <div class="card margin-bottom">
                            <div class="card-header">
                                <h2 class="margin-0">Schools &amp; Colleges</h2>
                            </div>
                        </div>
                        <div class="card margin-bottom">
                            <div class="card-header">
                                <h2 class="margin-0">Centers &amp; Institutes</h2>
                            </div>
                        </div>
                        <div class="card margin-bottom">
                            <div class="card-header">
                                <h2 class="margin-0">Locations</h2>
                            </div>
                        </div>
                        <div class="card margin-bottom">
                            <div class="card-header">
                                <h2 class="margin-0">Services and Functional Groups</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PageTemplate from '../../../components/Base/page-template.vue';
import DesignSidebar from '../../../components/Sidebars/design-sidebar.vue';

export default {
  name: 'borders',
  components: {
    PageTemplate,
    DesignSidebar,
  },
};
</script>
