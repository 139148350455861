const state = {
  prototypes: [
    {
      prototypeName: 'Article With List',
      prototypeImage: 'article-with-list.svg',
      prototypeCategory: 'Typography',
      prototypeLink: 'article-with-list',
    },
    {
      prototypeName: 'Flexbox',
      prototypeImage: 'flexbox.svg',
      prototypeCategory: 'Layout',
      prototypeLink: 'flexbox-prototype',
    },
    {
      prototypeName: 'Horizontal Cards',
      prototypeImage: 'horz-card-stack.svg',
      prototypeCategory: 'Layout',
      prototypeLink: 'prototype-horizontal-cards',
    },
  ],
  categories: [
  ],
};
const getters = {};
const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
