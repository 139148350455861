import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import Breakpoints from '../views/Layout/Breakpoints/Breakpoints.vue';
import Columns from '../views/Layout/Columns/Columns.vue';
import Containers from '../views/Layout/Containers.vue';
import Layout from '../views/Layout/Layout/Layout.vue';
import Flexbox from '../views/Layout/Flexbox/Flexbox.vue';
import FlexboxUtilities from '../views/Layout/Flexbox/FlexboxUtilities.vue';
import Grid from '../views/Layout/Grid/Grid.vue';

import Colors from '../views/Design/Colors.vue';
import Typography from '../views/Design/Typography/Typography.vue';
import Spacing from '../views/Design/Spacing.vue';
import Borders from '../views/Design/Borders.vue';
import Logos from '../views/Design/Logos/Logos.vue';
import BoxShadow from '../views/Design/Boxshadow.vue';
import Media from '../views/Design/Images.vue';
import Overlay from '../views/Design/Overlay.vue';

import Buttons from '../views/Components/Buttons.vue';
import Forms from '../views/Components/Forms/Forms.vue';
import Tables from '../views/Components/Tables/Tables.vue';
import Accordion from '../views/Components/Accordion.vue';
import Cards from '../views/Components/Cards/Cards.vue';
import LoadingGraphics from '../views/Components/LoadingGraphics.vue';
import Modal from '../views/Components/Modal.vue';
import Headers from '../views/Components/Header.vue';

import Prototypes from '../views/Prototypes/Prototypes.vue';

import ContactForm from '../views/Prototypes/ContactForm.vue';
import GenericHeader from '../views/Prototypes/HeadersGeneric.vue';
import FormValidation from '../views/Prototypes/Forms/FormValidation.vue';
import Article from '../views/Prototypes/Articles/ArticleFuture.vue';
import ArticleWithList from '../views/Prototypes/Articles/ArticleWithList.vue';
import FlexboxPrototype from '../views/Prototypes/Layout/FlexboxPrototype.vue';
import TilePrototype from '../views/Prototypes/Layout/TilePrototype.vue';
import HorizontalCardStack from '../views/Prototypes/Cards/HorizontalCardStacks.vue';
import SearchForm from '../views/Prototypes/Forms/SearchForm.vue';

import IconLibrary from '../views/Icons/IconLibrary.vue';
import IconUsage from '../views/Icons/IconUsage.vue';

import Accessibility from '../views/Accessibility.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/layout/breakpoints',
    name: 'breakpoints',
    component: Breakpoints,
  },
  {
    path: '/layout/columns',
    name: 'columns',
    component: Columns,
  },
  {
    path: '/layout/containers',
    name: 'containers',
    component: Containers,
  },
  {
    path: '/layout/layout',
    name: 'layout',
    component: Layout,
  },
  {
    path: '/layout/flexbox',
    name: 'flexbox',
    component: Flexbox,
  },
  {
    path: '/layout/flexbox-utilities',
    name: 'flexbox-utilities',
    component: FlexboxUtilities,
  },
  {
    path: '/layout/grid',
    name: 'grid',
    component: Grid,
  },
  {
    path: '/design/colors',
    name: 'colors',
    component: Colors,
  },
  {
    path: '/design/typography',
    name: 'typography',
    component: Typography,
  },
  {
    path: '/design/media',
    name: 'media',
    component: Media,
  },
  {
    path: '/design/spacing',
    name: 'spacing',
    component: Spacing,
  },
  {
    path: '/design/borders',
    name: 'borders',
    component: Borders,
  },
  {
    path: '/design/box-shadow',
    name: 'box-shadow',
    component: BoxShadow,
  },
  {
    path: '/design/overlay',
    name: 'overlay',
    component: Overlay,
  },
  {
    path: '/design/logos',
    name: 'logos',
    component: Logos,
  },
  {
    path: '/components/buttons',
    name: 'buttons',
    component: Buttons,
  },
  {
    path: '/components/forms',
    name: 'forms',
    component: Forms,
  },
  {
    path: '/components/tables',
    name: 'tables',
    component: Tables,
  },
  {
    path: '/components/cards',
    name: 'cards',
    component: Cards,
  },
  {
    path: '/components/accordion',
    name: 'accordion',
    component: Accordion,
  },
  {
    path: '/components/loading-graphics',
    name: 'loading-graphics',
    component: LoadingGraphics,
  },
  {
    path: '/components/modal',
    name: 'modal',
    component: Modal,
  },
  {
    path: '/components/headers',
    name: 'headers',
    component: Headers,
  },
  {
    path: '/prototypes/',
    name: 'prototypes',
    component: Prototypes,
  },
  {
    path: '/prototypes/contact-form',
    name: 'contact-form',
    component: ContactForm,
  },
  {
    path: '/prototypes/generic-header',
    name: 'generic-header',
    component: GenericHeader,
  },
  {
    path: '/prototypes/forms/form-validation',
    name: 'form-validation',
    component: FormValidation,
  },
  {
    path: '/prototypes/forms/search-form',
    name: 'search-form',
    component: SearchForm,
  },
  {
    path: '/prototypes/articles/article',
    name: 'article',
    component: Article,
  },
  {
    path: '/prototypes/articles/article-with-list',
    name: 'article-with-list',
    component: ArticleWithList,
  },
  {
    path: '/prototypes/layout/flexbox-prototype',
    name: 'flexbox-prototype',
    component: FlexboxPrototype,
  },
  {
    path: '/prototypes/layout/tile-prototype',
    name: 'tile-prototype',
    component: TilePrototype,
  },
  {
    path: '/prototypes/cards/horizontal-cards',
    name: 'prototype-horizontal-cards',
    component: HorizontalCardStack,
  },
  {
    path: '/icon-library',
    name: 'icon-library',
    component: IconLibrary,
  },
  {
    path: '/icon-usage',
    name: 'icon-usage',
    component: IconUsage,
  },
  {
    path: '/accessibility',
    name: 'accessibility',
    component: Accessibility,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  // eslint-disable-next-line consistent-return
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
          });
        }, 100);
      });
    } if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
