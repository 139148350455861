<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <page-template>
      <div slot="sidebar">
        <design-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Borders</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 class="anchor-link" id="default-border" tabindex="-1">Default Border</h2>
            <p>The default class for a border is: <code class="code-highlight">.border</code>. This border style is incorporated in elements such as form text imputs and select inputs. It applies a solid, 1px border, with a color of <code class="code-highlight">$su-gray-medium-60</code>.</p>
            <p>To remain consistent with the style and branding of Syracuse University, borders should be used sparingly and to separate distinct pieces of content.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <div class="border padding-3">Default Border</div>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<div class='border padding-3'>Default Border</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 class="anchor-link" id="directional-border" tabindex="-1">Directional Borders</h2>
            <p>Borders can be applied to a specific edge or to the X and Y axes individually:</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <div class="padding-3 margin-right margin-bottom background-gray-light-20"
                      :class="border.className" v-for="border in directionalBorders"
                      :key="border.borderType">
                      {{ border.borderType }}
                    </div>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<div class='border-top padding-3 margin-right background-gray-light-20'>.border-top</div>
<div class='border-right padding-3 margin-right background-gray-light-20'>.border-right</div>
<div class='border-bottom padding-3 margin-right background-gray-light-20'>.border-bottom</div>
<div class='border-left padding-3 margin-right background-gray-light-20'>.border-left</div>
<div class='border-x padding-3 margin-right background-gray-light-20'>.border-x</div>
<div class='border-y padding-3 margin-right background-gray-light-20'>.border-y</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 class="anchor-link" id="border-weight" tabindex="-1">Border Weight</h2>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <div class="padding-3 margin-right margin-bottom background-gray-light-20"
                      :class="border.className" v-for="border in borderWeight"
                      :key="border.borderType">{{ border.borderType }}</div>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<div class='border-0 padding-3 margin-right background-gray-light-20'>.border-0</div>
<div class='border-1 padding-3 margin-right background-gray-light-20'>.border-1</div>
<div class='border-2 padding-3 margin-right background-gray-light-20'>.border-2</div>
<div class='border-3 padding-3 margin-right background-gray-light-20'>.border-3</div>
<div class='border-4 padding-3 margin-right background-gray-light-20'>.border-4</div>
<div class='border-5 padding-3 margin-right background-gray-light-20'>.border-5</div>
<div class='border-6 padding-3 margin-right background-gray-light-20'>.border-6</div>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <p>Border weight can be combined with directionality by combining border size and direction. Example: <code class="code-highlight">.border-top-3</code></p>
            <div class="example__container">
              <div class="example__demo">
                <div class="example__demo-section">
                  <div class="padding-3 margin-right margin-bottom background-gray-light-20"
                    :class="border.className" v-for="border in borderSizeDirection"
                    :key="border.borderType">{{ border.borderType }}</div>
                </div>
              </div>
              <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='border-top-1 padding-3 margin-right background-gray-light-20'>.border-top-1</div>
<div class='border-bottom-2 padding-3 margin-right background-gray-light-20'>.border-bottom-2</div>
<div class='border-right-3 padding-3 margin-right background-gray-light-20'>.border-right-3</div>
<div class='border-left-4 padding-3 margin-right background-gray-light-20'>.border-left-4</div>
<div class='border-x-5 padding-3 margin-right background-gray-light-20'>.border-x-5</div>
<div class='border-y-6 padding-3 margin-right background-gray-light-20'>.border-y-6</div>" language="markup"></prism-editor></pre>
              </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="border-color" tabindex="-1" class="anchor-link">Border Color Utilities</h2>
            <p>Border colors are a utility class used to modify the color of an applied border. As such, they must be used in conjunction with and appear after a border direction or border size class.</p>
            <div class="example__container">
              <div class="example__demo">
                <div class="example__demo-section" v-for="border in borderColor" :key="border.borderType">
                  <div class="border-bottom-3 padding-3 background-gray-light-20 full-width flex-space-between"
                    :class="border.className"><span>{{ border.borderType }}</span><span>{{ border.hexName }}</span></div>
                </div>
              </div>
              <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='border-bottom-3 border-black padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-black</span><span>#000000</span></div>
<div class='border-bottom-3 border-white padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-white</span><span>#FFFFFF</span></div>
<div class='border-bottom-3 border-gray-light padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-gray-light</span><span>#C6CACD</span></div>
<div class='border-bottom-3 border-gray padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-gray</span><span>#A9ADB3</span></div>
<div class='border-bottom-3 border-gray-dark padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-gray-dark</span><span>#7E858D</span></div>
<div class='border-bottom-3 border-blue-light padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-blue-light</span><span>#2B72D7</span></div>
<div class='border-bottom-3 border-blue-medium padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-blue-medium</span><span>#203299</span></div>
<div class='border-bottom-3 border-blue padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-blue</span><span>#000E54</span></div>
<div class='border-bottom-3 border-orange-light padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-orange-light</span><span>#FF8E00</span></div>
<div class='border-bottom-3 border-orange padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-orange</span><span>#F76900</span></div>
<div class='border-bottom-3 border-orange-medium padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-orange-medium</span><span>#FF431B</span></div>
<div class='border-bottom-3 border-orange-dark padding-3 background-gray-light-20 full-width flex-space-between'><span>.border-orange-dark</span><span>#D74100</span></div>" language="markup"></prism-editor></pre>
              </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="border-radius" tabindex="-1" class="anchor-link">Border Radius</h2>
            <p>Use the following utilities to add or remove rounded corners: <code class="code-highlight">.rounded-0</code> removes rounded corners, <code class="code-highlight">.rounded-1</code> applies a border radius of 4px, rounded-2 applies a border radius of 8px and <code class="code-highlight">.circle</code> applies a border radius of 50%, which turns square elements into perfect circles.</p>
            <div class="example__container">
              <div class="example__demo">
                <div class="example__demo-section">
                  <div class="border-2 padding-3 background-gray-light-20 margin-right margin-bottom"
                    v-for="border in borderRadius" :key="border.borderType"
                    :class="border.className" :style="border.style">{{ border.borderType }}</div>
                </div>
              </div>
              <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='border-2 rounded-0 padding-3 background-gray-light-20'>.rounded-0</div>
<div class='border-2 rounded-1 padding-3 background-gray-light-20'>.rounded-1</div>
<div class='border-2 rounded-2 padding-3 background-gray-light-20'>.rounded-2</div>
<div class='border-2 circle padding-3 background-gray-light-20'>.circle</div>" language="markup"></prism-editor></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import { mapState } from 'vuex';
import PageTemplate from '../../components/Base/page-template.vue';
import DesignSidebar from '../../components/Sidebars/design-sidebar.vue';

export default {
  name: 'borders',
  components: {
    PageTemplate,
    DesignSidebar,
    PrismEditor,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('borders', ['directionalBorders', 'borderWeight', 'borderSizeDirection', 'borderColor', 'borderRadius']),
  },
};
</script>
