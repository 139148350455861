<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <page-template>
      <div slot="sidebar">
        <design-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Spacing</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 id="spacing-scale" class="anchor-link" tabindex="-1">Spacing Scale</h2>
            <p>The spacing scale is a base-8 scale because 8 is a highly composable number (it can be divided and multiplied many times and result in whole numbers), yet it allows spacing dense enough for a variety of UI's.
                The scale's exception is that it begins at 4px to allow smaller padding and margin for denser parts of the site, from there it steps up consistently in equal values of 8px.
            </p>
            <div class="col-md-6 col-sm-12">
                <table class="margin-bottom-5 border">
                    <thead>
                        <tr>
                        <th>Variable</th>
                        <th>Scale</th>
                        <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>$spacer-0</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>$spacer-1</td>
                            <td>1</td>
                            <td>4px</td>
                        </tr>
                        <tr>
                            <td>$spacer-2</td>
                            <td>2</td>
                            <td>8px</td>
                        </tr>
                        <tr>
                            <td>$spacer-3</td>
                            <td>3</td>
                            <td>16px</td>
                        </tr>
                        <tr>
                            <td>$spacer-4</td>
                            <td>4</td>
                            <td>24px</td>
                        </tr>
                        <tr>
                            <td>$spacer-5</td>
                            <td>5</td>
                            <td>32px</td>
                        </tr>
                        <tr>
                            <td>$spacer-6</td>
                            <td>6</td>
                            <td>40px</td>
                        </tr>
                        <tr>
                            <td>$spacer-7</td>
                            <td>7</td>
                            <td>48px</td>
                        </tr>
                        <tr>
                            <td>$spacer-8</td>
                            <td>8</td>
                            <td>56px</td>
                        </tr>
                        <tr>
                            <td>$spacer-9</td>
                            <td>9</td>
                            <td>64px</td>
                        </tr>
                        <tr>
                            <td>$spacer-10</td>
                            <td>10</td>
                            <td>72px</td>
                        </tr>
                        <tr>
                            <td>$spacer-11</td>
                            <td>11</td>
                            <td>80px</td>
                        </tr>
                        <tr>
                            <td>$spacer-12</td>
                            <td>12</td>
                            <td>88px</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>These variables are encouraged to be used within components and custom CSS. Margin and padding utilities also use the spacing scale.</p>
          </div>
          <div class="design-system__page-section">
            <h2 id="naming-convention" class="anchor-link" tabindex="-1">Utilities Naming Convention</h2>
            <p>The naming convention for spacing utilities was constructed with the goal of being both semantic and easy to remember. For uniform margins and padding, the location is omitted from the name.</p>
            <table class="border">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Location</th>
                  <th>Size</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>margin</td>
                  <td>top, right, bottom, left, x (horizontal), y (vertical)</td>
                  <td>0 - 12 and auto</td>
                </tr>
                <tr>
                  <td>padding</td>
                  <td>top, right, bottom, left, x (horizontal), y (vertical)</td>
                  <td>0 - 12 and auto</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="design-system__page-section">
              <h2 id="margin" class="anchor-link" tabindex="-1">Margin</h2>
              <p>Based on a global spacing scale which helps keep horizontal and vertical spacing consistent, margin utilities help reduce the amount of custom CSS that share the same properties and allows for many different page layouts using the same styles.</p>
              <h3>Uniform Margins</h3>
              <p>Uniform spacing utilities are used to apply equal margins to all sides of an element, can change or override default margins and can be used with a spacing scale from 0-12.</p>
              <div class="example__container margin-bottom-6">
                  <div class="example__demo">
                    <div class="example__demo-section">
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-0</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-1">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-1</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-2">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-2</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-3">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-4">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-4</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-5">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-5</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-6">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-6</div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='background-orange-primary-40'>
    <div class='margin-0 padding-1 background-gray-light-30 border'>.margin-0</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-1 padding-1 background-gray-light-30 border'>.margin-1</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-2 padding-1 background-gray-light-30 border'>.margin-2</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-3 padding-1 background-gray-light-30 border'>.margin-3</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-4 padding-1 background-gray-light-30 border'>.margin-4</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-5 padding-1 background-gray-light-30 border'>.margin-5</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-6 padding-1 background-gray-light-30 border'>.margin-6</div>
</div>" language="markup"></prism-editor></pre>
                  </div>
                </div>
              <h3>Directional Margins</h3>
              <p>Directional utilities are used to apply margin to an individual side or the X and Y axis of an element, can change or override default margins, and can be used with a spacing scale of 0-12.</p>
              <div class="example__container margin-bottom-6">
                <div class="example__demo">
                  <div class="example__demo-section">
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-top-3">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-top-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-right-3">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-right-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-bottom-3">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-bottom-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-left-3">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-left-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-y-3">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-y-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-orange-primary-40 padding-x-3">
                              <div class="font-size-10 background-gray-light-30 padding-1 border">.margin-x-3</div>
                          </div>
                        </div>
                  </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='background-orange-primary-40'>
    <div class='margin-top-3 padding-1 background-gray-light-30 border'>.margin-top-3</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-right-3 padding-1 background-gray-light-30 border'>.margin-right-3</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-bottom-3 padding-1 background-gray-light-30 border'>.margin-bottom-3</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-left-3 padding-1 background-gray-light-30 border'>.margin-left-3</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-y-3 padding-1 background-gray-light-30 border'>.margin-y-3</div>
</div>
<div class='background-orange-primary-40'>
    <div class='margin-x-3 padding-1 background-gray-light-30 border'>.margin-x-3</div>
</div>" language="markup"></prism-editor></pre>
                  </div>
              </div>
              <h3>Center Elements</h3>
              <p>Use <span><code class="code-highlight">.margin-x-auto</code></span> to center block elements with a set width.</p>
              <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <div class="background-orange-primary-40 full-width">
                      <div class="background-gray-light-30 margin-x-auto text-align-center" style="width:125px">.margin-x-auto</div>
                    </div>
                  </div>
                </div>
                <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='background-orange-primary-40 full-width' style='width:125px'>
  <div class='margin-x-auto background-gray-light-30 text-align-center'>.margin-x-auto</div>
</div>" language="markup"></prism-editor></pre>
                  </div>
              </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="responsive-margins" class="anchor-link" tabindex="-1">Responsive Margins</h2>
            <p>The Digital Design System provides a responsive version of all the margin class names.
              Each class name can be made responsive by simply adding a design system breakpoint value to the end of the class name.
              The table below illustrates the class name structure and behavior.</p>
            <table role="table" class="border margin-bottom-6 table-simple">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader">Type</th>
                  <th role="columnheader">Direction</th>
                  <th role="columnheader">Size</th>
                  <th role="columnheader">Breakpoint</th>
                  <th role="columnheader">Class</th>
                  <th role="columnheader">Behavior</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">margin</div>
                  </td>
                  <td role="cell"></td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">xs</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.margin-3-xs</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Uniform margin of 16px for all viewports</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">margin</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">top</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">sm</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.margin-top-3-sm</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Top margin of 16px for viewports 544px and up</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">margin</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">bottom</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">md</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.margin-bottom-3-md</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Bottom margin of 16px for viewports 768px and up</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">margin</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">left</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">lg</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.margin-left-3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Left margin of 16px for viewports 1012px and up</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">margin</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">right</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">xl</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.margin-right-3-xl</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Right margin of 16px for viewports 1280px and up</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">margin</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">x</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">xxl</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.margin-x-3-xxl</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Right and left margin of 16px for viewports 1366px and up</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="example__container">
              <div class="example__demo">
                <div class="example__demo-section">
                  <div class="flex flex-grow-1">
                    <div class="background-orange-primary-40 flex flex-grow-1 border">
                        <div class="font-size-10 background-gray-light-30 margin-2-xs margin-3-md margin-6-xxl padding-1 flex flex-grow-1">
                          8px margin for viewports up to 767px<br>
                          16px margin for viewports between 768px and 1365px<br>
                          32px margin for viewports 1366px and larger
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='flex flex-grow-1'>
  <div class='background-orange-primary-40 flex flex-grow-1 border'>
      <div class='font-size-10 background-gray-light-30 margin-2-xs margin-3-md margin-6-xxl padding-1 flex flex-grow-1'>
        8px margin for viewports up to 767px<br>
        16px margin for viewports between 768px and 1365px<br>
        32px margin for viewports 1366px and larger
      </div>
  </div>
</div>"></prism-editor></pre>
              </div>
            </div>
          </div>
          <div class="design-system__page-section">
              <h2 id="padding" class="anchor-link" tabindex="-1">Padding</h2>
              <p>Based on a global spacing scale which helps keep horizontal and vertical spacing consistent, padding utilities help reduce the amount of custom CSS that could share the same properties and allows for many different page layouts using the same styles.</p>
              <h3>Uniform Padding</h3>
              <p>Uniform spacing utilities are used to apply equal padding to all sides of an element and can be used with a spacing scale from 0-12.</p>
              <div class="example__container">
                  <div class="example__demo">
                    <div class="example__demo-section">
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-0</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-1 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-1</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-2 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-2</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-3 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-4 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-4</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-5 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-5</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-6 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-6</div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='background-blue-light-50 padding-0 border'>
    <div class='padding-1 background-gray-light-30'>.padding-0</div>
</div>
<div class='background-blue-light-50 padding-1 border'>
    <div class='padding-1 background-gray-light-30'>.padding-1</div>
</div>
<div class='background-blue-light-50 padding-2 border'>
    <div class='padding-1 background-gray-light-30'>.padding-2</div>
</div>
<div class='background-blue-light-50 padding-3 border'>
    <div class='padding-1 background-gray-light-30'>.padding-3</div>
</div>
<div class='background-blue-light-50 padding-4 border'>
    <div class='padding-1 background-gray-light-30'>.padding-4</div>
</div>
<div class='background-blue-light-50 padding-5 border'>
    <div class='padding-1 background-gray-light-30'>.padding-5</div>
</div>
<div class='background-blue-light-50 padding-6 border'>
    <div class='padding-1 background-gray-light-30'>.padding-6</div>
</div>" language="markup"></prism-editor></pre>
                  </div>
                </div>
              <h3>Directional Padding</h3>
              <p>Directional utilities are used to apply padding to an individual side or the X and Y axis of an element, can change or override default padding, and can be used with a spacing scale of 0-12.</p>
              <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-top-3 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-top-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-right-3 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-right-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-bottom-3 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-bottom-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-left-3 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-left-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-y-3 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-y-3</div>
                          </div>
                        </div>
                        <div class="margin-right margin-bottom">
                          <div class="background-blue-light-50 padding-x-3 border">
                              <div class="font-size-10 background-gray-light-30 padding-1">.padding-x-3</div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<div class='background-blue-light-50 padding-top-3 border'>
  <div class='padding-1 background-gray-light-30'>.padding-top-3</div>
</div>
<div class='background-blue-light-50 padding-right-3 border'>
  <div class='padding-1 background-gray-light-30'>.padding-right-3</div>
</div>
<div class='background-blue-light-50 padding-bottom-3 border'>
  <div class='padding-1 background-gray-light-30'>.padding-bottom-3</div>
</div>
<div class='background-blue-light-50 padding-left-3 border'>
  <div class='padding-1 background-gray-light-30'>.padding-left-3</div>
</div>
<div class='background-blue-light-50 padding-y-3 border'>
  <div class='padding-1 background-gray-light-30'>.padding-y-3</div>
</div>
<div class='background-blue-light-50 padding-x-3 border'>
  <div class='padding-1 background-gray-light-30'>.padding-x-3</div>
</div>" language="markup"></prism-editor></pre>
                  </div>
              </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="responsive-padding" class="anchor-link" tabindex="-1">Responsive Padding</h2>
            <p>The Digital Design System provides a responsive version of all the padding class names.
              Each class name can be made responsive by simply adding a design system breakpoint value to the end of the class name.
              The table below illustrates the class name structure and behavior.</p>
            <table role="table" class="border margin-bottom-6 table-simple">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader">Type</th>
                  <th role="columnheader">Direction</th>
                  <th role="columnheader">Size</th>
                  <th role="columnheader">Breakpoint</th>
                  <th role="columnheader">Class</th>
                  <th role="columnheader">Behavior</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">padding</div>
                  </td>
                  <td role="cell"></td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">xs</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.padding-3-xs</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Uniform padding of 16px for all viewports</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">padding</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">top</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">sm</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.padding-top-3-sm</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Top padding of 16px for viewports 544px and up</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">padding</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">bottom</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">md</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.padding-bottom-3-md</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Bottom padding of 16px for viewports 768px and up</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">padding</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">left</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">lg</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.padding-left-3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Left padding of 16px for viewports 1012px and up</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">padding</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">right</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">xl</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.padding-right-3-xl</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Right padding of 16px for viewports 1280px and up</div>
                  </td>
                </tr>
                <tr role="row">
                  <td role="cell">
                    <div class="mobile-th">Type</div>
                    <div class="mobile-td">padding</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Direction</div>
                    <div class="mobile-td">x</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Size</div>
                    <div class="mobile-td">3</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Breakpoint</div>
                    <div class="mobile-td">xxl</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Class</div>
                    <div class="mobile-td">.padding-x-3-xxl</div>
                  </td>
                  <td role="cell">
                    <div class="mobile-th">Behavior</div>
                    <div class="mobile-td">Right and left padding of 16px for viewports 1366px and up</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="example__container">
              <div class="example__demo">
                <div class="example__demo-section">
                  <div class="flex flex-grow-1">
                    <div class="background-blue-light-50 flex flex-grow-1 padding-2-xs padding-3-md padding-6-xxl border">
                        <div class="font-size-10 background-gray-light-30 padding-1 flex flex-grow-1">
                          8px padding for viewports up to 767px<br>
                          16px padding for viewports between 768px and 1365px<br>
                          32px padding for viewports 1366px and larger
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='flex flex-grow-1'>
  <div class='background-blue-light-50 flex flex-grow-1 padding-2-xs padding-3-md padding-6-xxl border'>
      <div class='font-size-10 background-gray-light-30 padding-1 flex flex-grow-1'>
        8px margin for viewports up to 767px<br>
        16px margin for viewports between 768px and 1365px<br>
        32px margin for viewports 1366px and larger
      </div>
  </div>
</div>"></prism-editor></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import PageTemplate from '../../components/Base/page-template.vue';
import DesignSidebar from '../../components/Sidebars/design-sidebar.vue';


export default {
  name: 'Typography',
  components: {
    PageTemplate,
    DesignSidebar,
    PrismEditor,
  },
};
</script>

<style lang="scss">
  .design {
    height: 100%;
    &__content {
      padding: $spacer-4;
    }
  }
</style>
