<!-- eslint-disable max-len -->
<template>
  <div class="design">
    <page-template>
      <div slot="sidebar">
        <design-sidebar />
      </div>
      <div slot="content">
        <div class="design-system__page-header">
          <h1>Typography</h1>
        </div>
        <div class="design-system__page-content">
          <div class="design-system__page-section">
            <h2 id="usage" class="anchor-link" tabindex="-1">Usage</h2>
            <p>Sherman, the official brand font for Syracuse University, features two primary categories within its family: Sherman Serif and Sherman Sans. Both include book and bold styles, as well as italics.</p>
            <p>As a bolder, fresher and more welcoming font, Sherman Sans should be used prominently in University communications. Sherman Serif plays a supporting role and can be used in contexts where the academic strength and gravitas of Syracuse University needs emphasizing.</p>
            <p>When representing Syracuse University, no other typeface should be used in proximity to or in combination with these fonts, unless approved.</p>
            <!--<h3>CDN</h3>
            <p>Coming Soon...</p>-->
          </div>
          <div class="design-system__page-section">
            <h2 id="fonts" class="anchor-link" tabindex="-1">Fonts</h2>
            <fonts />
          </div>
          <div class="design-system__page-section">
            <h2 id="type-scale" class="anchor-link" tabindex="-1">Type Scale and Utilities</h2>
            <type-scale />
          </div>
          <div class="design-system__page-section">
            <h2 id="headings" class="anchor-link" tabindex="-1">Headings</h2>
            <div class="design-system__typography-section">
              <h3>Heading Display Styles</h3>
              <p>These heading styles are intended for more stylized headings which are often used for banners and editorial content.</p>
              <p>The class <code class="code-highlight">.heading-display-h1</code> is styled specifically with the primary orange color and is reserved for titles for pages on the Syracuse University flagship site.</p>
              <div class="example__container margin-bottom-3">
                <div class="example__demo">
                  <div>
                    <h1 class='heading-display-jumbo'>h1 Display Jumbo</h1>
                    <h1 class='heading-display-xl'>h1 Display XL</h1>
                    <h1 class='heading-display-lg'>h1 Display LG</h1>
                    <h1 class='heading-display-h1'>h1 Display</h1>
                    <h2 class='heading-display-h2'>h2 Display</h2>
                    <h3 class='heading-display-h3'>h3 Display</h3>
                    <h4 class='heading-display-h4'>h4 Display</h4>
                    <h5 class='heading-display-h5'>h5 Display</h5>
                    <h6 class='heading-display-h6'>h6 Display</h6>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<h1 class='heading-display-h1'>h1 Display</h1>
<h2 class='heading-display-h2'>h2 Display</h2>
<h3 class='heading-display-h3'>h3 Display</h3>
<h4 class='heading-display-h4'>h4 Display</h4>
<h5 class='heading-display-h5'>h5 Display</h5>
<h6 class='heading-display-h6'>h6 Display</h6>" language="markup"></prism-editor></pre>
                </div>
              </div>
              <h3>Heading Utilities</h3>
              <p>Use <code class="code-highlight">.heading-size-h1</code> – <code class="code-highlight">.heading-size-h6</code> to change an elements font size and weight to match heading styles.</p>
              <div class="example__container">
                <div class="example__demo">
                  <p v-for="heading in headingUtilities" :key="heading.headingSize" :class="heading.className">
                    {{ heading.headingSize }}
                  </p>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<p class='heading-size-h1'>h1 size</p>
<p class='heading-size-h2'>h2 size</p>
<p class='heading-size-h3'>h3 size</p>
<p class='heading-size-h4'>h4 size</p>
<p class='heading-size-h5'>h5 size</p>
<p class='heading-size-h6'>h6 size</p>" language="markup"></prism-editor></pre>
                </div>
              </div>
              <div class="example__container">
                <div class="example__demo">
                  <h2 class="heading-size-h1">Looks Like an h1, Semantically an h2</h2>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<h2 class='heading-size-h1'>Looks Like an h1, Semantically an h2</h2>" language="markup"></prism-editor></pre>
                </div>
              </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="paragraph-styles" class="anchor-link" tabindex="-1">Paragraph Styles</h2>
            <paragraph-styles />
          </div>
          <div class="design-system__page-section">
            <h2 id="links" class="anchor-link" tabindex="-1">Links</h2>
            <h3>Heading Link</h3>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <div>
                      <h3><a href="#">Heading With A Link</a></h3>
                      <p>Lorem ipsum dolor sit amet, <a href="#">consectetur adipiscing elit</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.</p>
                    </div>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<h3><a href='#'>Heading With A Link</a></h3>
<p>Lorem ipsum dolor sit amet, <a href='#'>consectetur adipiscing elit</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.</p>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <h3>Paragraph Links</h3>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <p>Lorem ipsum dolor sit amet, <a href="#">consectetur adipiscing elit</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.</p>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<p>Lorem ipsum dolor sit amet, <a href='#'>consectetur adipiscing elit</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis posuere morbi leo urna molestie at elementum.</p>" language="markup"></prism-editor></pre>
                </div>
            </div>
          </div>
          <div class="design-system__page-section">
            <h2 id="lists" class="anchor-link" tabindex="-1">Lists</h2>
            <lists />
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import { mapState } from 'vuex';
import PageTemplate from '../../../components/Base/page-template.vue';
import DesignSidebar from '../../../components/Sidebars/design-sidebar.vue';
import TypeScale from './TypeScale.vue';
import Fonts from './Fonts.vue';
import Lists from './Lists.vue';
import ParagraphStyles from './ParagraphStyles.vue';

export default {
  name: 'Typography',
  components: {
    PageTemplate,
    DesignSidebar,
    TypeScale,
    Fonts,
    ParagraphStyles,
    Lists,
    PrismEditor,
  },
  computed: {
    ...mapState('typography', ['headingUtilities', 'displayHeadings']),
  },
};
</script>

<style lang="scss">
  .design {
    height: 100%;
    &__content {
      padding: $spacer-4;
    }
  }
</style>
