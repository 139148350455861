const state = {
  headingUtilities: [
    {
      headingSize: 'h1 size',
      className: 'heading-size-h1',
      codeExample: '<p class="heading-size-h1">h1 size</p>',
    },
    {
      headingSize: 'h2 size',
      className: 'heading-size-h2',
      codeExample: '<p class="heading-size-h2">h2 size</p>',
    },
    {
      headingSize: 'h3 size',
      className: 'heading-size-h3',
      codeExample: '<p class="heading-size-h3">h3 size</p>',
    },
    {
      headingSize: 'h4 size',
      className: 'heading-size-h4',
      codeExample: '<p class="heading-size-h4">h4 size</p>',
    },
    {
      headingSize: 'h5 size',
      className: 'heading-size-h5',
      codeExample: '<p class="heading-size-h5">h5 size</p>',
    },
    {
      headingSize: 'h6 size',
      className: 'heading-size-h6',
      codeExample: '<p class="heading-size-h6">h6 size</p>',
    },
  ],
  displayHeadings: [
    {
      headingName: 'h1 Display',
      className: 'heading-display-h1',
      codeExample: '<h1 class="heading-display-h1">h1 Display</h1>',
    },
    {
      headingName: 'h2 Display',
      className: 'heading-display-h2',
      codeExample: '<h2 class="heading-display-h2">h2 Display</h2>',
    },
    {
      headingName: 'h3 Display',
      className: 'heading-display-h3',
      codeExample: '<h3 class="heading-display-h3">h3 Display</h3>',
    },
    {
      headingName: 'h4 Display',
      className: 'heading-display-h4',
      codeExample: '<h4 class="heading-display-h4">h4 Display</h4>',
    },
    {
      headingName: 'h5 Display',
      className: 'heading-display-h5',
      codeExample: '<h5 class="heading-display-h5">h5 Display</h5>',
    },
    {
      headingName: 'h6 Display',
      className: 'heading-display-h6',
      codeExample: '<h6 class="heading-display-h6">h6 Display</h6>',
    },
  ],
};

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
