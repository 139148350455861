<template>
    <div>
        <header-generic />
    </div>
</template>

<script>
import headerGeneric from '../../components/Components/headers/header-generic.vue';

export default {
  name: 'generic-header',
  components: { headerGeneric },
};
</script>
