<!-- eslint-disable max-len -->
<template>
    <div class="margin-top-6">
        <h2 id="row-span" class="anchor-link" tabindex="-1">Spanning Grid Rows</h2>
        <p>Spanning rows is very much the same as spanning columns. To have a child element span multiple rows, add a class of <code class="code-highlight">.grid-rows-span-{x}</code> to the child, with x being the number of rows you wish to span.</p>
        <div class="margin-y-4">
            <div class="grid grid-rows-3 grid-auto-flow-column grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4">
                <div class="grid-rows-span-3 background-blue-medium padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">1</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">2</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">3</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">4</span>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-rows-3 grid-auto-flow-column grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4'>
    <div class='grid-rows-span-3 background-blue-medium padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>1</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>2</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>3</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>4</span>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
