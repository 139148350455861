<!-- eslint-disable max-len -->
<template>
    <div>
        <div class="design-system__typography-section">
            <h3>Unordered List</h3>
            <p>Unordered lists do not use a class and just rely on the default styling. The list-style-type is defaulted to square.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <ul>
                        <li>Academic Calendar</li>
                        <li>Campus Map</li>
                        <li>Careers</li>
                    </ul>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<ul>
    <li>Academic Calendar</li>
    <li>Campus Map</li>
    <li>Careers</li>
</ul>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <h3>Ordered List</h3>
            <p>Ordered lists also do not use a class and just rely on the default styling.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <ol>
                        <li>Academic Calendar</li>
                        <li>Campus Map</li>
                        <li>Careers</li>
                    </ol>
                  </div>
                </div>
                <div class="example__code">
                    <pre contenteditable="true"><prism-editor code="<ol>
  <li>Academic Calendar</li>
  <li>Campus Map</li>
  <li>Careers</li>
</ol>" language="markup"></prism-editor></pre>
                </div>
            </div>
            <h3>Menu List</h3>
            <p>A styled list for sidebar menus.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <ul class="list-menu">
                        <li><a href="#">Academic Calendar</a></li>
                        <li><a href="#">Campus Map</a></li>
                        <li><a href="#">Careers</a></li>
                    </ul>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<ul class='list-menu'>
    <li><a href='#'>Academic Calendar</a></li>
    <li><a href='#'>Campus Map</a></li>
    <li><a href='#'>Careers</a></li>
</ul>" language="markup"></prism-editor></pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  components: {
    PrismEditor,
  },
};
</script>
