<!-- eslint-disable max-len -->
<template>
    <div>
        <h2 id="grid-columns" class="anchor-link" tabindex="-1">Grid Columns</h2>
        <p>To organize your grid into columns the <code class="code-highlight">.grid-columns-{x}</code> class will need to be added to the parent container.</p>
        <p>Much like the flexbox grid, the css grid is also based on a 12 column system.
            To define the number of columns you desire you would add a number - from 1 through 12 - to the <code class="code-highlight">.grid-columns-{x}</code> class.</p>
        <p>In the following example the class <code class="code-highlight">.grid-columns-3</code> will give 3 equally sized columns to the grid.</p>
        <div class="margin-y-4">
            <div class="grid grid-columns-3 grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4">
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">1</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">2</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">3</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">4</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">5</span>
                </div>
                <div class="background-blue-primary padding-4 text-white text-align-center">
                    <span class="is-bold font-size-8">6</span>
                </div>
            </div>
        </div>
        <div class="example__code border margin-bottom-4">
            <pre contenteditable="true"><prism-editor code="<div class='grid grid-columns-3 grid-columns-gap-3 grid-rows-gap-3 background-blue-primary-10 padding-4'>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>1</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>2</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>3</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>4</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>5</span>
    </div>
    <div class='background-blue-primary padding-4 text-white text-align-center'>
        <span class='is-bold font-size-8'>6</span>
    </div>
</div>" language="markup"></prism-editor></pre>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'flexbox',
  components: {
    PrismEditor,
  },
};
</script>
