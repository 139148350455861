<!-- eslint-disable max-len -->
<template>
    <div>
        <p>The above values are defined as variables and then put into a Sass map that generates the media query mixin.</p>
      <pre contenteditable="true"><prism-editor code="//breakpoints
$width-xs: 0;
$width-sm: 544px;
$width-md: 768px;
$width-lg: 1012px;
$width-xl: 1280px;

$breakpoints: (
  // Small screen / phone
    sm: $width-sm,
  // Medium screen / tablet
    md: $width-md,
  // Large screen / desktop (980 + (12 * 2)) container + gutters
    lg: $width-lg,
  // Extra large screen / wide desktop
    xl: $width-xl
) !default;" language="scss"></prism-editor></pre>
      <h3 class="margin-top-3">Media Query Mixins</h3>
      <p>These allow you to change CSS properties at a particular breakpoint and work by passing in a breakpoint value, such as breakpoint(md).</p>
      <p>Media queries are scoped from each breakpoint and upwards.
        In the example below, the font size remains 28px until the viewport size meets the md breakpoint. From there upwards&mdash;including through the xl breakpoint&mdash;the font size changes to 32px.</p>
      <pre contenteditable="true"><prism-editor code=".styles {
    font-size: 28px;
    @include breakpoint(md) {
      font-size: 32px;
    }
  }" language="scss"></prism-editor></pre>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  components: {
    PrismEditor,
  },
};
</script>
