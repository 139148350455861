<!-- eslint-disable max-len -->
<template>
    <prototype-template>
        <div slot="content">
            <div class="container-lg padding-left-3 padding-right-3">
                <search-form :programs="programs"/>
            </div>
        </div>
    </prototype-template>
</template>

<script>
import PrototypeTemplate from '../../../components/Base/prototype-template.vue';
import SearchForm from '../../../components/Components/forms/search-form.vue';

export default {
  name: 'flexbox-prototype',
  components: {
    PrototypeTemplate,
    SearchForm,
  },
  data() {
    return {
      programs: [
        {
          programName: 'Communications',
          programId: '1',
        },
        {
          programName: 'Computer Science',
          programId: '2',
        },
      ],
    };
  },
};
</script>
