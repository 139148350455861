<!-- eslint-disable max-len -->
<template>
    <div>
        <div class="design-system__typography-section">
            <div class="design-system__font-families">
                <span class="is-bold">Sherman Sans Bold</span>
                <div class="design-system__font-code">
                  <pre contenteditable="true"><prism-editor code="@font-face {
    font-family: 'ShermanSans';
    src: url('https://assets.syracuse.edu/fonts/shermansans-bold.woff2') format('woff2'),
     url('https://assets.syracuse.edu/fonts/shermansans-bold.woff') format('woff'),
     url('https://assets.syracuse.edu/fonts/shermansans-bold.eot') format('embedded-opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0020-007F, U+00A9, U+00AE, U+2013-201D;
}

@font-face {
    font-family: 'ShermanSans';
    src: url('https://assets.syracuse.edu/fonts/shermansans-bold-intl.woff2') format('woff2'),
     url('https://assets.syracuse.edu/fonts/shermansans-bold-intl.woff') format('woff'),
     url('https://assets.syracuse.edu/fonts/shermansans-bold-intl.eot') format('embedded-opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    unicode-range: U+00A0-00A8, U+00C0-024F, U+201E-2026;
}

font-family: ShermanSans, Verdana, sans-serif;
font-weight: 700;" language="css"></prism-editor></pre>
                </div>
            </div>
            <div class="design-system__font-families">
                <span>Sherman Sans Book</span>
                <div class="design-system__font-code">
                  <pre contenteditable="true"><prism-editor code="@font-face {
  font-family: 'ShermanSans';
  src: url('https://assets.syracuse.edu/fonts/shermansans-book.woff2') format('woff2'),
    url('https://assets.syracuse.edu/fonts/shermansans-book.woff') format('woff'),
    url('https://assets.syracuse.edu/fonts/shermansans-book.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007F, U+00A9, U+00AE, U+2013-201D;
}

@font-face {
  font-family: 'ShermanSans';
  src: url('https://assets.syracuse.edu/fonts/shermansans-book-intl.woff2') format('woff2'),
    url('https://assets.syracuse.edu/fonts/shermansans-book-intl.woff') format('woff'),
    url('https://assets.syracuse.edu/fonts/shermansans-book-intl.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00A0-00A8, U+00C0-024F, U+201E-2026;
}

font-family: ShermanSans, Verdana, sans-serif;
font-weight: 500;" language="css"></prism-editor></pre>
                </div>
            </div>
            <div class="design-system__font-families">
                <span class="is-serif is-bold">Sherman Serif Bold</span>
                <div class="design-system__font-code">
                  <pre contenteditable="true"><prism-editor code="@font-face {
  font-family: 'ShermanSerif';
  src: url('https://assets.syracuse.edu/fonts/shermanserif-bold.woff2') format('woff2'),
    url('https://assets.syracuse.edu/fonts/shermanserif-bold.woff') format('woff'),
    url('https://assets.syracuse.edu/fonts/shermanserif-bold.eot') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007F, U+00A9, U+00AE, U+2013-201D;
}

@font-face {
    font-family: 'ShermanSerif';
    src: url('https://assets.syracuse.edu/fonts/shermanserif-bold-intl.woff2') format('woff2'),
        url('https://assets.syracuse.edu/fonts/shermanserif-bold-intl.woff') format('woff'),
        url('https://assets.syracuse.edu/fonts/shermanserif-bold-intl.eot') format('embedded-opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    unicode-range: U+00A0-00A8, U+00C0-024F, U+201E-2026;
}

font-family: ShermanSerif, Georgia, serif;
font-weight: 700;" language="css"></prism-editor></pre>
                </div>
            </div>
            <div class="design-system__font-families">
                <span class="is-serif">Sherman Serif Book</span>
                <div class="design-system__font-code">
                    <pre contenteditable="true"><prism-editor code="@font-face {
  font-family: 'ShermanSerif';
  src: url('https://assets.syracuse.edu/fonts/shermanserif-book.woff2') format('woff2'),
    url('https://assets.syracuse.edu/fonts/shermanserif-book.woff') format('woff'),
    url('https://assets.syracuse.edu/fonts/shermanserif-book.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007F, U+00A9, U+00AE, U+2013-201D;
}

@font-face {
    font-family: 'ShermanSerif';
    src: url('https://assets.syracuse.edu/fonts/shermanserif-book-intl.woff2') format('woff2'),
     url('https://assets.syracuse.edu/fonts/shermanserif-book-intl.woff') format('woff'),
     url('https://assets.syracuse.edu/fonts/shermanserif-book-intl.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    unicode-range: U+00A0-00A8, U+00C0-024F, U+201E-2026;
}

font-family: ShermanSerif, Georgia, serif;
font-weight: 500;" language="css"></prism-editor></pre>
                </div>
            </div>
        </div>
        <div class="design-system__typography-section">
            <h3>Font Utilities</h3>
            <p>There are a few font utilities available to adjust the font family, font weight and casing.</p>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <span class="is-bold">My Text is Bold</span>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<span class='is-bold'>My Text is Bold</span>" language="markup"></prism-editor>
                  <prism-editor code=".is-bold {
  font-weight: bold;
}" language="css"></prism-editor></pre>
                </div>
            </div>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <span class="is-serif">My Text is Serif</span>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<span class='is-serif'>My Text is Serif</span>" language="markup"></prism-editor>
                  <prism-editor code=".is-serif {
    font-family: ShermanSerif, Georgia, serif;
}" language="css"></prism-editor></pre>
                </div>
            </div>
            <div class="example__container">
                <div class="example__demo">
                  <div class="example__demo-section">
                    <span class="is-caps">My Text is Capitalized</span>
                  </div>
                </div>
                <div class="example__code">
                  <pre contenteditable="true"><prism-editor code="<span class='is-caps'>My Text is Capitalized</span>" language="markup"></prism-editor>
                  <prism-editor code=".is-caps {
    text-transform: uppercase;
    word-spacing: .15rem;
    letter-spacing: .05rem;
}" language="css"></prism-editor></pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  components: {
    PrismEditor,
  },
};
</script>
