const state = {
  iconList: [
    {
      iconName: 'arrow-down',
      iconClass: 'icon-arrow-down',
      iconCategory: '',
    },
    {
      iconName: 'arrow-left',
      iconClass: 'icon-arrow-left',
      iconCategory: '',
    },
    {
      iconName: 'arrow-right',
      iconClass: 'icon-arrow-right',
      iconCategory: '',
    },
    {
      iconName: 'arrow-up',
      iconClass: 'icon-arrow-up',
      iconCategory: '',
    },
    {
      iconName: 'award-solid',
      iconClass: 'icon-award-solid',
      iconCategory: '',
    },
    {
      iconName: 'basketball',
      iconClass: 'icon-basketball',
      iconCategory: '',
    },
    {
      iconName: 'bell open',
      iconClass: 'icon-bell-open',
      iconCategory: '',
    },
    {
      iconName: 'brush solid',
      iconClass: 'icon-brush-solid',
      iconCategory: '',
    },
    {
      iconName: 'calendar',
      iconClass: 'icon-calendar',
      iconCategory: '',
    },
    {
      iconName: 'chart open',
      iconClass: 'icon-chart-open',
      iconCategory: '',
    },
    {
      iconName: 'checkmark',
      iconClass: 'icon-checkmark',
      iconCategory: '',
    },
    {
      iconName: 'check circle',
      iconClass: 'icon-check-circle',
      iconCategory: '',
    },
    {
      iconName: 'chevron up',
      iconClass: 'icon-chevron-up',
      iconCategory: '',
    },
    {
      iconName: 'chevron down',
      iconClass: 'icon-chevron-down',
      iconCategory: '',
    },
    {
      iconName: 'chevron left',
      iconClass: 'icon-chevron-left',
      iconCategory: '',
    },
    {
      iconName: 'chevron right',
      iconClass: 'icon-chevron-right',
      iconCategory: '',
    },
    {
      iconName: 'clipboard-checked',
      iconClass: 'icon-clipboard-checked',
      iconCategory: '',
    },
    {
      iconName: 'clock',
      iconClass: 'icon-clock',
      iconCategory: '',
    },
    {
      iconName: 'cog open',
      iconClass: 'icon-cog-open',
      iconCategory: '',
    },
    {
      iconName: 'cog solid',
      iconClass: 'icon-cog-solid',
      iconCategory: '',
    },
    {
      iconName: 'comment active',
      iconClass: 'icon-comment-active',
      iconCategory: '',
    },
    {
      iconName: 'cookie',
      iconClass: 'icon-cookie',
      iconCategory: '',
    },
    {
      iconName: 'dashboard',
      iconClass: 'icon-dashboard',
      iconCategory: '',
    },
    {
      iconName: 'desktop solid',
      iconClass: 'icon-desktop-solid',
      iconCategory: '',
    },
    {
      iconName: 'disk',
      iconClass: 'icon-disk',
      iconCategory: '',
    },
    {
      iconName: 'download',
      iconClass: 'icon-download',
      iconCategory: '',
    },
    {
      iconName: 'exclamation circle',
      iconClass: 'icon-exclamation-circle',
      iconCategory: '',
    },
    {
      iconName: 'exclamation circle solid',
      iconClass: 'icon-exclamation-circle-solid',
      iconCategory: '',
    },
    {
      iconName: 'exclamation point',
      iconClass: 'icon-exclamation-point',
      iconCategory: '',
    },
    {
      iconName: 'external-link',
      iconClass: 'icon-external-link',
      iconCategory: '',
    },
    {
      iconName: 'eye',
      iconClass: 'icon-eye',
      iconCategory: '',
    },
    {
      iconName: 'facebook',
      iconClass: 'icon-facebook',
      iconCategory: '',
    },
    {
      iconName: 'file open',
      iconClass: 'icon-file-open',
      iconCategory: '',
    },
    {
      iconName: 'file solid',
      iconClass: 'icon-file-solid',
      iconCategory: '',
    },
    {
      iconName: 'folder solid',
      iconClass: 'icon-folder-solid',
      iconCategory: '',
    },
    {
      iconName: 'globe',
      iconClass: 'icon-globe',
      iconCategory: '',
    },
    {
      iconName: 'grad cap',
      iconClass: 'icon-grad-cap',
      iconCategory: '',
    },
    {
      iconName: 'hamburger',
      iconClass: 'icon-hamburger',
      iconCategory: '',
    },
    {
      iconName: 'home solid',
      iconClass: 'icon-home-solid',
      iconCategory: '',
    },
    {
      iconName: 'laptop code',
      iconClass: 'icon-laptop-code',
      iconCategory: '',
    },
    {
      iconName: 'mail',
      iconClass: 'icon-mail',
      iconCategory: '',
    },
    {
      iconName: 'map-marker',
      iconClass: 'icon-map-marker',
      iconCategory: '',
    },
    {
      iconName: 'network wired',
      iconClass: 'icon-network-wired',
      iconCategory: '',
    },
    {
      iconName: 'news',
      iconClass: 'icon-news',
      iconCategory: '',
    },
    {
      iconName: 'pages',
      iconClass: 'icon-pages',
      iconCategory: '',
    },
    {
      iconName: 'pause',
      iconClass: 'icon-pause',
      iconCategory: '',
    },
    {
      iconName: 'photo-video',
      iconClass: 'icon-photo-video',
      iconCategory: '',
    },
    {
      iconName: 'play',
      iconClass: 'icon-play',
      iconCategory: '',
    },
    {
      iconName: 'plus',
      iconClass: 'icon-plus',
      iconCategory: '',
    },
    {
      iconName: 'pencil',
      iconClass: 'icon-pencil',
      iconCategory: '',
    },
    {
      iconName: 'refresh',
      iconClass: 'icon-refresh',
      iconCategory: '',
    },
    {
      iconName: 'search',
      iconClass: 'icon-search',
      iconCategory: '',
    },
    {
      iconName: 'sign-out',
      iconClass: 'icon-sign-out',
      iconCategory: '',
    },
    {
      iconName: 'sliders-solid',
      iconClass: 'icon-sliders-solid',
      iconCategory: '',
    },
    {
      iconName: 'sliders-open',
      iconClass: 'icon-sliders-open',
      iconCategory: '',
    },
    {
      iconName: 'sliders-open-vertical',
      iconClass: 'icon-sliders-open-v',
      iconCategory: '',
    },
    {
      iconName: 'times',
      iconClass: 'icon-times',
      iconCategory: '',
    },
    {
      iconName: 'times-circle',
      iconClass: 'icon-times-circle',
      iconCategory: '',
    },
    {
      iconName: 'trash solid',
      iconClass: 'icon-trash-solid',
      iconCategory: '',
    },
    {
      iconName: 'trophy',
      iconClass: 'icon-trophy',
      iconCategory: '',
    },
    {
      iconName: 'trophy-solid',
      iconClass: 'icon-trophy-solid',
      iconCategory: '',
    },
    {
      iconName: 'twitter',
      iconClass: 'icon-twitter',
      iconCategory: '',
    },
    {
      iconName: 'twitter-square',
      iconClass: 'icon-twitter-square',
      iconCategory: '',
    },
    {
      iconName: 'user-solid',
      iconClass: 'icon-user-solid',
      iconCategory: '',
    },
  ],
  iconMenu: [
    {
      linkName: 'Icon Usage',
      linkLocation: 'icon-usage',
    },
    {
      linkName: 'Icon Library',
      linkLocation: 'icon-library',
    },
  ],
};

const mutations = {
};
const getters = {};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
