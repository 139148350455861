<!-- eslint-disable max-len -->
<template>
<div>
  <table role="table" class="table-multi-level">
    <thead role="rowgroup">
      <tr role="row">
        <th></th>
        <th role="columnheader" id="part-time">Part Time</th>
        <th role="columnheader" id="full-time">Full Time</th>
        <th role="columnheader" id="online">Online</th>
      </tr>
    </thead>
    <tbody>
      <tr role="row">
        <th class="column-span" colspan="4" id="undergrad">Undergraduate</th>
      </tr>
      <tr role="row">
        <th class="column-header" role="cell" id="chemistry">Chemistry</th>
        <td headers="part-time undergrad chemistry" role="cell">X</td>
        <td headers="full-time undergrad chemistry" role="cell">X</td>
        <td headers="online undergrad chemistry" role="cell"></td>
      </tr>
      <tr role="role">
        <th class="column-header" role="cell" id="computer-science">Computer Science</th>
        <td headers="part-time undergrad computer-science" role="cell">X</td>
        <td headers="full-time undergrad computer-science" role="cell">X</td>
        <td headers="online-time undergrad computer-science" role="cell">X</td>
      </tr>
      <tr>
        <th class="column-header" role="cell" id="cybersecurity-administration">Cybersecurity Administration</th>
        <td headers="part-time undergrad cybersecurity-administration" role="cell">X</td>
        <td headers="full-time undergrad cybersecurity-administration" role="cell">X</td>
        <td headers="online undergrad cybersecurity-administration" role="cell">X</td>
      </tr>
      <tr role="row">
        <th class="column-span" colspan="4" id="graduate">Graduate</th>
      </tr>
      <tr role="row">
        <th class="column-header" role="cell" id="chemical-engineering">Chemical Engineering</th>
        <td headers="part-time graduate chemical-engineering" role="cell">X</td>
        <td headers="full-time graduate chemical-engineering" role="cell">X</td>
        <td headers="online graduate chemical-engineering" role="cell"></td>
      </tr>
      <tr role="row">
        <th class="column-header" role="cell" id="civil-engineering">Civil Engineering</th>
        <td headers="part-time graduate civil-engineering" role="cell">X</td>
        <td headers="full-time graduate civil-engineering" role="cell">X</td>
        <td headers="online graduate civil-engineering" role="cell"></td>
      </tr>
      <tr role="row">
        <th class="column-header" role="cell" id="communications" attribute="">Communications</th>
        <td headers="part-time graduate communications" role="cell">X</td>
        <td headers="full-time graduate communications" role="cell">X</td>
        <td headers="online graduate communications" role="cell">X</td>
      </tr>
    </tbody>
  </table>
  <div class="example__code margin-top-5 border-1">
    <pre contenteditable="true"><prism-editor code="<table role='table' class='table-multi-level'>
  <thead role='rowgroup'>
    <tr role='row'>
      <th></th>
      <th role='columnheader' id='part-time'>Part Time</th>
      <th role='columnheader' id='full-time'>Full Time</th>
      <th role='columnheader' id='online'>Online</th>
    </tr>
  </thead>
  <tbody>
    <tr role='row'>
      <th class='column-span' colspan='4' id='undergrad'>Undergraduate</th>
    </tr>
    <tr role='row'>
      <th class='column-header' role='cell' id='chemistry'>Chemistry</th>
      <td headers='part-time undergrad chemistry' role='cell'>X</td>
      <td headers='full-time undergrad chemistry' role='cell'>X</td>
      <td headers='online undergrad chemistry' role='cell'></td>
    </tr>
    <tr role='role'>
      <th class='column-header' role='cell' id='computer-science'>Computer Science</th>
      <td headers='part-time undergrad computer-science' role='cell'>X</td>
      <td headers='full-time undergrad computer-science' role='cell'>X</td>
      <td headers='online-time undergrad computer-science' role='cell'>X</td>
    </tr>
    <tr>
      <th class='column-header' role='cell' id='cybersecurity-administration'>Cybersecurity Administration</th>
      <td headers='part-time undergrad cybersecurity-administration' role='cell'>X</td>
      <td headers='full-time undergrad cybersecurity-administration' role='cell'>X</td>
      <td headers='online undergrad cybersecurity-administration' role='cell'>X</td>
    </tr>
    <tr role='row'>
      <th class='column-span' colspan='4' id='graduate'>Graduate</th>
    </tr>
    <tr role='row'>
      <th class='column-header' role='cell' id='chemical-engineering'>Chemical Engineering</th>
      <td headers='part-time graduate chemical-engineering' role='cell'>X</td>
      <td headers='full-time graduate chemical-engineering' role='cell'>X</td>
      <td headers='online graduate chemical-engineering' role='cell'></td>
    </tr>
    <tr role='row'>
      <th class='column-header' role='cell' id='civil-engineering'>Civil Engineering</th>
      <td headers='part-time graduate civil-engineering' role='cell'>X</td>
      <td headers='full-time graduate civil-engineering' role='cell'>X</td>
      <td headers='online graduate civil-engineering' role='cell'></td>
    </tr>
    <tr role='row'>
      <th class='column-header' role='cell' id='communications' attribute=''>Communications</th>
      <td headers='part-time graduate communications' role='cell'>X</td>
      <td headers='full-time graduate communications' role='cell'>X</td>
      <td headers='online graduate communications' role='cell'>X</td>
    </tr>
  </tbody>
</table>" language="markup"></prism-editor></pre>
    </div>
  </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'type-scale',
  components: {
    PrismEditor,
  },
};
</script>
