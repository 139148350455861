<!-- eslint-disable max-len -->
<template>
    <div>
        <h3>Width Utilities</h3>
        <p>Width utilities in the digital design system are intended to be responsive - so all utilities avoid fixed widths.</p>
        <p>The following utility classes adjust width based on viewport widths or on a percentage.
            Width utilities can also be made further responsive by adding a responsive modifier to the class name, which will apply the style at the designated breakpoint.</p>
        <h3>Max-width utilities</h3>
        <p>You can constrain the width of an element by applying the below max-width utilities.</p>
        <p>Max-width utility classes are especially useful for form inputs, which should have their widths constrained to match the expected length of the information you’re asking someone to give you. For example, a zip code input is generally smaller than an address field.</p>
        <p class="is-bold">The width utility classes are intended for use when fine-tuning the appearance of individual elements on a page and should not be used for high-level page layout. To lay out pages, use the flexbox or CSS grid instead.</p>
        <table class="border">
            <thead>
                <tr>
                    <th width="25%">Class Name</th>
                    <th width="50%">Behavior</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>.max-width-sm</td>
                    <td>Max width of 544px</td>
                </tr>
                <tr>
                    <td>.max-width-md</td>
                    <td>Max width of 768px</td>
                </tr>
                <tr>
                    <td>.max-width-lg</td>
                    <td>Max width of 1012px</td>
                </tr>
                <tr>
                    <td>.max-width-xl</td>
                    <td>Max width of 1280px</td>
                </tr>
                <tr>
                    <td>.max-width-xxl</td>
                    <td>Max width of 1366px</td>
                </tr>
                <tr>
                    <td>.max-width-xxxl</td>
                    <td>Max width of 1640px</td>
                </tr>
            </tbody>
        </table>
        <div class="example__container margin-top-4">
            <div class="padding-3">
                <div class="max-width-sm text-white background-blue padding-3 margin-bottom-2">.max-width-sm</div>
                <div class="max-width-md text-white background-blue padding-3 margin-bottom-2">.max-width-md</div>
                <div class="max-width-lg text-white background-blue padding-3 margin-bottom-2">.max-width-lg</div>
                <div class="max-width-xl text-white background-blue padding-3 margin-bottom-2">.max-width-xl</div>
                <div class="max-width-xxl text-white background-blue padding-3 margin-bottom-2">.max-width-xxl</div>
                <div class="max-width-xxxl text-white background-blue padding-3 margin-bottom-2">.max-width-xxxl</div>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<div class='max-width-sm text-white background-blue padding-3 margin-bottom-2'>.max-width-sm</div>
<div class='max-width-md text-white background-blue padding-3 margin-bottom-2'>.max-width-md</div>
<div class='max-width-lg text-white background-blue padding-3 margin-bottom-2'>.max-width-lg</div>
<div class='max-width-xl text-white background-blue padding-3 margin-bottom-2'>.max-width-xl</div>
<div class='max-width-xxl text-white background-blue padding-3 margin-bottom-2'>.max-width-xxl</div>
<div class='max-width-xxxl text-white background-blue padding-3 margin-bottom-2'>.max-width-xxxl</div>" language="markup"></prism-editor></pre>
            </div>
        </div>
        <div class="margin-top-3">
            <h3>Setting Responsive Widths</h3>
            <p>Each utility class can be used responsively by adding a modifier to the end of the class name. Adding the modifier will specify at what screen size the max-width will take effect.</p>
            <div class="example__code margin-bottom-3">
                <pre contenteditable="true"><prism-editor code="<div class='max-width-md-lg'>
    <!-- markup -->
</div>" language="markup"></prism-editor></pre>
            </div>
            <p>In the above example, the div will have a max-width of 768px at viewports 1012px and up</p>
            <p>Below is a set of modifiers that can be added to the max-width utilities</p>
            <table class="border">
            <thead>
                <tr>
                    <th width="25%">Modifier</th>
                    <th width="50%">Behavior</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>-sm</td>
                    <td>Viewports 544px and up</td>
                </tr>
                <tr>
                    <td>-md</td>
                    <td>Viewports 768px and up</td>
                </tr>
                <tr>
                    <td>-lg</td>
                    <td>Viewports 1012px and up</td>
                </tr>
                <tr>
                    <td>-xl</td>
                    <td>Viewports 1280px and up</td>
                </tr>
                <tr>
                    <td>-xxl</td>
                    <td>Viewports 1366px and up</td>
                </tr>
                <tr>
                    <td>-xxxl</td>
                    <td>Viewports 1640px and up</td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'alignment',
  components: {
    PrismEditor,
  },
};
</script>
