const state = {
  directionalBorders: [
    {
      borderType: '.border-top',
      className: 'border-top',
      codeExample: '<div class="border-top padding-3 margin-right background-gray-light-20">.border-top</div>',
    },
    {
      borderType: '.border-right',
      className: 'border-right',
      codeExample: '<div class="border-right padding-3 margin-right background-gray-light-20">.border-right</div>',
    },
    {
      borderType: '.border-bottom',
      className: 'border-bottom',
      codeExample: '<div class="border-bottom padding-3 margin-right background-gray-light-20">.border-bottom</div>',
    },
    {
      borderType: '.border-left',
      className: 'border-left',
      codeExample: '<div class="border-left padding-3 margin-right background-gray-light-20">.border-left</div>',
    },
    {
      borderType: '.border-x',
      className: 'border-x',
      codeExample: '<div class="border-x padding-3 margin-right background-gray-light-20">.border-x</div>',
    },
    {
      borderType: '.border-y',
      className: 'border-y',
      codeExample: '<div class="border-y padding-3 margin-right background-gray-light-20">.border-y</div>',
    },
  ],
  borderWeight: [
    {
      borderType: '.border-0',
      className: 'border-0',
      codeExample: '<div class="border-0 padding-3 margin-right background-gray-light-20">.border-0</div>',
    },
    {
      borderType: '.border-1',
      className: 'border-1',
      codeExample: '<div class="border-1 padding-3 margin-right background-gray-light-20">.border-1</div>',
    },
    {
      borderType: '.border-2',
      className: 'border-2',
      codeExample: '<div class="border-2 padding-3 margin-right background-gray-light-20">.border-2</div>',
    },
    {
      borderType: '.border-3',
      className: 'border-3',
      codeExample: '<div class="border-3 padding-3 margin-right background-gray-light-20">.border-3</div>',
    },
    {
      borderType: '.border-4',
      className: 'border-4',
      codeExample: '<div class="border-4 padding-3 margin-right background-gray-light-20">.border-4</div>',
    },
    {
      borderType: '.border-5',
      className: 'border-5',
      codeExample: '<div class="border-5 padding-3 margin-right background-gray-light-20">.border-5</div>',
    },
    {
      borderType: '.border-6',
      className: 'border-6',
      codeExample: '<div class="border-6 padding-3 margin-right background-gray-light-20">.border-6</div>',
    },
  ],
  borderSizeDirection: [
    {
      borderType: '.border-top-1',
      className: 'border-top-1',
      codeExample: '<div class="border-top-1 padding-3 margin-right background-gray-light-20">.border-top-1</div>',
    },
    {
      borderType: '.border-bottom-2',
      className: 'border-bottom-2',
      codeExample: '<div class="border-bottom-2 padding-3 margin-right background-gray-light-20">.border-bottom-2</div>',
    },
    {
      borderType: '.border-right-3',
      className: 'border-right-3',
      codeExample: '<div class="border-right-3 padding-3 margin-right background-gray-light-20">.border-right-3</div>',
    },
    {
      borderType: '.border-left-4',
      className: 'border-left-4',
      codeExample: '<div class="border-left-4 padding-3 margin-right background-gray-light-20">.border-left-4</div>',
    },
    {
      borderType: '.border-x-5',
      className: 'border-x-5',
      codeExample: '<div class="border-x-5 padding-3 margin-right background-gray-light-20">.border-x-5</div>',
    },
    {
      borderType: '.border-y-6',
      className: 'border-y-6',
      codeExample: '<div class="border-y-6 padding-3 margin-right background-gray-light-20">.border-y-6</div>',
    },
  ],
  borderColor: [
    {
      borderType: '.border-black',
      className: 'border-black',
      hexName: '#000000',
      codeExample: '<div class="border-bottom-3 border-black padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-black</span><span>#000000</span></div>',
    },
    {
      borderType: '.border-white',
      className: 'border-white',
      hexName: '#FFFFFF',
      codeExample: '<div class="border-bottom-3 border-white padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-white</span><span>#FFFFFF</span></div>',
    },
    {
      borderType: '.border-gray-light',
      className: 'border-gray-light',
      hexName: '#C6CACD',
      codeExample: '<div class="border-bottom-3 border-gray-light padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-gray-light</span><span>#C6CACD</span></div>',
    },
    {
      borderType: '.border-gray',
      className: 'border-gray',
      hexName: '#A9ADB3',
      codeExample: '<div class="border-bottom-3 border-gray padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-gray</span><span>#A9ADB3</span></div>',
    },
    {
      borderType: '.border-gray-dark',
      className: 'border-gray-dark',
      hexName: '#7E858D',
      codeExample: '<div class="border-bottom-3 border-gray-dark padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-gray-dark</span><span>#7E858D</span></div>',
    },
    {
      borderType: '.border-blue-light',
      className: 'border-blue-light',
      hexName: '#2B72D7',
      codeExample: '<div class="border-bottom-3 border-blue-light padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-blue-light</span><span>#2B72D7</span></div>',
    },
    {
      borderType: '.border-blue-medium',
      className: 'border-blue-medium',
      hexName: '#203299',
      codeExample: '<div class="border-bottom-3 border-blue-medium padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-blue-medium</span><span>#203299</span></div>',
    },
    {
      borderType: '.border-blue',
      className: 'border-blue',
      hexName: '#000E54',
      codeExample: '<div class="border-bottom-3 border-blue padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-blue</span><span>#000E54</span></div>',
    },
    {
      borderType: '.border-orange-light',
      className: 'border-orange-light',
      hexName: '#FF8E00',
      codeExample: '<div class="border-bottom-3 border-orange-light padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-orange-light</span><span>#FF8E00</span></div>',
    },
    {
      borderType: '.border-orange',
      className: 'border-orange',
      hexName: '#F76900',
      codeExample: '<div class="border-bottom-3 border-orange padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-orange</span><span>#F76900</span></div>',
    },
    {
      borderType: '.border-orange-medium',
      className: 'border-orange-medium',
      hexName: '#FF431B',
      codeExample: '<div class="border-bottom-3 border-orange-medium padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-orange-medium</span><span>#FF431B</span></div>',
    },
    {
      borderType: '.border-orange-dark',
      className: 'border-orange-dark',
      hexName: '#D74100',
      codeExample: '<div class="border-bottom-3 border-orange-dark padding-3 background-gray-light-20 full-width flex-space-between"><span>.border-orange-dark</span><span>#D74100</span></div>',
    },
  ],
  borderRadius: [
    {
      borderType: '.rounded-0',
      className: 'rounded-0',
      codeExample: '<div class="border-2 rounded-0 padding-3 background-gray-light-20">.rounded-0</div>',
      style: '',
    },
    {
      borderType: '.rounded-1',
      className: 'rounded-1',
      codeExample: '<div class="border-2 rounded-1 padding-3 background-gray-light-20">.rounded-1</div>',
      style: '',
    },
    {
      borderType: '.rounded-2',
      className: 'rounded-2',
      codeExample: '<div class="border-2 rounded-2 padding-3 background-gray-light-20">.rounded-2</div>',
      style: '',
    },
    {
      borderType: '.circle',
      className: 'circle',
      codeExample: '<div class="border-2 circle padding-3 background-gray-light-20">.circle</div>',
      style: 'width: 100px; height: 100px',
    },
  ],
};

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
