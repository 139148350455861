<template>
    <div>
        <div class="example__container">
            <div class="example__demo">
                <div class="example__demo-section">
                    <form>
                        <div class="form-group">
                            <label class="form-label" for="department">Choose a department</label>
                            <div class="select">
                                <select class="select-dropdown"
                                    id="department" aria-labelledby="department">
                                    <option>Admissions</option>
                                    <option>Financial Aid</option>
                                    <option>Parking Services</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="example__code">
                <pre contenteditable="true"><prism-editor code="<form>
    <div class='form-group'>
        <label class='form-label' for='department'>Choose a department</label>
        <div class='select'>
            <select class='select-dropdown'
                id='department' aria-labelledby='department'>
                <option>Admissions</option>
                <option>Financial Aid</option>
                <option>Parking Services</option>
            </select>
        </div>
    </div>
</form>
" language="markup"></prism-editor></pre>
            </div>
        </div>
    </div>
</template>

<script>
import PrismEditor from 'vue-prism-editor';

export default {
  name: 'type-scale',
  components: {
    PrismEditor,
  },
};
</script>
