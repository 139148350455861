import Vue from 'vue';
import Vuex from 'vuex';

import colorsModule from './modules/colors';
import typographyModule from './modules/typography';
import bordersModule from './modules/borders';
import sidebarModule from './modules/sidebar';
import prototypesModule from './modules/prototypes';
import modalModule from './modules/modal';
import iconsModule from './modules/icons';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    colors: colorsModule,
    typography: typographyModule,
    borders: bordersModule,
    sidebar: sidebarModule,
    prototypes: prototypesModule,
    modal: modalModule,
    icons: iconsModule,
  },
});
