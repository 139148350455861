<template>
    <div class="sidebar-subnav">
        <p class="heading-size-h4 is-bold">On This Page</p>
        <ul class="sidebar__menu-sub"
            v-for="list in layoutSidebar"
            :key="list.routeName"
            v-show="$route.name === list.routeName">
            <li v-for="item in list.subNav" :key="item.linkName">
                <a href="#" v-scroll-to="item.routeName">
                    {{ item.linkName }}
                </a>
            </li>
        </ul>
        <ul class="sidebar__menu-sub"
            v-for="list in designSidebar"
            :key="list.routeName"
            v-show="$route.name === list.routeName">
            <li v-for="item in list.subNav" :key="item.linkName">
                <a href="#" v-scroll-to="item.routeName">
                    {{ item.linkName }}
                </a>
            </li>
        </ul>
        <ul class="sidebar__menu-sub"
            v-for="list in componentsSidebar"
            :key="list.routeName"
            v-show="$route.name === list.routeName">
            <li v-for="item in list.subNav" :key="item.linkName">
                <a href="#" v-scroll-to="item.routeName">
                    {{ item.linkName }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'sidebar-subnav',
  computed: {
    ...mapState('sidebar', ['layoutSidebar', 'designSidebar', 'componentsSidebar']),
  },
};
</script>

<style lang="scss" scoped>
    .sidebar {
        &-subnav {
            position: fixed;
            padding: $spacer-3 $spacer-3 0 $spacer-4;
        }
        &__menu-sub {
            font-size: 1.125rem;
            & > li {
                margin-bottom: $spacer-1;
            }
        }
    }
</style>
