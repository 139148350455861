<template>
    <div class="header-generic">
        <div class="header-generic-container">
            <div class="header-generic-brand">
                <div class="header-generic-brand-logo">
                    <a href="#maincontent" id="skip-nav">Skip to Content</a>
                    <a class="header-generic-brand-link" href="/">
                        <img src="../../../assets/Logos/su-header-logo.svg"
                        alt="Syracuse University Design System"
                        class="header-generic-brand-image"/>
                    </a>
                </div>
                <div class="header-generic-brand-title">
                    <span>Information Technology Services</span>
                </div>
            </div>
            <div class="header-generic-content">
                <button class="button button-primary" role="button">Sign In</button>
            </div>
            <button class="menu-button">
                <i class="icon-hamburger" />
                <span class="menu-button-label">Open Menu</span>
            </button>
        </div>
        <div class="header-generic-subhead">
            <span>Information Technology Services</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'header-generic',
};
</script>
