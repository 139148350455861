<template>
    <div class="prototype-template">
        <mobile-sidebar />
        <page-header />
        <prototype-nav />
        <main>
            <slot name="content" />
        </main>
        <page-footer />
    </div>
</template>

<script>
import PrototypeNav from './prototype-nav.vue';
import PageHeader from './page-header.vue';
import PageFooter from './page-footer.vue';
import MobileSidebar from '../Sidebars/mobile-sidebar.vue';

export default {
  name: 'prototype-template',
  components: {
    PrototypeNav,
    PageHeader,
    PageFooter,
    MobileSidebar,
  },
};
</script>

<style lang="scss" scoped>
  main {
    margin: $spacer-5 0;
    min-height: 800px;
  }
</style>
